<template>
  <article
    v-if="buyFlowStore.cart?.items.length == 0 && !buyFlowStore.hasOwnedByBestBuyError && FLAGS.ENABLE_BUYFLOW_WEBROOT"
    class="border-around mb-double"
  >
    <UndoRemove />
    <div class="text-center">
      <h1 class="border-bottom-none mb-double">{{ t("UndoRemove.cartEmpty") }}</h1>
      <button id="btnStartShopping" class="btn-primary mb" @click="go()">{{ t("UndoRemove.startShopping") }}</button>
    </div>
  </article>
  <article
    v-else-if="buyFlowStore.hasOwnedByBestBuyError && FLAGS.ENABLE_BUYFLOW_WEBROOT"
    id="bestBuySection"
    class="border-around border-radius mb-double text-center"
  >
    <p class="border-bottom-none mb-double">
      <span class="h1 pr block-mobile">{{ tn("supportBBYTitle") }}</span>
      <svg-icon-component icon="logo-best-buy" class="icon-relative-move-half" />
    </p>
    <p>{{ tn("itLooksLikeBBY") }}</p>
    <p>
      {{ tn("renewYourSubscriptionBBY") }}
      <strong>
        <a
          id="linkBestBuy"
          class="lowercase pr-quarter"
          href="https://www.bestbuy.com/services/remotesupport"
          target="_blank"
        >
          {{ t("BestBuySupportSectionComponent.line2a") }}
        </a>
      </strong>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="t('BestBuySupportSectionComponent.line2b')"></span>
      .
    </p>
  </article>
  <article v-else class="mb spinner-background border-around" :class="{ spinning: buyFlowStore.isCalculatingCart }">
    <ul>
      <cart-item
        v-for="(cartItem, index) in buyFlowStore.cart?.items"
        :key="index"
        :cart-item="cartItem"
        :index="index"
      />
      <UndoRemove />
      <li v-if="buyFlowStore.cart?.adjustments && buyFlowStore.cart?.adjustments.length > 0" class="indent mb-double">
        <Adjustments :adjustments="buyFlowStore.cart?.adjustments || []" />
      </li>
      <li class="flex-start border-top">
        <div class="grow indent text-grey">
          <strong>{{ tn("total") }}</strong>
        </div>
        <div>
          <h2 id="total-amount">{{ getTotal() }}</h2>
        </div>
      </li>
    </ul>
    <spinner-component :is-spinning="buyFlowStore.isCalculatingCart"></spinner-component>
    <div v-if="buyFlowStore.isCalculatingCart" class="cart-spinner-text">{{ t("CartComponent.updating") }}</div>
    <p v-if="buyFlowStore.pageState !== pageStates.reviewOrder" class="text-right text-grey mb">
      {{ tn("taxDisclaimer") }}
    </p>
  </article>
</template>

<script setup lang="ts">
import SpinnerComponent from "@/components/shared/Spinner.vue";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { logEvent } from "@/common/logger";
import CartItem from "@/components/Buy/Cart/CartItem.vue";
import { formatMoney } from "@/components/Buy/commonFn";
import { t } from "@/i18n";
import Adjustments from "@/components/Buy/Adjustments.vue";
import { pageStates } from "@/components/Buy/BuyEnums";
import UndoRemove from "@/components/Buy/Cart/UndoRemove.vue";
import { FLAGS, REFERRAL_URL_TRUSTED_DOMAINS, URLS } from "@/define";
import { buildQueryString } from "@/components/Buy/BuyHelper";
import { useRoute } from "vue-router";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const componentName = "CartComponent";
logEvent("Created", componentName);

const buyFlowStore = useBuyFlowStore();
const route = useRoute();

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function getTotal() {
  const cartTotal = buyFlowStore.cart?.total;
  // If cartTotal is null/undefined, calculate the sum from items
  if (cartTotal == null) {
    let total = 0;
    for (const item of buyFlowStore.cart?.items ?? []) {
      total += item.total ?? 0;
    }
    return formatMoney(total);
  }
  return formatMoney(cartTotal);
}

function go() {
  const referralUrl = route.query.referralUrl?.toString() || buyFlowStore.referralUrl || "";
  if (referralUrl) {
    try {
      const url = new URL(referralUrl);
      if (REFERRAL_URL_TRUSTED_DOMAINS.find(l => l === url.hostname)) {
        window.location.href = `${referralUrl}?${buildQueryString(route.query, ["referralUrl"])}`;
        return;
      }
    } catch {
      //The URL is bad so don't use it
      logEvent("Referral url is not valid, it will be ignored", "SelectPlanLink");
    }
  }
  //Empty cart only applies to WR so go back to WR.com
  window.location.href = URLS.WEBROOT_BUY_URL;
}
</script>
