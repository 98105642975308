<template>
  <ModalWrapperComponent
    dialog-name="DownloadProductComponent"
    :show-dialog="showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      <span v-if="showReinstallText">{{ t("DownloadProductComponent.ReinstallSafe") }}</span>
      <span v-else>{{ t(product?.localizedName || "") }}</span>
    </template>

    <template #content>
      <SafeProductComponent
        v-if="productName === 'SAFE'"
        :activation-code="activationCode"
        :device-id="deviceId"
        :install-type="installType"
        :product-name="productName"
        :unsupported-o-s="unsupported"
      />
      <WSAPProductComponent v-if="productName === 'WSAP'" :keycodes="keycodes" :is-unsupported-os="unsupported" />
      <WWTSProductComponent v-if="productName === 'WWTS'" :keycodes="keycodes" :is-unsupported-os="unsupported" />
      <WRMSProductComponent v-if="productName === 'WRMS'" :keycodes="keycodes" :is-unsupported-os="unsupported" />
      <WSCBProductComponent v-if="productName === 'WSCB'" :keycodes="keycodes" :is-unsupported-os="unsupported" />
      <WIFIProductComponent v-if="productName === 'WIFI'" :is-unsupported-os="unsupported" />
      <LPPMProductComponent v-if="productName === 'LPPM'" :is-unsupported-os="unsupported" />
      <SAFEMOBILEProductComponent v-if="productName === 'SAFEMOBILE'" :is-unsupported-os="unsupported" />
    </template>
    <template #footer>
      <div class="opposite-ends-desktop">
        <div id="linksModalFooter">
          <a v-if="helpLink" id="linkNeedHelp" class="mb-half" :href="helpLink" target="_blank">
            {{ helpText }}
            <svg-icon-component icon="external-link" class="pl-quarter" />
          </a>
        </div>
        <div id="buttonsModalFooter">
          <button id="btnCloseModal" type="button" class="btn-outline-primary" @click="close()">
            {{ tn("btnClose") }}
          </button>
        </div>
      </div>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType, ref, computed, onUnmounted } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscriberDownloadsInfo } from "@/common/api/unifiedPortal/interfaces";
import { IProduct, productList } from "@/common/productList";
import { getOsFamily } from "@/common/browserDetect";
import SafeProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/Safe.vue";
import WSAPProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/WSAP.vue";
import WWTSProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/WWTS.vue";
import WRMSProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/WRMS.vue";
import WSCBProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/WSCB.vue";
import WIFIProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/WIFI.vue";
import LPPMProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/LPPM.vue";
import SAFEMOBILEProductComponent from "@/components/shared/dialogs/DownloadProduct/Products/SAFEMOBILE.vue";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { getUrl } from "@/common/getUrl";
import { registerRefreshFunction, clearRefreshFunctions } from "@/components/shared/LanguageSelector/LanguageSelector";

const componentName = "DownloadProductComponent";

const props = defineProps({
  productName: {
    type: String,
    default: "SAFE",
  },
  keycodes: {
    type: Object as PropType<ISubscriberDownloadsInfo>,
    default: null,
  },
  activationCode: {
    type: String,
    default: null,
  },
  deviceId: {
    type: Number,
    default: null,
  },
  installType: {
    type: String,
    default: null,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["close-modal"]);

logEvent("created", componentName, props);
const unsupported = ref<boolean>(false);
const showReinstallText = ref<boolean>(false);
const product = ref<IProduct>();
let safeReinstallSupportUrl = getUrl("VITE_APP_SAFE_REINSTALL_SUPPORT");
let safeRestoreAllFilesSupportUrl = getUrl("VITE_APP_SAFE_RESTORE_ALL_FILES_SUPPORT");
function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

const helpLink = computed(() => {
  return props.productName !== "SAFE"
    ? productList().find(c => c.code === props.productName)?.supportURL
    : props.installType === "Reinstall"
      ? safeReinstallSupportUrl
      : safeRestoreAllFilesSupportUrl;
});

function refreshDownloadProductUrls() {
  safeReinstallSupportUrl = getUrl("VITE_APP_SAFE_REINSTALL_SUPPORT");
  safeRestoreAllFilesSupportUrl = getUrl("VITE_APP_SAFE_RESTORE_ALL_FILES_SUPPORT");
}

registerRefreshFunction(refreshDownloadProductUrls);

const helpText = computed(() => productList().find(c => c.code === props.productName)?.supportURLText);

function close() {
  emits("close-modal");
}

function beforeOpen() {
  unsupported.value = false;

  product.value = productList().find(c => c.code === props.productName);
  if (!product.value) {
    logEvent("Product not found", componentName, props.productName);
    return;
  }

  if (props.productName === "SAFE" && props.installType === "Reinstall") {
    showReinstallText.value = true;
  }

  if (props.productName !== "SAFE") {
    // checks if OS is supported to display the modal for the product
    if (!product.value.supportedOS.find(c => c.toLowerCase() === getOsFamily().toLowerCase())) {
      unsupported.value = true;
      logEvent("Unsupported OS", componentName, product.value.code);
      return;
    }
  }
}

onUnmounted(() => {
  clearRefreshFunctions();
});
</script>
