<template>
  <div v-if="props.sub.isActiveUntilCancelled && !props.sub.isEvergreenMonthly">
    {{ $t("SubscriptionInformationComponent.notApplicable") }}
  </div>
  <div v-if="isNotActiveYet(props.sub)">{{ t("SubscriptionInformationComponent.notActivatedYet") }}</div>
  <div v-else-if="isProcessingRenewal(props.sub)">{{ t("SubscriptionInformationComponent.processingRenewal") }}</div>
  <div v-else-if="props.sub?.zuoraSubscriptionStatus === ZuoraSubscriptionStatus.Cancelled">
    {{ t("Common.cancelled") }}
  </div>
  <div v-else-if="isWebrootSubscriptionPurged || isAmazonPostExpiredWindow">
    {{ t("SubscriptionInformationComponent.expired") }}
  </div>
  <div v-else-if="isSafeSubscriptionClosed(props.sub)">{{ t("SubscriptionInformationComponent.closed") }}</div>
  <div
    v-else-if="isInSafeRenewalWindow || isOutsideSafeRenewalWindow || isBestBuyChurn || hasOutstandingInvoices"
    :class="getExpiredClass()"
  >
    {{ t("SubscriptionInformationComponent.expired") }}
  </div>
  <ul v-else>
    <li>{{ nextPaymentDate() }}</li>
    <li v-if="!hideNextPaymentDateText">{{ t(nextPaymentDateText) }}</li>
    <li v-if="sub.isAutoRenewEnabled && sub.renewalDiscount > 0 && !sub.renewalDiscountApplied" class="success-text">
      {{ sub.renewalDiscount }}% {{ t("Common.discount") }}
    </li>
  </ul>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { ExpirationState, KeyCodeSeller, ZuoraSubscriptionStatus } from "@/globalEnums";
import {
  isSafeSubscriptionClosed,
  isNotActiveYet,
  isProcessingRenewal,
  isExpiredAllstateWithNewSubscription,
} from "@/common/subscriptionHelper";
import {
  SUBSCRIPTION_LAST_AMAZON_EXPIRED_DAY,
  SUBSCRIPTION_MIN_EXPIRED_DAY,
  SUBSCRIPTION_MIN_GRACE_PERIOD_DAY,
} from "@/define";
import { formatDate } from "@/common/dateFormat";

const componentName = "NextPaymentColumnComponent";

const props = defineProps({
  sub: {
    type: Object as PropType<ISubscription>,
    required: true,
  },
});

logEvent("created", componentName);

const isInSafeRenewalWindow = computed(() => {
  return props.sub.expiration === ExpirationState.Expired;
});

const isOutsideSafeRenewalWindow = computed(() => {
  return props.sub.expiration === ExpirationState.FinalWarning;
});

const isBestBuyChurn = computed(() => {
  return props.sub.keyCodeSellerType === KeyCodeSeller.BestBuy && props.sub.expiration === ExpirationState.Purged;
});

const isAmazonPostExpiredWindow = computed(() => {
  return (
    props.sub.keyCodeSellerType === KeyCodeSeller.Amazon &&
    props.sub.remainingDays <= SUBSCRIPTION_LAST_AMAZON_EXPIRED_DAY
  );
});

const isWebrootSubscriptionPurged = computed(() => {
  return props.sub.keyCodeSellerType === KeyCodeSeller.Webroot && props.sub.expiration === ExpirationState.Purged;
});

function nextPaymentDate() {
  let nextPaymentDate = "";

  if (props.sub?.nextPaymentDate) {
    const date = new Date(props.sub.nextPaymentDate);
    nextPaymentDate = formatDate(date);
  }

  return nextPaymentDate;
}

function getExpiredClass() {
  return isExpiredAllstateWithNewSubscription(props.sub) ? "" : "danger-text";
}

const hideNextPaymentDateText = computed(() => {
  return props.sub.resellerId && props.sub.resellerId > 0 && !props.sub.isPartnerReferral;
});

const nextPaymentDateText = computed(() => {
  if (props.sub.isTrial) {
    return "SubscriptionInformationComponent.trial";
  }

  if (props.sub.isAutoRenewEnabled || (props.sub.isEvergreenMonthly && !props.sub.isCancelledEvergreenSubscription)) {
    return "SubscriptionInformationComponent.automatic";
  }

  return "SubscriptionInformationComponent.manual";
});

const hasOutstandingInvoices = computed(() => {
  return (
    props.sub.hasOutstandingInvoices &&
    props.sub.outstandingInvoice &&
    props.sub.outstandingInvoice.outstandingInvoiceDays <= SUBSCRIPTION_MIN_EXPIRED_DAY &&
    props.sub.outstandingInvoice.outstandingInvoiceDays >= SUBSCRIPTION_MIN_GRACE_PERIOD_DAY
  );
});
</script>
