<template>
  <ul class="backup-status">
    <li>
      <div v-if="displayDeviceStatus" class="state-wrapper">
        <div :id="`deviceStatus_${props.tagId}`" class="flex-start align-items-center">
          <h3 :class="deviceStatusCSS">{{ t(`CardComputerComponent.deviceStatus${deviceStatus}`) }}</h3>
          <div class="icon-sm info has-tooltip arrow-right" onclick="">
            <button type="button" class="btn-link">
              <svg-icon-component icon="circle-information" class="icon-sm" />
            </button>
            <div class="tooltip-area">
              <p>{{ t(`CardComputerComponent.deviceStatus${deviceStatus}Message`) }}</p>
              <i18n-t
                v-if="deviceStatus === 'BackupOutOfDate'"
                keypath="CardComputerComponent.refreshYourBackup"
                tag="p"
                scope="global"
              >
                <template #steps>
                  <a
                    :id="`linkFollowTheseStepsTargetBlank_${props.tagId}`"
                    href="https://support.carbonite.com/articles/Personal-Windows-Backup-Overdue"
                    target="_blank"
                  >
                    {{ t("CardComputerComponent.followTheseSteps") }}
                  </a>
                </template>
              </i18n-t>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li v-show="displayLastBackedUp" class="mb-half">
      {{ t("CardComputerComponent.lastBackedUp") }} {{ lastBackupTime() }}
    </li>
    <li v-show="displayFileCount" class="mb-half">
      <span :id="`fileCount${props.tagId}`">{{ props.device.fileCount }}</span>
      {{ t("CardComputerComponent.files") }}
      (
      <span :id="`fileSize${props.tagId}`">{{ fileSizeInGb }}</span>
      GB)
    </li>
    <li v-show="displayViewFilesAndGetFilesBack" class="mb-half">
      <button :id="`viewFiles_${props.tagId}`" type="button" class="btn-outline-primary mr-half" @click="viewFiles()">
        {{ t("CardComputerComponent.viewFiles") }}
      </button>
      <span v-if="displayGetFileButton" class="only-show-desktop-inline">
        <button :id="`getFilesBack_${props.tagId}`" type="button" class="btn-outline-primary" @click="getFilesBack()">
          {{ t("CardComputerComponent.getFilesBack") }}
        </button>
      </span>
    </li>
  </ul>
</template>
<script setup lang="ts">
import { PropType, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IDevice } from "@/common/api/unifiedPortal/interfaces";
import { BYTE_SCALE } from "@/define";
import { FLAGS } from "@/define";
import { DeviceStatus } from "./CardComputerEnums";
import { formatDateString } from "@/common/dateFormat";
import { isMobile } from "@/common/browserDetect";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { getFileAccessRoute } from "@/components/FileAccess/navigationFn";
import { getUrl } from "@/common/getUrl";

const name = "DeviceStatusComponent";

const props = defineProps({
  device: {
    type: Object as PropType<IDevice>,
    required: true,
  },
  tagId: {
    type: String,
    required: false,
    default: null,
  },
  displayViewFilesAndGetFilesBackProp: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(["indicator"]);

logEvent("created", name);
const router = useRouter();
const displayDeviceStatus = ref<boolean>(false);
const displayDeviceStatusTooltip = ref<boolean>(false);
const displayLastBackedUp = ref<boolean>(false);
const displayFileCount = ref<boolean>(false);
const displayGetFileButton = ref<boolean>(true);
const displayViewFilesAndGetFilesBack = ref<boolean>(true);
const deviceStatus = ref<string>("");
const deviceStatusCSS = ref<string>("");

function lastBackupTime() {
  return formatDateString(props.device.lastBackupTime);
}

const fileSizeInGb = computed(() => {
  return (props.device.fileSizeMB / BYTE_SCALE).toFixed(2);
});

setDeviceStatus(props.device.status);
displayGetFileButton.value = !isMobile();
displayViewFilesAndGetFilesBack.value = lastBackupTime() !== "" && props.displayViewFilesAndGetFilesBackProp;

function setDeviceStatus(status: string) {
  displayDeviceStatus.value = status.length > 0 && status != "Normal" && status != "Expired";
  logEvent("Initial Device Status", name, status);
  if (status === DeviceStatus.Uninstalled || status === DeviceStatus.Unknown) {
    deviceStatus.value = DeviceStatus.Uninstalled;
    deviceStatusCSS.value = "danger-text bold-text";
    emits("indicator", "Alert", "");
  } else if (status === DeviceStatus.Disabled) {
    deviceStatus.value = status;
    emits("indicator", "Danger", "");
    displayDeviceStatusTooltip.value = true;
    deviceStatusCSS.value = "danger-text bold-text";
  } else if (status === DeviceStatus.Frozen) {
    deviceStatus.value = DeviceStatus.RecoverMode;
    emits("indicator", "Alert", "");
    displayDeviceStatusTooltip.value = true;
    deviceStatusCSS.value = "bold-text";
  } else if (status === DeviceStatus.Starting) {
    deviceStatus.value = status;
    displayViewFilesAndGetFilesBack.value = false;
    deviceStatusCSS.value = "italic-text";
  } else if (status === DeviceStatus.Overdue || status === DeviceStatus.OverdueSevere) {
    emits("indicator", "Alert", "CardComputerBackupOutDated");
    displayDeviceStatusTooltip.value = true;
    deviceStatus.value = DeviceStatus.BackupOutOfDate;
    deviceStatusCSS.value = "danger-text";
  } else {
    displayDeviceStatus.value = false;
  }

  if (status !== DeviceStatus.Starting) {
    displayLastBackedUp.value = true;
    displayFileCount.value = true;
  }

  logEvent("Device Status", name, deviceStatus);
}

function viewFiles() {
  if (FLAGS.SHOW_RFA_COMPONENT === "true") {
    logEvent("new viewFiles", name, props.device);
    router.push(getFileAccessRoute(props.device.deviceId));
  } else {
    logEvent("old viewFiles", name, props.device);
    window.location.href = `${getUrl("REMOTE_FILE_ACCESS_URL")}/${props.device.deviceId}`;
  }
}

function getFilesBack() {
  logEvent("getFilesBack", name, props.device);
  router.push(`/getFiles?deviceId=${props.device.deviceId}`);
}
</script>
