import { t } from "../i18n";

export function getWebrootLicenseDescription(licenseCategory: string): string | undefined {
  return t(`Brand.${licenseCategory}`);
}
export interface IWebrootProductNames {
  licenseCategory: string;
  sku: string;
  candyRackDescription: string[];
}

export const webrootLicenseCategories = {
  WSAV: "WSAV",
  WSAI: "WSAI",
  WSAC: "WSAC",
  WSAFMPK: "WSAFMPK",
  WSCB: "WSCB",
  WSAG: "WSAG",
  WIFI: "WIFI",
  WSAP: "WSAP",
  WSID: "WSID",
  WSAPF: "WSAPF",
  WSIDP: "WSIDP",
  WSIDPF: "WSIDPF",
  WTP: "WTP",
  WTPF: "WTPF",
  CD: "CD",
  WW: "WW",
  ADP: "ADP",
  WSASB: "WSASB",
  WPCO: "WPCO",
  Webroot: "Webroot", // used by old Legacy Webroot Records
};

export const webrootLCNs = [
  "WSAV",
  "WSAI",
  "WSAC",
  "WSAFMPK",
  "WSCB",
  "WSAG",
  "WIFI",
  "WSAP",
  "WSID",
  "WSAPF",
  "WTP",
  "WTPF",
  "WW",
  "ADP",
  "WSASB",
  "WPCO",
];
export const allstateLCNs = ["WSAP", "WSAPF", "WSID", "WSIDP", "WSIDPF", "WTP", "WTPF"];
export const premiumLCNs = ["WSAP", "WSAPF", "WTP", "WTPF"];
export const allstatePremiumFamilyLCNs = ["WSIDPF", "WSAPF"];
export const nonAllstateWebrootLCNs = ["WSAV", "WSCB", "WSAG", "WIFI", "WSAC", "WSAFMPK", "WSAI", "WW", "ADP", "WSASB"];
export const addOnLCNs = ["WSID", "WSIDP", "WSIDPF"];
export const webrootCDSku = "WebrootSoftwareInstallationCD";
export const webrootProducts: IWebrootProductNames[] = [
  {
    licenseCategory: webrootLicenseCategories.WSAV,
    sku: "WebrootAntiVirus",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSAI,
    sku: "WebrootPlus",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSAC,
    sku: "WebrootGamer",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSAFMPK,
    sku: "WebrootComplete",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSCB,
    sku: "WebrootChromebook",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSAG,
    sku: "WebrootGamer",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WIFI,
    sku: "WebrootSecureVPN",
    candyRackDescription: [
      "SpecialOffers.WebrootWiFiSecurityDescription1",
      "SpecialOffers.WebrootWiFiSecurityDescription2",
    ],
  },
  {
    licenseCategory: webrootLicenseCategories.WSAP,
    sku: "WebrootPremium",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSAPF,
    sku: "WebrootPremiumFamily",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSID,
    sku: "AllstateIdentityProtectionBasic",
    candyRackDescription: [
      "SpecialOffers.AllstateIdentityProtectionBasicDescription1",
      "SpecialOffers.AllstateIdentityProtectionBasicDescription2",
      "SpecialOffers.AllstateIdentityProtectionBasicDescription3",
    ],
  },
  {
    licenseCategory: webrootLicenseCategories.WSIDP,
    sku: "AllstateIdentityProtectionPremium",
    candyRackDescription: [
      "SpecialOffers.AllstateIdentityProtectionPremiumDescription1",
      "SpecialOffers.AllstateIdentityProtectionPremiumDescription2",
      "SpecialOffers.AllstateIdentityProtectionPremiumDescription3",
    ],
  },
  {
    licenseCategory: webrootLicenseCategories.WSIDPF,
    sku: "AllstateIdentityProtectionPremiumFamily",
    candyRackDescription: [
      "SpecialOffers.AllstateIdentityProtectionPremiumFamilyDescription1",
      "SpecialOffers.AllstateIdentityProtectionPremiumFamilyDescription2",
      "SpecialOffers.AllstateIdentityProtectionPremiumFamilyDescription3",
    ],
  },
  {
    licenseCategory: webrootLicenseCategories.WTP,
    sku: "WebrootTotalProtection",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WTPF,
    sku: "WebrootTotalProtectionFamily",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.CD,
    sku: webrootCDSku,
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WW,
    sku: "WindowWasher",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.ADP,
    sku: "AndroidMobile",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSASB,
    sku: "WebrootSmallBusinessAntiVirus",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WPCO,
    sku: "WebrootPcOptimizer",
    candyRackDescription: [
      "SpecialOffers.WebrootPcOptimizerDescription1",
      "SpecialOffers.WebrootPcOptimizerDescription2",
    ],
  },
];

export const webrootSkusWithNotableFeature = [
  "WebrootAntiVirus",
  "WebrootGamer",
  "WebrootPlus",
  "WebrootComplete",
  "WebrootPremium",
  "WebrootPremiumFamily",
  "AllstateIdentityProtectionBasic",
  "AllstateIdentityProtectionPremium",
  "AllstateIdentityProtectionPremiumFamily",
  "WebrootChromebook",
  "WebrootSecureVPN",
  "AndroidMobile",
  "WebrootSmallBusinessAntiVirus",
  "WebrootTotalProtection",
  "WebrootTotalProtectionFamily",
  "WebrootPcOptimizer",
];
export const webrootMultiDeviceSkus = [
  "WebrootPlus",
  "WebrootComplete",
  "WebrootPremium",
  "WebrootPremiumFamily",
  "WebrootChromebook",
  "WebrootSecureVPN",
  "WebrootTotalProtection",
  "WebrootTotalProtectionFamily",
  "WebrootPcOptimizer",
];
export const webrootSkusWithIdentity = [
  "WebrootPremium",
  "WebrootPremiumFamily",
  "AllstateIdentityProtectionBasic",
  "AllstateIdentityProtectionPremium",
  "AllstateIdentityProtectionPremiumFamily",
  "WebrootTotalProtection",
  "WebrootTotalProtectionFamily",
];
export const webrootSkusThatAlreadyHaveIdentity = [
  "WebrootPremium",
  "WebrootPremiumFamily",
  "WebrootTotalProtection",
  "WebrootTotalProtectionFamily",
];
export const webrootCDLP = ["WebrootTotalProtection", "WebrootTotalProtectionFamily"];
export const webrootCDLPcodes = ["WTP", "WTPF"];
export const webrootSkusThatAreRenewOnly = ["WindowWasher", "AndroidMobile", "WebrootSmallBusinessAntiVirus"];
export const webrootOnlyAllstateSkus = [
  "AllstateIdentityProtectionBasic",
  "AllstateIdentityProtectionPremium",
  "AllstateIdentityProtectionPremiumFamily",
];

export function convertWebrootSku(sku: string, licenseCategory: string) {
  if (webrootProducts.some(w => w.sku === sku)) {
    return sku;
  }

  const response = webrootProducts.find(w => w.licenseCategory.toString() === licenseCategory);
  return response?.sku ?? "";
}
