<template>
  <div>
    <div class="content top-bottom-space">
      <section v-if="step === 'start'">
        <header>
          <h1>
            <button id="btnReturnToBackup" class="inline-btn-link" @click="returnToBackup()">
              <svg-icon-component icon="chevron-left" />
            </button>
            {{ tn("headerStepStart") }}
          </h1>
        </header>
        <h4>{{ tn("lostFiles") }}</h4>
        <p>{{ tn("howWould") }}</p>
        <ul class="form">
          <li class="radio">
            <input
              id="download"
              v-model="downloadMethod"
              type="radio"
              name="method"
              value="download"
              :disabled="!compatiblePlatform"
            />
            <label :class="{ 'text-grey': !compatiblePlatform }" for="download">{{ tn("stepStartOption1") }}</label>
          </li>
          <li v-if="!compatiblePlatform" class="indent">
            <p class="danger-text">
              <span class="block">{{ tn("incompatibleOSInfoMessage", { OS: detectedPlatform }) }}</span>
              {{ t(`GetFilesWizardComponent.incompatibleOSWarning${device?.platform}`) }}
            </p>
          </li>
          <li v-if="showCourierOption" class="radio mb-0">
            <input
              id="courier"
              v-model="downloadMethod"
              type="radio"
              name="method"
              value="courier"
              :disabled="backupPageStore.isInternationalAddress"
            />
            <label for="courier">{{ tn("stepStartOption2") }}</label>
          </li>
          <li v-if="showCourierOption && backupPageStore.isInternationalAddress">
            <p class="danger-text indent mb-quarter">{{ tn("courierUSOnly") }}</p>
            <i18n-t keypath="GetFilesWizardComponent.courierContactSupportText" tag="p" class="indent" scope="global">
              <template #link>
                <button type="button" class="inline-btn-link" @click="goToSupportPage()">
                  {{ t("Common.contactSupport") }}
                </button>
              </template>
            </i18n-t>
          </li>
        </ul>
        <footer>
          <button id="StartNext" type="button" class="btn-primary" @click="stepStartNext()">
            {{ tn("next") }}
          </button>
        </footer>
      </section>
      <section v-if="step === 'download'">
        <header>
          <h1>
            <button id="btnReturnToStart" class="inline-btn-link" @click="returnToStart()">
              <svg-icon-component icon="chevron-left" />
            </button>
            {{ tn("headerStepDownload") }}
          </h1>
        </header>
        <article>
          <p>{{ tn("whichFiles") }}</p>
          <h4>{{ tn("allFiles") }}</h4>
          <p>{{ tn("allFilesText") }}</p>
          <button id="DownloadNext1" type="button" class="btn-primary" @click="stepDownloadCompleteRestore()">
            {{ tn("completeRestore") }}
          </button>
        </article>

        <article>
          <h4>{{ tn("onlyAFew") }}</h4>
          <p>{{ tn("onlyAFewText") }}</p>
          <button id="DownloadNext2" type="button" class="btn-primary" @click="stepDownloadRemoteAccess()">
            {{ tn("remoteAccess") }}
          </button>
        </article>
      </section>
      <section v-if="step === 'completeRestore'">
        <header>
          <h1>
            <button id="btnReturnToDownload" class="inline-btn-link" @click="returnToDownload()">
              <svg-icon-component icon="chevron-left" />
            </button>
            {{ tn("headerStepCompleteRestore") }}
          </h1>
        </header>
        <h4>{{ tn("lostFiles") }}</h4>

        <p>{{ tn("note") }}</p>
        <p>
          {{ tn("isCarboniteInstalled") }}
          <a :href="getUrl('VITE_APP_CARBONITE_INSTALLED_HOW_TO_TELL_URL')" target="_blank">{{ tn("howToTell") }}</a>
        </p>

        <ul class="form">
          <li class="radio">
            <input id="yes" v-model="carboniteInstalled" type="radio" name="carboniteInstalled" value="yes" />
            <label for="yes">{{ tn("yes") }}</label>
          </li>
          <li class="radio">
            <input id="no" v-model="carboniteInstalled" type="radio" name="carboniteInstalled" value="no" />
            <label for="no">{{ tn("no") }}</label>
          </li>
        </ul>
        <footer>
          <button
            id="CompleteRestoreNext"
            type="button"
            class="btn-primary"
            :disabled="!carboniteInstalled"
            @click="stepCompleteRestoreNext()"
          >
            {{ tn("next") }}
          </button>
        </footer>
      </section>

      <section v-if="step === 'downloadInstructions'">
        <header>
          <h1>
            <button id="btnReturnToCompleteRestore" class="inline-btn-link" @click="returnToCompleteRestore()">
              <svg-icon-component icon="chevron-left" />
            </button>
            {{ tn("headerStepDownload") }}
          </h1>
        </header>

        <p>{{ tn("noneOfComputers") }}</p>
        <p>{{ tn("toGetStarted") }}</p>

        <!-- <div id="mobileWarning" v-if="isMobile" class="unsupported">
                    {{ t("BackupComponent.noteOnlyAvailableFor")}}
                </div> -->

        <ol>
          <i18n-t keypath="GetFilesWizardComponent.stepDownloadInstruction1" tag="li" scope="global">
            <template #computer>
              <strong>{{ tn("stepDownloadInstruction1sub") }}</strong>
            </template>
          </i18n-t>

          <i18n-t keypath="GetFilesWizardComponent.stepDownloadInstruction2" tag="li" scope="global">
            <template #add>
              <strong>{{ tn("downloadNow") }}</strong>
            </template>
            <template #button>
              <DownloadSafeComponent :device="device" :install-type="'Recover'" @downloaded="downloadFinished" />
            </template>
            <template #note>
              <em>
                {{ tn("onlyAvailableOnDesktop") }}
              </em>
            </template>
          </i18n-t>

          <li>{{ tn("stepDownloadInstruction3") }}</li>
        </ol>

        <p>{{ tn("onceInstalled") }}</p>
        <p>
          <a :href="getUrl('VITE_APP_CARBONITE_PERSONAL_WINDOWS_RESTORE_ALL_FILES_URL')" target="_blank">
            {{ tn("viewDetailed") }}
            <svg-icon-component icon="external-link" class="icon-sm pl-quarter" />
          </a>
        </p>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed, ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IDevice } from "@/common/api/unifiedPortal/interfaces";
import { FLAGS } from "@/define";
import DownloadSafeComponent from "@/components/Backup/DownloadSafe.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { getFileAccessRoute } from "@/components/FileAccess/navigationFn";
import { getUrl } from "@/common/getUrl";
import { useBackupPageStore } from "@/stores/backupPage";

const componentName = "GetFilesWizardComponent";

const props = defineProps({
  device: {
    type: Object as PropType<IDevice | undefined>,
    required: true,
  },
});

logEvent("created", componentName);
const step = ref<string>("start");
//setting default download method to download radio button
const downloadMethod = ref<string>("");
const carboniteInstalled = ref<string>("");
const detectedPlatform = ref<string>("");
const compatiblePlatform = ref<boolean>(true);
const backupPageStore = useBackupPageStore();

const router = useRouter();

if (!props.device) {
  router.push("/backup");
}

onBeforeMount(async () => {
  //If there are no payments methods loaded, try to load them again
  if (backupPageStore.creditCards.length === 0) {
    await backupPageStore.populateCreditCards();
  }
});

logEvent(props.device?.name || "", componentName);
detectedPlatform.value = localStorage.getItem("getOS") || "{}";
compatiblePlatform.value = detectedPlatform.value === props.device?.platform;

//setup downloadMethod default value
downloadMethod.value = compatiblePlatform.value ? "download" : "courier";

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function goToSupportPage() {
  window.open(getUrl("VITE_APP_CARBONITE_SUPPORT_SITE"), "_blank");
}

function stepStartNext() {
  logEvent("stepStartNext selected " + downloadMethod.value, componentName);
  if (downloadMethod.value === "courier") {
    if (FLAGS.ENABLE_VD_COURIER_RECOVERY === "false") {
      router.push("/courier");
    } else {
      router.push("/courierrecovery");
    }
  }
  // setting default for method
  step.value = "download";
}

function returnToBackup() {
  returnToStart();
  router.push("/backup");
}

function returnToStart() {
  downloadMethod.value = "";
  carboniteInstalled.value = "";
  step.value = "start";
}

function returnToDownload() {
  carboniteInstalled.value = "";
  step.value = "download";
}

function returnToCompleteRestore() {
  step.value = "completeRestore";
}

function stepDownloadCompleteRestore() {
  step.value = "completeRestore";
}

function stepDownloadRemoteAccess() {
  if (!props.device) return;

  if (FLAGS.SHOW_RFA_COMPONENT === "true") {
    logEvent("new viewFiles", componentName, props.device);
    router.push(getFileAccessRoute(props.device.deviceId));
  } else {
    logEvent("old viewFiles", componentName, props.device);
    window.location.href = `${getUrl("REMOTE_FILE_ACCESS_URL")}/${props.device.deviceId}`;
  }
}

function downloadFinished() {
  logEvent("download finished", componentName);
}

function stepCompleteRestoreNext() {
  if (carboniteInstalled.value === "yes") {
    // open restoring all files link in new tab
    /* eslint-disable */
        //@ts-ignore
        window.open(getUrl("VITE_APP_CARBONITE_PRO_WINDOWS_RESTORE_ALL_FILES_URL"), "_blank").focus();
        /* eslint-enable */
  }
  if (carboniteInstalled.value === "no") {
    step.value = "downloadInstructions";
  }
}

const showCourierOption = computed(() => {
  //for Trial Users or for Expired Subscription, Hide Courier Recovery option
  return !props.device?.isTrial && (props.device?.daysRemaining ?? 0) > 0;
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
@import "@/styles/variables.css";

.unsupported {
  color: var(--danger);
  font-style: italic;
}
dd input[type="text"] {
  width: 100%;
}
h3 {
  margin-bottom: var(--space-third);
}
h4 {
  margin-bottom: var(--space);
}
header h1 {
  margin-top: var(--space-half);
}
header .icon {
  top: 0;
  fill: var(--link-color);
}
article {
  margin: 30px 0;
}
ol {
  padding-left: 15px;
}
ol li {
  margin-bottom: var(--space);
}
ol button {
  display: block;
  margin-top: var(--space);
}
ol em {
  display: block;
  color: var(--danger);
  font-style: italic;
  margin-top: var(--space);
}

@media (min-width: 600px) {
  ol em {
    display: none;
  }
}
</style>
