import { SUBSCRIPTION_BESTBUY_RENEWAL_CONVERSION_DAY } from "@/define";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { isNotActiveYet } from "@/common/subscriptionHelper";
import { useUserStore } from "@/stores/user";

const bestBuy = "BestBuy";
export const bestBuyRetailDistributionCodes = [
  "ZCBB",
  "ZABB",
  "ZBBB",
  "SBWT",
  "ADBB",
  "ABBB",
  "TTBC",
  "TEBC",
  "TEBW",
  "SBWO",
  "WABB",
  "SBIT",
  "TTBW",
  "WIBB",
  "TTPP",
  "ECBB",
  "SBST",
  "GAMR",
  "ZEBB",
];

export async function hasOnlyBestBuyS2(): Promise<boolean> {
  const userStore = useUserStore();
  const subscriptionsStore = useSubscriptionsStore();
  await subscriptionsStore.populateSubscriptions();
  const subscriptions = subscriptionsStore.subscriptions;
  if (subscriptions && subscriptions.length > 0) {
    const q = subscriptions.every(
      s =>
        s.keyCodeSellerType === bestBuy &&
        (s.remainingDays > SUBSCRIPTION_BESTBUY_RENEWAL_CONVERSION_DAY || isNotActiveYet(s))
    );
    return q;
  }
  return userStore.currentUser?.subscriberInfo?.hasBestBuyS2 ?? false;
}

export async function hasBestBuyS2(): Promise<boolean> {
  const userStore = useUserStore();
  const subscriptionsStore = useSubscriptionsStore();
  await subscriptionsStore.populateSubscriptions();
  const subscriptions = subscriptionsStore.subscriptions;
  if (subscriptions && subscriptions.length > 0) {
    const q = subscriptions.some(
      s =>
        s.keyCodeSellerType === bestBuy &&
        (s.remainingDays > SUBSCRIPTION_BESTBUY_RENEWAL_CONVERSION_DAY || isNotActiveYet(s))
    );
    return q;
  }
  return userStore.currentUser?.subscriberInfo?.hasBestBuyS2 ?? false;
}

//Check if the user has any subs that were bought from BestBuy
//This includes Retail, S2, and TTS
export async function hasAnyBestBuySubscriptions() {
  const subscriptions = useSubscriptionsStore().subscriptions ?? [];

  return subscriptions.some(
    l =>
      l.keyCodeSellerType === bestBuy ||
      bestBuyRetailDistributionCodes.find(k => k === getKeycodeDistributionMethodFromKeycode(l.keyCode ?? ""))
  );
}

//Distribution method is between characters 5th and 8th in the keycode
function getKeycodeDistributionMethodFromKeycode(keycode: string) {
  return keycode.substring(4, 8);
}
