<template>
  <ul>
    <li class="details">
      <span v-if="viewDetailsLink" class="danger-details">{{ t(`${textRoot}dangerDetails`) }}</span>
      <button
        v-if="viewDetailsLink"
        :id="`btnDetails_${tileTitle}`"
        class="inline-btn-link"
        type="button"
        @click="viewDetails()"
      >
        {{ t(`Common.viewDetails`) }}
      </button>
    </li>
    <slot name="content" />
  </ul>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { i18nRoot } from "@/components/Home";

const name = "TileDetailsComponent";

const props = defineProps({
  path: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
});

logEvent("created", name);
const router = useRouter();
const textRoot = ref<string>(i18nRoot);
const viewDetailsLink = ref<string>(props.path);

const tileTitle = computed(() => props.title);

function viewDetails() {
  if (props.path !== "") {
    router.push(props.path);
  }
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
