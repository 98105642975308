<template>
  <ModalWrapperComponent dialog-name="MoveMySubscriptionComponent" :show-dialog="showDialog" @close-modal="closeModal">
    <template #header>
      {{ tn("headerH1") }}
    </template>
    <template #content>
      <ul>
        <li>
          {{ tn("macToMacLine1") }}
          <strong>{{ nickname }}</strong>
        </li>
        <li>
          {{ tn("macToMacLine2") }}
        </li>
        <li>
          <i18n-t keypath="MacToMacMoveSubscriptionComponent.macToMacLine3" tag="p" scope="global">
            <template #Continue>
              <strong>{{ tn("footerButtonContinue") }}</strong>
            </template>
            <template #nickname>
              <strong>{{ nickname }}</strong>
            </template>
          </i18n-t>
        </li>
      </ul>
    </template>
    <template #footer>
      <button id="btnCancelChangesMacToMac" type="button" class="btn-link mr-half" @click="closeModal()">
        {{ tn("footerButtonCancel") }}
      </button>
      <button id="btnSaveChangesMacToMac" type="button" class="btn-primary" @click="handleSaveModal()">
        {{ tn("footerButtonContinue") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IDevice } from "@/common/api/unifiedPortal/interfaces";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";

const componentName = "MacToMacMoveSubscriptionComponent";

const props = defineProps({
  device: {
    type: Object as PropType<IDevice>,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save", "cancel"]);

logEvent("created", componentName, props.device);
const nickname = ref<string>(props.device.description);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function handleSaveModal() {
  logEvent("Save move sub", componentName, "Reinstall");
  emits("save", "Reinstall");
}

function closeModal() {
  logEvent("Close move sub", componentName);
  emits("cancel");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
