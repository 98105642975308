import { computed, ref } from "vue";
import {
  IUser,
  ILoggedInUser,
  IHomePageTiles,
  IUserDetails,
  ISubscriberInfo,
} from "@/common/api/unifiedPortal/interfaces";
import { identifyPendo } from "@/common/pendo";
import { UserType } from "@/globalEnums";
import { defineStore } from "pinia";

export * from "./userInterfaces";

export const useUserStore = defineStore("user", () => {
  const homeTilesInitialState = {
    backupOverview: true,
    securityOverview: true,
    securityZeroState: true,
    backupZeroState: true,
    subscriptions: true,
  };

  const email = ref("");
  const currentUser = ref<IUser | null>(null);
  const userType = ref<UserType>(UserType.consumer);
  const selectedLanguage = ref("");
  const isCreateAccountKeycodeExpired = ref(false);
  const createAccountEmailMismatchKeycode = ref("");
  const isResellerManaged = ref(false);
  const checkUserSecurityQuestionsFilled = ref(false);
  const twoFactorAuthAddedFromFirstLogin = ref(false);
  const userDetails = ref<IUserDetails | null>(null);
  const lastRequestTime = ref(0);
  const displayedManageResellerControlRequest = ref(false);
  const showTile = ref<IHomePageTiles>(homeTilesInitialState);
  const isPendoIdentified = ref(false);
  const isCssbUser = ref(false);
  const preliminaryKeepAliveTimeInMinutes = ref(0);
  const preliminaryUserTimedOut = ref(false);

  //Need to create our own reset function because this style of store doesn't support $reset
  function resetState() {
    email.value = "";
    currentUser.value = null;
    userType.value = UserType.consumer;
    selectedLanguage.value = "";
    isCreateAccountKeycodeExpired.value = false;
    createAccountEmailMismatchKeycode.value = "";
    isResellerManaged.value = false;
    checkUserSecurityQuestionsFilled.value = false;
    twoFactorAuthAddedFromFirstLogin.value = false;
    userDetails.value = null;
    lastRequestTime.value = 0;
    displayedManageResellerControlRequest.value = false;
    showTile.value = homeTilesInitialState;
    isPendoIdentified.value = false;
    isCssbUser.value = false;
  }

  const userFullName = computed(() => {
    let name = "";
    if (currentUser.value) {
      if (currentUser.value.firstName) {
        name = `${currentUser.value.firstName}`;
      }

      if (currentUser.value.lastName) {
        name = `${name} ${currentUser.value.lastName}`;
      }
    }

    return name;
  });

  const isEstablishedAccount = computed(() => {
    return (currentUser.value && currentUser.value.subscriberInfo?.hasPasswordSet) || false;
  });

  function setUser(user: IUser) {
    currentUser.value = user;

    // Initialize Pendo
    if (user?.personId != undefined && user?.personId > 0) {
      identifyPendo(user.personId);
    }
  }

  function setLoggedInUser(user: ILoggedInUser | null) {
    // fix: do not override subscriberInfo information:
    const subscriberInfo = currentUser.value?.subscriberInfo
      ? currentUser.value.subscriberInfo
      : ({} as ISubscriberInfo);
    subscriberInfo.hasValidatedEmail = user?.hasValidatedEmail ?? false;

    const updateCurrentUser = {
      personId: user?.personId,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      alternateEmail: user?.alternateEmail,
      subscriberInfo: subscriberInfo,
    } as IUser;

    if (currentUser.value) {
      currentUser.value = Object.assign(currentUser.value, updateCurrentUser);
    } else {
      currentUser.value = updateCurrentUser;
    }
  }

  return {
    email,
    currentUser,
    userType,
    selectedLanguage,
    isCreateAccountKeycodeExpired,
    createAccountEmailMismatchKeycode,
    isResellerManaged,
    checkUserSecurityQuestionsFilled,
    twoFactorAuthAddedFromFirstLogin,
    userDetails,
    lastRequestTime,
    displayedManageResellerControlRequest,
    showTile,
    isPendoIdentified,
    isCssbUser,
    userFullName,
    setUser,
    setLoggedInUser,
    isEstablishedAccount,
    resetState,
    preliminaryKeepAliveTimeInMinutes,
    preliminaryUserTimedOut,
  };
});
