<template>
  <section class="card mb-double">
    <header>
      <h3 v-if="hasWindowWasher">
        <svg-icon-component icon="window-washer" class="icon-md icon-relative-move" />
        {{ t("Brand.WindowWasher") }}
      </h3>
      <h3 v-if="hasAndroidMobile">
        <svg-icon-component icon="webroot-security-antivirus-premier" class="icon-md icon-relative-move" />
        {{ t("Brand.AndroidMobile") }}
      </h3>
      <h3 v-if="hasWebrootSmallBusinessAntiVirus">
        <svg-icon-component icon="webroot-small-business-antivirus" class="icon-md icon-relative-move" />
        {{ t("Brand.WebrootSmallBusinessAntiVirus") }}
      </h3>
    </header>
    <article>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="tn('forRenewals')"></p>
    </article>
  </section>
</template>
<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { computed } from "vue";
import { ANDROID_MOBILE, WEBROOT_SMALL_BUSINESS_ANTIVIRUS, WINDOW_WASHER } from "@/define";
import { useBuyFlowStore } from "@/stores/buyFlow";

const componentName = "WebrootSunsetProducts";
const baseComponentName = "WebrootDownloadInstructions";
const buyFlowStore = useBuyFlowStore();
logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

const hasWindowWasher = computed(() => {
  return buyFlowStore?.cart?.items.some(item => item.sku === WINDOW_WASHER);
});

const hasAndroidMobile = computed(() => {
  return buyFlowStore?.cart?.items.some(item => item.sku === ANDROID_MOBILE);
});

const hasWebrootSmallBusinessAntiVirus = computed(() => {
  return buyFlowStore?.cart?.items.some(item => item.sku === WEBROOT_SMALL_BUSINESS_ANTIVIRUS);
});
</script>
