import { createRouter, createWebHistory, RouteComponent, RouteRecordRaw } from "vue-router";

import { showPaymentHistory } from "@/common/showPaymentHistory";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import { logEvent } from "@/common/logger";
import Home from "@/components/Home/HomeRoute.vue";
import CreateAccount from "@/components/Account/create/CreateAccountRoute.vue";
import Backup from "@/components/Backup/BackupRoute.vue";
import Downloads from "@/components/Downloads/DownloadsRoute.vue";
import PersonalInformation from "@/components/PersonalInformation/PersonalInformationRoute.vue";
import SubscriptionInformation from "@/components/Subscriptions/SubscriptionsRoute.vue";
import PaymentHistory from "@/components/payment/PaymentRoute.vue";
import AccountSetup from "@/components/Account/setup/AccountSetupRoute.vue";
import GetFiles from "@/components/Backup/GetFilesRoute.vue";
import CreditCard from "@/components/CreditCards/CreditCardRoute.vue";
import AddCreditCard from "@/components/CreditCards/AddCreditCardRoute.vue";
import Support from "@/components/support/SupportRoute.vue";
import EmailVerification from "@/components/Account/email/EmailVerificationRoute.vue";
import EmailVerificationIncomplete from "@/components/Account/email/EmailVerificationNotCompleteRoute.vue";
import FileAccess from "@/components/FileAccess/FileAccessRoute.vue";
import Courier from "@/components/courier/CourierRoute.vue";
import CourierRecovery from "@/components/courier/CourierRecoveryRoute.vue";
import Security from "@/components/Security/SecurityRoute.vue";
import Error from "@/components/error/ErrorRoute.vue";
import Unknown from "./Unknown.vue";
import AllstateSignUp from "@/components/Allstate/AllstateRoute.vue";
import CssbRfa from "@/components/FileAccess/FileAccessCSSBRoute.vue";
import CssbDownloadWatcher from "@/components/FileAccess/CssbDownloadWatcherRoute.vue";
import InstallationInstructions from "@/components/Buy/OrderConfirmation/CarboniteInstallationInstructions.vue";
import Buy from "@/components/Buy/BuyRoute.vue";
import SelectPlan from "@/components/Buy/SelectPlanRoute.vue";
import FinalizeAccount from "@/components/Buy/FinalizeAccountRoute.vue";
import OrderConfirmation from "@/components/Buy/OrderConfirmation/OrderConfirmationRoute.vue";
import SetNewUserPassword from "@/components/Account/CreateNtp/SetNewUserPassword/SetNewUserPasswordRoute.vue";
import PayPal from "@/components/PayPal/PayPalRedirectRoute.vue";
import BuyErrors from "@/components/Buy/Errors/BuyErrorsRoute.vue";
import FlatRateOfferMismatch from "@/components/Buy/Errors/FlatRateOfferMismatch.vue";
import FlatRateExpiredLicense from "@/components/Buy/Errors/FlatRateExpiredLicense.vue";
import FlatRateRemainingDaysInvalid from "@/components/Buy/Errors/FlatRateRemainingDaysInvalid.vue";
import WindowsArmInfo from "@/components/shared/WindowsArmInfo.vue";
import SafeLongExpiredComputer from "@/components/Buy/Errors/SafeLongExpiredComputer.vue";
import UnderMaintenance from "@/components/UnderMaintenance/UnderMaintenanceRoute.vue";
import PlanetOpenText from "@/components/PlanetOpenText/PlanetOpenText.vue";
import { FLAGS } from "@/define";
import { removePendoToolTip } from "@/common/pendo";
export interface IRouteMetadata {
  requireAuth?: boolean;
  hideSidebar?: boolean;
  hideTitlebar?: boolean;
  hideFooter?: boolean;
}

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "root",
    component: Unknown as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/home",
    name: "home",
    component: Home as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/createAccount",
    name: "create_account",
    component: CreateAccount as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: true,
      requireAuth: false,
      page_type: "user_form",
    },
  },
  {
    path: "/accountSetup/:skipLoginLogic",
    name: "account_setup",
    component: AccountSetup as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: true,
      requireAuth: true,
      page_type: "user_form",
    },
  },
  {
    path: "/installationInstructions",
    name: "installation_instructions",
    component: InstallationInstructions as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: true,
      requireAuth: false,
    },
  },
  {
    path: "/getFiles",
    name: "get_files",
    component: GetFiles as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: false,
      requireAuth: true,
      parent: "backup",
    },
  },
  {
    path: "/creditCard",
    name: "credit_cards",
    component: CreditCard as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      requireAuth: true,
      page_type: "payment_form",
    },
  },
  {
    path: "/fileAccess",
    name: "file_access",
    component: FileAccess as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/creditCard/add",
    name: "add_credit_cards",
    component: AddCreditCard as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: true,
      requireAuth: true,
      page_type: "payment_form",
    },
  },
  {
    path: "/paymentHistory",
    name: "payment_history",
    component: PaymentHistory as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    beforeEnter: (to, from, next) => {
      // users who only have subs managed by a reseller cannot access this page
      if (showPaymentHistory()) {
        next();
      } else {
        next({ name: "home" });
      }
    },
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/subscriptions",
    name: "subscription_information",
    component: SubscriptionInformation as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/personalInfo",
    name: "personal_information",
    component: PersonalInformation as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/support",
    name: "support",
    component: Support as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/backup",
    name: "backup",
    component: Backup as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/buy",
    name: "buy",
    component: Buy as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: true,
      requireAuth: false,
    },
  },
  {
    path: "/selectPlan",
    name: "selectPlan",
    component: SelectPlan as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: true,
      requireAuth: false,
    },
  },
  {
    path: "/buy/error",
    name: "buy_error",
    component: BuyErrors as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    children: [
      { path: "mismatch", component: FlatRateOfferMismatch as unknown as RouteComponent },
      { path: "expired", component: FlatRateExpiredLicense as unknown as RouteComponent },
      { path: "invalid", component: FlatRateRemainingDaysInvalid as unknown as RouteComponent },
      { path: "unrecoverable", component: SafeLongExpiredComputer as unknown as RouteComponent },
    ],
    meta: {
      hideSidebar: true,
      requireAuth: false,
    },
  },
  {
    path: "/finalizeAccount",
    name: "finalizeAccount",
    component: FinalizeAccount as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: true,
      requireAuth: false,
    },
  },
  {
    path: "/orderConfirmation",
    name: "orderConfirmation",
    component: OrderConfirmation as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: true,
      requireAuth: false,
    },
  },
  {
    path: "/setNewPassword",
    name: "setNewPassword",
    component: SetNewUserPassword as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: true,
      requireAuth: true,
    },
  },
  {
    path: "/downloads",
    name: "downloads",
    component: Downloads as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/windowsArmInfo",
    name: "windowsArmInfo",
    component: WindowsArmInfo as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      requireAuth: false,
      hideSidebar: true,
    },
  },
  {
    path: "/security",
    name: "security",
    component: Security as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/courier",
    name: "courier",
    component: Courier as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/courierRecovery",
    name: "courierRecovery",
    component: CourierRecovery as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/verifyEmail",
    name: "verify_email",
    component: EmailVerification as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: true,
      requireAuth: false,
      page_type: "user_form",
    },
  },
  {
    path: "/emailNotVerified",
    name: "email_not_verified",
    component: EmailVerificationIncomplete as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: true,
      requireAuth: true,
    },
  },
  {
    path: "/allstateSignUp",
    name: "allstateSignUp",
    component: AllstateSignUp as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/cssbRfa",
    name: "cssb_rfa",
    component: CssbRfa as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: false,
      requireAuth: true,
      allowSmbUser: true,
    },
  },
  {
    path: "/processDownloads",
    name: "cssb_processDownloads",
    component: CssbDownloadWatcher as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: true,
      hideTitlebar: true,
      hideFooter: true,
      requireAuth: true,
      allowSmbUser: true,
    },
  },
  {
    path: "/paypalReturn", //Need to update if required to change format to /paypal/return
    component: PayPal as unknown as RouteComponent,
    props: route => ({ ...route.params, redirectTo: "return" }),
    meta: {
      hideSidebar: true,
      requireAuth: false,
    },
  },
  {
    path: "/paypalCancel", //Need to update if required to change format to /paypal/cancel
    component: PayPal as unknown as RouteComponent,
    props: route => ({ ...route.params, redirectTo: "cancel" }),
    meta: {
      hideSidebar: true,
      requireAuth: false,
    },
  },
  {
    path: "/paypalNotify", //Need to update if required to change format to /paypal/notify
    component: PayPal as unknown as RouteComponent,
    props: route => ({ ...route.params, redirectTo: "notify" }),
    meta: {
      hideSidebar: true,
      requireAuth: false,
    },
  },
  {
    path: "/error",
    name: "network_error",
    component: Error as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: false,
      requireAuth: false,
    },
  },
  {
    path: "/underMaintenance",
    name: "underMaintenance",
    component: UnderMaintenance as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: true,
      requireAuth: false,
    },
  },
  {
    path: "/employeeBenefits",
    name: "employeeBenefits",
    component: PlanetOpenText as unknown as RouteComponent,
    props: route => ({ ...route.params }),
    meta: {
      hideSidebar: true,
      hideTitlebar: true,
      hideFooter: true,
      requireAuth: false,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: Unknown as unknown as RouteComponent,
    meta: {
      requireAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

export { routes };

import { useUserStore } from "@/stores/user";
import { UserType } from "@/globalEnums";
import { URLS } from "@/define";
import { pageView } from "@/common/googleTagEvents";
import { useSiteSettingsStore } from "@/stores/siteSettings";

// add global navigation guard
router.beforeEach(async to => {
  if (to.query.lang) {
    // the following line repairs an issue where the lang attribute is set more than once in a query string
    // and ends up something like en,en which breaks date formatting in common/dateFormat.ts
    const lang = (to.query.lang as string).split(",")[0];
    localStorage.setItem("selectedLanguage", lang);
    sessionStorage.setItem("langInQuery", "true");
  } else {
    sessionStorage.setItem("langInQuery", "false");
  }
  //do this temporarily because EBS can't change their behavior without a full regression test
  //and we want to get rid of the # in all of our URLs
  if (to.fullPath.indexOf("/#/") >= 0) {
    return to.fullPath.replace("/#/", "/");
  }

  //if we're being sent to error, then just go there.
  //unless we're already there. In that case, try going home
  //which could lead us back here if the issues have not been resolved
  if (to.path === "/error") {
    const isError = localStorage.getItem("isError");
    if (isError == "true") {
      localStorage.setItem("isError", "false");
      return "/home";
    } else {
      localStorage.setItem("isError", "true");
      return true;
    }
  }

  if (to.meta?.requireAuth) {
    const userStore = useUserStore();
    const user = userStore.currentUser;
    const siteSettings = useSiteSettingsStore();

    if (user) {
      let page_type = "account_page";
      let page_name = "";
      if (to.meta?.page_type) {
        page_type = to.meta.page_type as string;
      }
      if (to.name) {
        page_name = to.name as string;
      }
      pageView(page_name, page_type);
    }

    // check to see if we were routed with the skipLoginLogic param, and if we have a user, we can skip further logic
    if (user && to.params.skipLoginLogic === "true") {
      return true;
    }

    // user has not been set in store, try to load from API
    if (!user) {
      try {
        // required for payment history access
        await reloadSubscriberInfo();
        if (userStore.currentUser) {
          //if this is an smb user and they are attempting to visit a page they are not allowed, send them back to cp login
          if (userStore.userType == UserType.smb && !to.meta?.allowSmbUser) {
            window.location.href = URLS.CP_LOGIN;
          }
        } else return false;
      } catch (err) {
        logEvent("Failed to retrieve user", "router.beforeEach", err);
        return false; // nowhere to go yet...
      }
    }

    //If the user doesn't have a password force them to add one
    if (!userStore.isEstablishedAccount && to.name !== "setNewPassword") {
      return {
        name: "setNewPassword",
      };
    }

    // must be logged in as user response has returned value successfully
    if (siteSettings.getIsCreateAccountFlow() || siteSettings.getSkipAccountSetup()) {
      return true;
    }
    // need to load questions now to check if they are filled out
    try {
      if (!userStore.checkUserSecurityQuestionsFilled && !siteSettings.getIsCreateAccountFlow()) {
        const questions = userStore.currentUser?.subscriberInfo?.securityQuestions;
        if (!questions?.securityQuestionOne || !questions?.securityQuestionTwo || !questions?.securityQuestionThree) {
          userStore.checkUserSecurityQuestionsFilled = false;
          return {
            name: "account_setup",
            params: { skipLoginLogic: "true" },
          };
        } else {
          userStore.checkUserSecurityQuestionsFilled = true;
        }
      } else {
        return true;
      }
    } catch (err) {
      logEvent("Failed to retrieve questions", "router.beforeEach", err);
      return false; // nowhere to go yet...
    }
  } else {
    return true;
  }
});

router.afterEach(async () => {
  if (FLAGS.AUTO_CLOSE_PENDO_TOOLTIP === "true") await removePendoToolTip();
});

export default router;
