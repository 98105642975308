import axios, { AxiosError } from "axios";
import { logEvent, logException } from "@/common/logger";
import { getApiConfig, getErsoBaseUrl } from "./commonFn";
import {
  IComputerContents,
  IRfaComputer,
  IRfaItem,
  ISearchRequest,
  IItemCollection,
  IRfaSelectionInfo,
  IFileDownload,
  IImageDownload,
  ICollectionParams,
} from "./interfaces";
import { useUserStore } from "@/stores/user";
import {
  API_INITIAL_POLLING_INTERVAL_MSEC,
  API_MAX_POLLING_INTERVAL_FOR_DOWNLOAD_MSEC,
  API_MAX_POLLING_INTERVAL_MSEC,
  HTTP_STATUS_ACCEPTED,
  HTTP_STATUS_OK,
  HTTP_STATUS_UNAUTHORIZED,
} from "@/define";
import { ref } from "vue";
const componentName = "FileAccess.ersoServerComm";
export const downloadResponseError = ref<AxiosError>();
export async function checkHealth(): Promise<boolean> {
  let isPresent = false;
  try {
    logEvent("checking erso server health", componentName);
    const url = `${getErsoBaseUrl()}/Home/Check`;
    const resp = (await axios.get(url)).data;
    isPresent = resp.IsRunning;

    if (!isPresent) logEvent("erso server health check failed", componentName);
  } catch (err) {
    logException(err as Error, "checkHealth");
  }
  return isPresent;
}

export async function getComputer(): Promise<IRfaComputer | undefined> {
  let computer: IRfaComputer | undefined = undefined;
  try {
    useUserStore().lastRequestTime = new Date().getTime();
    const url = `${getErsoBaseUrl()}/Computer/root`;
    const requestId = (await axios.get(url, getApiConfig())).data.Guid as string;
    computer = await getComputerResult(requestId);
  } catch (err) {
    logException(err as Error, "getComputer");
  }
  return computer;
}

export async function getComputerResult(requestId: string): Promise<IRfaComputer | undefined> {
  const url = `${getErsoBaseUrl()}/computer/root/${requestId}`;
  return await pollApi<IRfaComputer>(url, "getComputerResult", API_MAX_POLLING_INTERVAL_MSEC);
}

export async function getComputerContents(cc: IComputerContents): Promise<IRfaItem[]> {
  let currentItems: IRfaItem[] = [];
  try {
    useUserStore().lastRequestTime = new Date().getTime();
    const url = `${getErsoBaseUrl()}/computer/contents`;
    const requestId = (await axios.post(url, { path: decodeURI(cc.path) }, getApiConfig())).data.Guid;
    currentItems = await getComputerContentsResult(requestId);
  } catch (err) {
    logException(err as Error, "getComputerContents");
  }
  return currentItems;
}

export async function getComputerContentsResult(requestId: string): Promise<IRfaItem[]> {
  const url = `${getErsoBaseUrl()}/computer/contents/${requestId}`;
  return await pollApi<IRfaItem[]>(url, "getComputerContentsResult", API_MAX_POLLING_INTERVAL_MSEC);
}

export async function pollApi<T>(url: string, methodName: string, maxPollingInterval: number): Promise<T> {
  return new Promise(async (resolve, reject) => {
    let pollingInterval = API_INITIAL_POLLING_INTERVAL_MSEC;
    const backoffFactor = 2;
    let retryCountForUnauthorizedError = 3;

    async function poll() {
      try {
        useUserStore().lastRequestTime = new Date().getTime();
        const result = await axios.get(url, getApiConfig());

        if (result.status === HTTP_STATUS_OK) {
          resolve(result.data);
        } else if (result.status === HTTP_STATUS_ACCEPTED) {
          setTimeout(async () => {
            try {
              await poll(); // Recursively call poll to continue polling
            } catch (pollingError) {
              reject(pollingError);
            }
          }, pollingInterval);

          // Calculate the next polling interval
          pollingInterval = Math.min(pollingInterval * backoffFactor, maxPollingInterval);
        } else {
          reject(new Error(`Unexpected status code: ${result.status}`));
        }
      } catch (err) {
        const axiosError = err as AxiosError;
        if (axiosError.response?.status == HTTP_STATUS_UNAUTHORIZED && retryCountForUnauthorizedError > 0) {
          // This can happen when download takes more than 10 minutes and RFA session is expired.
          console.log("Retrying polling for 401 error");
          retryCountForUnauthorizedError--;
          setTimeout(async () => {
            try {
              await poll();
            } catch (pollingError) {
              reject(pollingError);
            }
          }, pollingInterval);
        } else {
          logException(err as Error, methodName);
          reject(err);
        }
      }
    }

    // Start the initial poll
    await poll();
  });
}

export async function getSearchInfo(sr: ISearchRequest): Promise<IRfaItem[]> {
  let currentItems: IRfaItem[] = [];
  try {
    useUserStore().lastRequestTime = new Date().getTime();
    const url = `${getErsoBaseUrl()}/computer/search`;
    const searchPayload = {
      filter: sr.filter,
      path: decodeURI(sr.path),
      startIndex: sr.start,
      maxResults: sr.maxResults,
    };
    const requestId = (await axios.post(url, searchPayload, getApiConfig())).data.Guid;
    currentItems = await getSearchResult(requestId);
  } catch (err) {
    logException(err as Error, "getSearchInfo");
  }
  return currentItems;
}

export async function getSearchResult(requestId: string): Promise<IRfaItem[]> {
  const url = `${getErsoBaseUrl()}/computer/search/${requestId}`;
  return await pollApi(url, "getSearchResult", API_MAX_POLLING_INTERVAL_MSEC);
}

export async function getSelectionInfo(ic: IItemCollection): Promise<IRfaSelectionInfo | undefined> {
  let selectionInfo: IRfaSelectionInfo | undefined = undefined;
  try {
    useUserStore().lastRequestTime = new Date().getTime();
    const url = `${getErsoBaseUrl()}/computer/selectionInfo`;
    const requestId = (await axios.post(url, getSelectionInfoPayload(ic), getApiConfig())).data.Guid;
    selectionInfo = await getSelectionInfoResult(requestId);
  } catch (err) {
    logException(err as Error, "getSelectionInfo");
  }
  return selectionInfo;
}

export async function getSelectionInfoResult(requestId: string): Promise<IRfaSelectionInfo> {
  const url = `${getErsoBaseUrl()}/computer/selectionInfo/${requestId}`;
  return await pollApi(url, "getSelectionInfo", API_MAX_POLLING_INTERVAL_MSEC);
}

function getSelectionInfoPayload(ic: IItemCollection) {
  return {
    fileIds: ic.fileIds,
    onlyImages: false,
  };
}

export async function downloadFile(fd: IFileDownload, ic?: IItemCollection, deviceTrustToken = "") {
  let downloadUrl: string;
  try {
    const hasTrustToken = deviceTrustToken.length > 0;
    useUserStore().lastRequestTime = new Date().getTime();
    const url = `${getErsoBaseUrl()}/computer/download`;
    const requestId = (
      await axios.post(
        url,
        {
          fileIds: ic?.fileIds,
          hasProtectedFiles: hasTrustToken,
          trustToken: deviceTrustToken,
          skipProtectedFiles: !hasTrustToken,
        },
        getApiConfig()
      )
    ).data.Guid;
    downloadUrl = await getDownloadResult(requestId);
    if (downloadUrl) {
      await startDownloadFileOnBrowser(fd, downloadUrl);
    }
  } catch (err) {
    logException(err as Error, "downloadFile");
    downloadResponseError.value = err as AxiosError;
  }
}

async function startDownloadFileOnBrowser(fd: IFileDownload, downloadUrl: string) {
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", fd.name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export async function getDownloadResult(requestId: string): Promise<string> {
  const url = `${getErsoBaseUrl()}/computer/download/${requestId}`;
  return await pollApi<string>(url, "getDownloadResult", API_MAX_POLLING_INTERVAL_FOR_DOWNLOAD_MSEC);
}

export async function downloadImage(img: IImageDownload): Promise<Blob | undefined> {
  logEvent("downloading image", componentName, img);
  let blob: Blob | undefined = undefined;
  let downloadUrl: string;
  try {
    useUserStore().lastRequestTime = new Date().getTime();
    const url = `${getErsoBaseUrl()}/computer/download`;

    const requestId = (
      await axios.post(url, { fileIds: [img.fileId?.toString()], name: img.path, size: img.size }, getApiConfig())
    ).data.Guid;
    downloadUrl = await getDownloadResult(requestId);
    if (downloadUrl) {
      blob = (await axios.get(downloadUrl, { responseType: "blob" })).data;
    }
  } catch (err) {
    //don't display a notification to the user in this case
    //we're just going to return undefined and let the caller figure out what to do
    logException(err as Error, "downloadImage");
  }

  return blob;
}

export function getCollection(cp: ICollectionParams): IItemCollection {
  const fileIds: string[] = [];
  cp.items.forEach(element => {
    fileIds.push(element.FileId.toString());
  });
  return { fileIds: fileIds };
}
