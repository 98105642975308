<template>
  <div v-if="showSpinner" class="content">
    <LoadingSpinner />
  </div>
  <div v-else class="content">
    <PrimaryEmailVerificationWrapperComponent notification-context="SupportComponent" :email="email" />
    <h1 v-if="IsBestBuyClient" class="only-show-mobile-block">{{ tn("h1Heading") }}</h1>
    <BestBuySupportSectionComponent v-if="IsBestBuyClient && !showSpinner" />
    <div v-if="!showSpinner && hasPurchasedSubscription">
      <h1 :class="IsBestBuyClient ? 'only-show-desktop-block' : ''">{{ tn("h1Heading") }}</h1>
      <CarboniteSupportSectionComponent v-if="hasCarbonite" />
      <WebrootSupportSectionComponent v-if="hasWebroot || hasWebrootPendingLicense" />

      <div v-if="canDisplayGlobalFeature(globalFeatures.allStateUpgrade) && allStateEnabled && hasActiveAllstate">
        <h2 class="mb-half">
          {{ tn("additionalProducts") }}
        </h2>
        <AllstateSupportSectionComponent />
      </div>
      <small class="block text-right">{{ buildInfo }}</small>
    </div>
    <div v-if="!IsBestBuyClient && ((!hasCarbonite && !hasWebroot) || noSubscriptions)" class="support">
      <ExpiredSubSupportSectionComponent
        v-if="!IsBestBuyClient"
        :show-carbonite="allCarboniteExpired || noSubscriptions"
        :show-webroot="allWebrootExpired || noSubscriptions"
      />
      <div class="mb">
        <AllstateSupportSectionComponent v-if="allStateEnabled && hasActiveAllstate && language == 'en'" />
      </div>
      <small class="block text-right">{{ buildInfo }}</small>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { useUserStore } from "@/stores/user";
import { useNotificationsStore } from "@/stores/notifications";
import { BUILD_DATE, BUILD_HASH, BUILD_HASH_DISPLAY_LENGTH } from "@/define";
import { hasOnlyBestBuyS2 } from "@/common/bestBuy";
import BestBuySupportSectionComponent from "@/components/shared/BestBuySupportSection.vue";
import CarboniteSupportSectionComponent from "@/components/support/CarboniteSupportSection.vue";
import WebrootSupportSectionComponent from "@/components/support/WebrootSupportSection.vue";
import ExpiredSubSupportSectionComponent from "@/components/support/ExpiredSubSupportSection.vue";
import AllstateSupportSectionComponent from "@/components/support/AllstateSupportSection.vue";
import PrimaryEmailVerificationWrapperComponent from "@/components/shared/PrimaryEmailVerificationWrapper.vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { carboniteSkus } from "@/common/productsBySku";
import { webrootLCNs } from "@/common/webrootProductName";
import { allstateLCNs, addOnLCNs } from "@/common/webrootProductName";
import { canDisplayGlobalFeature, globalFeatures } from "@/common/featureDisplayAccess";

const componentName = "SupportComponent";

logEvent("created", componentName);
const notificationsStore = useNotificationsStore();
const showSpinner = ref<boolean>(true);
const userStore = useUserStore();
const email = ref<string>("");
const hasWebroot = ref<boolean>(false);
const hasCarbonite = ref<boolean>(false);
const hasActiveAllstate = ref<boolean>(false);
const IsBestBuyClient = ref<boolean>(false);
const hasPurchasedSubscription = ref<boolean>(false);
const hasExpiredSubscription = ref<boolean>(false);
const subscriptions = ref<ISubscription[]>([]);
const noSubscriptions = ref<boolean>(false);
const subscriptionsStore = useSubscriptionsStore();
const allStateEnabled = ref(false);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

onMounted(async () => {
  logEvent("mounted", componentName);
  email.value = user.value?.email ?? "";

  if (user.value?.subscriberInfo?.hasPendingWebrootLicenses && !user.value?.subscriberInfo?.hasValidatedEmail) {
    notificationsStore.addNotification({ type: "PrimaryEmailVerificationWebroot" });
  }
  await subscriptionsStore.populateSubscriptions();
  subscriptions.value = subscriptionsStore.subscriptions;

  hasCarbonite.value = !!(user.value?.subscriberInfo?.hasSafeSubscriptions || hasInActiveCarbonite());
  IsBestBuyClient.value = !!user.value?.subscriberInfo?.hasBestBuyS2;
  const bbOnly = await hasOnlyBestBuyS2();

  hasWebroot.value = !!(
    user.value?.subscriberInfo?.hasSafeWebroot ||
    (user.value?.subscriberInfo?.hasEcommKeyCodes && !bbOnly && hasInActiveWebroot())
  );
  hasPurchasedSubscription.value =
    hasCarbonite.value || hasWebroot.value || (allStateEnabled.value && hasActiveAllstate.value);
  hasExpiredSubscription.value = allCarboniteExpired.value || allWebrootExpired.value;
  noSubscriptions.value =
    subscriptions.value.filter(s => !addOnLCNs.includes(s.licenseCategoryName ?? ""))?.length === 0;

  await enableAllstate();
  showSpinner.value = false;
});

function hasInActiveWebroot() {
  if (subscriptions.value) {
    return subscriptions.value.some(l => webrootLCNs.includes(l.licenseCategoryName || "") && l.remainingDays >= 0);
  }
}

function hasInActiveCarbonite() {
  if (subscriptions.value) {
    return subscriptions.value.some(l => carboniteSkus.includes(l.sku || "") && l.remainingDays >= 0);
  }
  return false;
}

const hasWebrootPendingLicense = computed(() => {
  return !!user.value?.subscriberInfo?.hasPendingWebrootLicenses;
});

const allCarboniteExpired = computed(() => {
  if (subscriptions.value) {
    const carboniteAccounts = subscriptions.value.filter(l => carboniteSkus.includes(l.sku || ""));
    if (carboniteAccounts.length > 0) {
      return carboniteAccounts.every(f => f.remainingDays < 0);
    }
  }
  return false;
});

const allWebrootExpired = computed(() => {
  if (subscriptions.value) {
    const carboniteSkus = ["PersonalPlus", "PersonalPrime"];
    const webrootAccounts = subscriptions.value.filter(
      l => webrootLCNs.includes(l.licenseCategoryName || "") || carboniteSkus.includes(l.sku || "")
    );
    if (webrootAccounts.length > 0) {
      return webrootAccounts.every(f => f.remainingDays < 0);
    }
  }
  return false;
});

const user = computed(() => {
  return userStore.currentUser;
});

const buildInfo = computed(() => {
  return `${BUILD_DATE.split(".")[0]}Z ${BUILD_HASH.substring(0, BUILD_HASH_DISPLAY_LENGTH)}`;
});

const language = computed(() => userStore.selectedLanguage);
async function enableAllstate() {
  allStateEnabled.value = language.value == "en";
  // TODO: When we remove the ENABLE_ALLSTATE flag, we can delete the if below
  if (allStateEnabled.value) {
    hasActiveAllstate.value = subscriptions.value.some(
      l => allstateLCNs.includes(l.licenseCategoryName ?? "") && l.webrootStatusDescription === "Active"
    );
  }
}

watch(language, async () => {
  await enableAllstate();
});
</script>
