<template>
  <div v-if="checkIfAtLeastOneOutstandingSafe" class="outstanding-invoice">
    <h1>{{ tn("h1Heading") }}</h1>
    <ul>
      <li v-for="(sub, index) in subscriptionsWithDetails" :key="`sub${index}`" class="flex-start-md">
        <WebrootOutstandingInvoiceMessage v-if="isWebroot(sub)" :sub="sub" @renew-webroot="renewSubscription" />
        <SafeOutstandingInvoiceMessage v-else :sub="sub" @renew-safe="renewSubscription" />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { carboniteProducts } from "@/common/productsBySku";
import { FLAGS, INVOICE_FIRST_OUTSTANDING_DAY, INVOICE_LAST_OUTSTANDING_DAY } from "@/define";
import SafeOutstandingInvoiceMessage from "@/components/Subscriptions/SafeOutstandingInvoiceMessage.vue";
import WebrootOutstandingInvoiceMessage from "@/components/Subscriptions/WebrootOutstandingInvoiceMessage.vue";

const name = "OutstandingInvoiceAlertComponent";

const props = defineProps({
  subscriptions: {
    type: Array as PropType<ISubscription[]>,
    required: true,
  },
});

const emits = defineEmits(["renew-sub"]);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${name}.${v}`, params);
}

// life cycle hooks
onMounted(async () => {
  logEvent("created", name);
});

const subscriptionsWithDetails = computed(() => {
  return props.subscriptions.filter(s => showSubscriptionDetails(s));
});

function showSubscriptionDetails(sub: ISubscription): boolean {
  let showDetails = false;
  if (sub.hasOutstandingInvoices && sub.outstandingInvoice) {
    if (carboniteProducts.find(product => product.sku === sub.sku)) {
      showDetails =
        sub.outstandingInvoice.outstandingInvoiceDays <= INVOICE_FIRST_OUTSTANDING_DAY &&
        sub.outstandingInvoice.outstandingInvoiceDays >= INVOICE_LAST_OUTSTANDING_DAY;
    } else {
      //WR sub
      showDetails = FLAGS.ENABLE_BUYFLOW_WEBROOT;
    }
  }
  return showDetails;
}

function renewSubscription(sub: ISubscription) {
  emits("renew-sub", sub);
}

const checkIfAtLeastOneOutstandingSafe = computed(() => {
  //If Webroot is enabled we need to show if there are Webroot invoices not just Safe
  if (FLAGS.ENABLE_BUYFLOW_WEBROOT) {
    return props.subscriptions.length > 0;
  }

  for (const sub of props.subscriptions) {
    const isSafeSKU = carboniteProducts.find(product => product.sku === sub.sku && sub.hasOutstandingInvoices);
    if (isSafeSKU) {
      return true;
    }
  }

  return false;
});

function isWebroot(sub: ISubscription) {
  return !carboniteProducts.find(l => l.sku === sub.sku);
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.computer-name {
  max-width: 200px;
  display: flex;
}

.computer-name span {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
