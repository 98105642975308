<template>
  <div v-if="buyFlowStore.pageState === buyFlowPageStates.email"></div>
  <div v-else-if="isLoading">
    <LoadingSpinner />
  </div>
  <section v-else id="payment-section">
    <!--header-->
    <h1 class="opposite-ends">
      <span class="h1">{{ tn("payment") }}</span>
      <button
        v-if="buyFlowStore.pageState === buyFlowPageStates.reviewOrder"
        id="btnEditPayment"
        type="button"
        class="text-sm inline-btn-link no-print"
        @click="editPayment()"
      >
        {{ t("Common.edit") }}
      </button>
    </h1>
    <div v-if="displayEnterEmailMessage">{{ tn("enterEmail") }}</div>
    <!-- This might be useful when we have the select card page UX -- So not removing it for now -->
    <!-- <h2 v-if="userStore.currentUser" class="mb">{{ tn("orderFor", { userName: userFullName }) }}</h2> -->
    <ul class="tight">
      <li v-if="displayCreditCardDropDown" class="mt">
        <section id="credit-card-drop-down" class="constrain-65">
          <SelectPayment
            :payment-methods="buyFlowStore.creditCards"
            :show-details="paymentPageStore.pageState === pageStates.displayCards && userStore.isEstablishedAccount"
            :show-shipping-form="true"
            :brand="buyFlowStore.currentBrand as openTextBrands"
            @add-card="addCard"
            @selected-credit-card="prepareNextStep"
            @set-display-card-details-state="displaySelectedCardDetails"
          />
        </section>
      </li>
      <li>
        <section
          v-if="
            paymentPageStore.displayPaymentMethods &&
            FLAGS.ENABLE_BUYFLOW_PAYPAL === 'true' &&
            buyFlowStore.currentBrand === openTextBrands.Webroot
          "
        >
          <ul class="flex-start-md choose-cc-or-pp">
            <li v-if="buyFlowStore.pageState !== buyFlowPageStates.reviewOrder" class="mr">
              <label
                class="flex-start pa-half"
                :class="currentPaymentType == paymentTypes.CreditCard ? 'selected' : ''"
              >
                <input
                  id="payment-type-cc"
                  v-model="currentPaymentType"
                  type="radio"
                  name="paymentType"
                  value="CreditCard"
                  class="mr-quarter"
                  @click="setPaymentType(paymentTypes.CreditCard)"
                />
                <svg-icon-component icon="credit-card" class="mr-quarter" />
                {{ tn("paymentTypeCC") }}
              </label>
            </li>
            <li v-if="buyFlowStore.pageState !== buyFlowPageStates.reviewOrder" id="liRadioPaypal" class="mr">
              <label class="flex-start pa-half" :class="currentPaymentType == paymentTypes.PayPal ? 'selected' : ''">
                <input
                  id="payment-type-pp"
                  v-model="currentPaymentType"
                  type="radio"
                  name="paymentType"
                  value="PayPal"
                  class="mr-quarter"
                  @click="setPaymentType(paymentTypes.PayPal)"
                />
                <svg-icon-component icon="paypal-icon" class="mr-quarter" />
                {{ tn("paymentTypePP") }}
              </label>
            </li>
          </ul>
          <div
            v-if="currentPaymentType == paymentTypes.PayPal && buyFlowStore.pageState !== buyFlowPageStates.reviewOrder"
            class="mt"
          >
            <button id="btnPaypalCheckout" type="button" class="btn-shell" @click="initiatePayPalRequest()">
              <svg-icon-component icon="paypal-button" class="paypal-svg-175" />
            </button>
          </div>
        </section>
      </li>
      <li>
        <section v-if="displayAddCreditCard" id="credit-card-add-section">
          <BuyAddCardComponent
            @payment-method-added="prepareNextStep"
            @payment-method-error="handlePaymentMethodError"
          />
        </section>
        <section v-if="displaySelectedCreditCard && !displayPayPalDetails" class="constrain-65">
          <BuySelectedCreditCardComponent
            :selected-payment-method-id="buyFlowStore.cart?.paymentMethodId || ''"
            :show-all-state-non-u-s-paypal-shipping-address="true"
          />
        </section>
      </li>
      <li v-show="displayPayPalDetails" class="mt">
        <PayPalPaymentDetails />
      </li>
      <li v-if="buyFlowStore.pageState === buyFlowPageStates.payment" id="trustBadges" class="mt">
        <svg-icon-component
          v-if="!buyFlowStore.hasWebrootCartItems()"
          id="guaranteeCarbonite"
          icon="trust-badge-30"
          class="no-shrink mr"
        />
        <svg-icon-component
          v-if="buyFlowStore.hasWebrootCartItems()"
          id="guaranteeWebroot"
          icon="trust-badge-70"
          class="no-shrink mr"
        />
        <svg-icon-component icon="trust-badge-support" class="no-shrink mr" />
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { ref, onBeforeMount, watch, computed } from "vue";
import { FLAGS, PAYPAL_CARD_TYPE } from "@/define";
import { logEvent, logException } from "@/common/logger";
import BuyAddCardComponent from "./AddCreditCard.vue";
//import BuySelectPaymentComponent from "./SelectPayment.vue";
import BuySelectedCreditCardComponent from "./SelectedCreditCard.vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { useUserStore } from "@/stores/user";
import { useNotificationsStore, NotificationType } from "@/stores/notifications";
import { pageStates } from "@/components/Buy/Payment/PaymentConfigs";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { usePaymentPageStore } from "@/stores/paymentPage";
import { handlePurchaseError, loadPaymentMethods, setPaymentMethodById } from "@/components/Buy/BuyHelper";
import { changePageState } from "@/components/Buy/BuyHelper";
import { layoutStates, openTextBrands, pageStates as buyFlowPageStates, paymentTypes } from "@/components/Buy/BuyEnums";
import SelectPayment from "@/components/Buy/Payment/SelectPayment.vue";
import PayPalPaymentDetails from "@/components/Buy/Payment/PayPalPayment.vue";
import { cartUpdate } from "@/common/googleTagEvents";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

import { AxiosError } from "axios";
import { handleApiError } from "@/common/handleApiError";
import { createPayPalAgreementToken } from "./Payment";
import { carboniteSkus } from "@/common/productsBySku";
import { IPurchaseError } from "../Interfaces";
import { onMounted } from "vue";
import { usePayPalStore } from "@/stores/payPal";

const componentName = "BuyPaymentComponent";

logEvent("created", componentName);
const isLoading = ref<boolean>(false);
const currentPaymentType = ref<string>(paymentTypes.CreditCard);
const paymentPageStore = usePaymentPageStore();
const userStore = useUserStore();
const notificationsStore = useNotificationsStore();
const buyFlowStore = useBuyFlowStore();
const payPalStore = usePayPalStore();

const displayEnterEmailMessage = computed(() => {
  return buyFlowStore.pageState === buyFlowPageStates.email;
});

const displayAddCreditCard = computed(() => {
  return (
    buyFlowStore.pageState === buyFlowPageStates.payment &&
    buyFlowStore.paymentType === paymentTypes.CreditCard &&
    (paymentPageStore.pageState === pageStates.addCard || paymentPageStore.pageState === pageStates.forceAddCard)
  );
});

const displaySelectedCreditCard = computed(() => {
  return buyFlowStore.pageState === buyFlowPageStates.reviewOrder;
});

const displayCreditCardDropDown = computed(() => {
  return (
    paymentPageStore.pageState !== pageStates.forceAddCard &&
    ((buyFlowStore.pageState === buyFlowPageStates.payment &&
      paymentPageStore.pageState === pageStates.displayCards &&
      !userStore.isEstablishedAccount) ||
      (buyFlowStore.pageState === buyFlowPageStates.payment &&
        userStore.isEstablishedAccount &&
        buyFlowStore.creditCards.length > 0))
  );
});

const displayPayPalDetails = computed(() => {
  logEvent("displayPayPalDetails", "isPayPalPayment", buyFlowStore.isPayPalPayment);
  logEvent("displayPayPalDetails", "pageState", buyFlowStore.pageState);
  return buyFlowStore.isPayPalPayment && buyFlowStore.pageState === buyFlowPageStates.reviewOrder;
});

onBeforeMount(async () => {
  try {
    logEvent("mounting", componentName);
    await loadPage();
  } catch (ex) {
    logException(ex as Error);
  }
});

onMounted(() => {
  //Check if there was an error during the paypal flow
  //Checking this in the onMounted will allow it to bypass all the clearNotification() calls we do everywhere
  if (payPalStore.purchaseError) {
    //Set the page to display add paypal
    paymentPageStore.setDisplayPaymentMethods(true);
    setPaymentType(paymentTypes.PayPal);

    //display Add credit card on purchase error
    handlePaymentMethodError(`${PAYPAL_CARD_TYPE}${payPalStore.purchaseError}`);

    //Clear the error from the store
    payPalStore.purchaseError = null;
  }
});

async function loadPage() {
  isLoading.value = true;
  if (
    buyFlowStore.pageState === buyFlowPageStates.payment ||
    buyFlowStore.pageState === buyFlowPageStates.reviewOrder
  ) {
    logEvent("loadPage", componentName, paymentPageStore.pageState);

    currentPaymentType.value = buyFlowStore.paymentType
      ? buyFlowStore.paymentType
      : currentPaymentType.value
        ? currentPaymentType.value
        : "";

    //If there was an error we want to force the CC form open
    if (paymentPageStore.pageState === pageStates.forceAddCard) {
      const card = buyFlowStore.creditCards.find(l => l.id === buyFlowStore.cart?.paymentMethodId);
      logEvent("failed card data", componentName, card);
      if (card) {
        paymentPageStore.creditAddress = {
          creditCardAddress1: card.address.streetAddress1 || "",
          creditCardAddress2: card.address.streetAddress2 || "",
          creditCardPostalCode: card.address.postalCode || "",
          creditCardCity: card.address.cityName || "",
          creditCardCountry: card.address.country || "",
          creditCardState: card.address.stateOrProvince || "",
          creditCardHolderName: card.accountHolder || "",
        };
      }
      isLoading.value = false;
      return;
    }
    paymentPageStore.pageState = pageStates.addCard;
    logEvent("changing payment page state", componentName, paymentPageStore.pageState);

    if (userStore.isEstablishedAccount) {
      logEvent("established Account", componentName, userStore.currentUser);
      buyFlowStore.creditCards = (await loadPaymentMethods()) || [];

      if (buyFlowStore.cart && !buyFlowStore.cart?.paymentMethodId) {
        const card = buyFlowStore.creditCards.find(l => l.defaultPaymentMethod);
        if (card) {
          logEvent("setting card to cart", componentName, card.id);
          buyFlowStore.cart.paymentMethodId = card.id;
        }
      }
      // we need to sync this according to the buyflow page state
      buyFlowStore.checkSalesTax = true;
      await buyFlowStore.updateShoppingCartTotal();
      buyFlowStore.checkSalesTax = false;

      // if a logged in user only has a paypal payment method and adds a carbonite product to the cart, the iframe does not display ZUOR-46529
      const hasCreditCard = buyFlowStore.creditCards.some(c => c.cardType != PAYPAL_CARD_TYPE);
      const currentSku = buyFlowStore.cart?.items[0]?.sku ?? "";
      if (currentSku && carboniteSkus.includes(currentSku) && !hasCreditCard) {
        paymentPageStore.pageState = pageStates.forceAddCard;
      } else if (buyFlowStore.creditCards.length > 0) {
        paymentPageStore.pageState = pageStates.displayCards;
        logEvent("changing payment page state", componentName, paymentPageStore.pageState);
      } else {
        paymentPageStore.setDisplayPaymentMethods(true);
      }
    } else {
      buyFlowStore.updateShoppingCartTotal();
      paymentPageStore.setDisplayPaymentMethods(true);
    }
  }
  isLoading.value = false;
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function setPaymentType(type: string) {
  buyFlowStore.paymentType = type;
  currentPaymentType.value = type;
}

function displaySelectedCardDetails() {
  paymentPageStore.pageState = pageStates.displayCards;
}

async function initiatePayPalRequest() {
  sessionStorage.setItem("planetOpenTextDiscountApplied", `${buyFlowStore.planetOpenTextDiscountApplied}`);
  if (!userStore.isEstablishedAccount && !userStore.currentUser) {
    sessionStorage.setItem("IsGuestFlowForPayPal", "true");
  }
  buyFlowStore.showProcessingOrderMessage = true;
  logEvent("Initiating paypal transaction", componentName);
  try {
    const paypalAgreementTokenResponse = await createPayPalAgreementToken();
    if (paypalAgreementTokenResponse) {
      logEvent("Got the paypal agreement token, redirecting to paypal approval url", componentName);
      sessionStorage.setItem("PayPal_token_id", paypalAgreementTokenResponse.tokenId);
      sessionStorage.setItem("BuyFlow_Paypal_cart", JSON.stringify(buyFlowStore.cart));
      window.location.href = paypalAgreementTokenResponse.approvalUrl;
    }
  } catch (error) {
    handleApiError(error as AxiosError, true);
  } finally {
    buyFlowStore.showProcessingOrderMessage = false;
  }
}

function addCard() {
  paymentPageStore.pageState = pageStates.addCard;
}

function handlePaymentMethodError(error: string) {
  logEvent("handlePaymentMethodError", componentName, error);
  notificationsStore.clearNotifications();
  notificationsStore.addNotification({ type: error as NotificationType });
  if (paymentPageStore.pageState === pageStates.forceAddCard) {
    paymentPageStore.pageState = pageStates.addCard;
  } else {
    paymentPageStore.pageState =
      buyFlowStore.creditCards && buyFlowStore.creditCards.length > 0 ? pageStates.displayCards : pageStates.addCard;
  }
  logEvent("handlePaymentMethodError change state", componentName, paymentPageStore.pageState);
}

async function prepareNextStep(creditCardId: string, hasAddedCreditCard: boolean) {
  try {
    isLoading.value = true;
    logEvent("prepareNextStep", componentName, { creditCardId: creditCardId, hasAddedCreditCard: hasAddedCreditCard });

    if (hasAddedCreditCard) {
      buyFlowStore.checkSalesTax = true;
      //update the payment method id in the cart
      buyFlowStore.updateCreditCard(creditCardId);
      //get a new preview
      await buyFlowStore.updateShoppingCartTotal(true, true);
      buyFlowStore.checkSalesTax = false;
      //get the updated credit cards
      buyFlowStore.creditCards = (await loadPaymentMethods()) || [];
    } else {
      setPaymentMethodById(creditCardId);
    }

    paymentPageStore.pageState = pageStates.selectedCard;
    cartUpdate(buyFlowStore.cart, buyFlowPageStates.reviewOrder);
    changePageState(buyFlowPageStates.reviewOrder);
  } catch (error) {
    const err = error as AxiosError;
    const statusCode = err?.response?.status;
    const response = err?.response?.data as IPurchaseError;
    handlePurchaseError(response.message, statusCode);
  } finally {
    buyFlowStore.showProcessingOrderMessage = false;
    isLoading.value = false;
  }
}

watch(
  () => buyFlowStore.triggerStateChange,
  async () => {
    await loadPage();
  }
);

// If cart is being updated, we want to hide the payment information
// from the user until we get the response back because preview may contain payment errors
watch(
  () => buyFlowStore.isCalculatingCart,
  async () => {
    isLoading.value = buyFlowStore.isCalculatingCart;
  }
);

function editPayment() {
  if (FLAGS.ENABLE_BUYFLOW_PAYPAL === "true") {
    buyFlowStore.layoutState = layoutStates.editPayment;
  } else {
    editCard();
  }
}

function editCard() {
  paymentPageStore.pageState =
    userStore.currentUser && buyFlowStore.creditCards.length > 0 ? pageStates.displayCards : pageStates.addCard;
  changePageState(buyFlowPageStates.payment);
}
</script>
