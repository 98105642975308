<template>
  <div v-if="isLoading">
    <LoadingSpinner />
  </div>
  <!--header-->
  <div v-else id="receipt">
    <h1>
      <div class="opposite-ends align-items-center">
        <div>
          {{ tn("h1Receipt") }}
        </div>
        <button id="print" type="button" class="text-sm inline-btn-link no-print" @click="print">
          {{ tn("print") }}
        </button>
      </div>
    </h1>
    <!--content-->
    <p id="emailNoteUnauthenticated" class="text-grey mb">
      {{ tn("confirmationEmailNoteUnauthenticated") }}
    </p>
    <!--billing information-->
    <ul class="mb">
      <li v-if="user.currentUser">
        <p id="userEmail" class="truncate">{{ user.currentUser?.email }}</p>
      </li>
      <li>
        {{ tn("dateOfPurchase") }}
        <span>{{ formatDateString(new Date().toDateString()) }}</span>
      </li>
      <li v-if="isAutoRenewEnabled" id="subscription-renewal-text">
        {{ tn("subscriptionRenews") }}
      </li>
      <li v-else id="subscription-ends-text">
        {{ tn("subscriptionEnds") }}
      </li>
      <li v-if="creditCardFromPurchase?.cardType !== PAYPAL_CARD_TYPE && creditCardFromPurchase?.lastDigits">
        <span>{{ creditCardFromPurchase?.cardType }}</span>
        {{ tn("endingIn") }}
        <span>*{{ removeStars(creditCardFromPurchase?.lastDigits) }}</span>
      </li>
      <li v-else-if="creditCardFromPurchase?.cardType === PAYPAL_CARD_TYPE">
        {{ tn("paypalPayment", { email: creditCardFromPurchase?.email }) }}
      </li>
    </ul>
    <!--purchases table-->
    <table class="full-width no-borders-th-td adjustments-table">
      <thead>
        <tr>
          <th class="qty">{{ tn("colQty") }}</th>
          <th class="py-half">{{ t("Common.subscription") }}</th>
          <th class="py-half amount">{{ tn("colAmount") }}</th>
        </tr>
      </thead>
      <!--purchased item - purchases table-->
      <tbody>
        <tr v-for="(cartItem, index) in getItemsForReceipt()" :id="`cart-item-${cartItem.sku}-${index}`" :key="index">
          <td class="qty vertical-align-top">{{ cartItem.quantity }}</td>
          <td colspan="2">
            <div class="opposite-ends">
              <div v-if="!isWebrootProduct(cartItem.sku)" class="pb-quarter">
                {{ t(getProductDisplayName(cartItem.sku)) }}
                <span v-if="buyFlowStore.getMonthsForItem(cartItem) === 1">
                  - {{ t("BuyflowComponent.monthlySubscription") }}
                </span>
                <span v-else-if="buyFlowStore.getMonthsForItem(cartItem) > 1">
                  - {{ getYearFromCartItem(cartItem) }} {{ t(getYearOrYearsText(getYearFromCartItem(cartItem) || 0)) }}
                  <span v-if="computerName">
                    {{ tn("forComputer") }}
                    <strong>{{ computerName }}</strong>
                  </span>
                </span>
              </div>
              <div v-else class="pb-quarter">
                {{ t(getProductDisplayName(cartItem.sku)) }}
                <span v-if="!hideProductDetails(cartItem)">
                  <span v-if="getNumberOfDevices(cartItem) > 0">
                    - {{ getNumberOfDevices(cartItem) }} {{ t(getDeviceDisplayString(cartItem)) }}
                  </span>
                  <span v-if="getNumberOfIdentities(cartItem) > 0">
                    - {{ getNumberOfIdentities(cartItem) }} {{ t(getIdentityDisplayString(cartItem)) }}
                  </span>
                  <span>&nbsp;- {{ getYearFromCartItem(cartItem) }} {{ t("Common.yearCapital") }}</span>
                </span>
              </div>
              <div id="cart-item-amount" class="amount pb-quarter">{{ formatMoney(cartItem.total) }}</div>
            </div>
            <div v-if="cartItem.expirationDate" class="text-grey">
              {{ tn("subscriptionExpiresOn", { date: formatDateString(cartItem.expirationDate) }) }}
            </div>
            <Adjustments v-if="cartItem.adjustments" :adjustments="cartItem.adjustments || []" />
            <hr />
          </td>
        </tr>
        <tr>
          <td class="qty">&nbsp;</td>
          <td colspan="2">
            <Adjustments v-if="buyFlowStore.cart?.adjustments" :adjustments="buyFlowStore.cart?.adjustments || []" />
            <hr />
          </td>
        </tr>
        <tr>
          <td class="qty">&nbsp;</td>
          <td class="text-right pt pb pr-double">
            <strong>{{ tn("total") }}</strong>
          </td>
          <td id="cart-total" class="amount">{{ formatMoney(buyFlowStore.cart?.total || 0) }}</td>
        </tr>
        <tr>
          <td colspan="2" class="text-right pr-double">
            <strong>{{ tn("paid") }}</strong>
          </td>
          <td cid="cart-paid" class="amount">{{ formatMoney(buyFlowStore.cart?.total || 0) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup lang="ts">
//Components
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import Adjustments from "@/components/Buy/Adjustments.vue";
//Others
import { computed, onBeforeMount, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { useUserStore } from "@/stores/user";
import { ICartItem } from "@/common/api/unifiedPortal/interfaces";
import { removeStars, formatMoney } from "@/components/Buy/BuyHelper";
import { formatDateString } from "@/common/dateFormat";
import { getProductDisplayNameBySku } from "@/common/productsBySku";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import { getComputerName } from "@/components/Buy/BuyHelper";
import { unifiedApi } from "@/common";
import { openTextBrands } from "@/components/Buy/BuyEnums";
import { webrootCDSku } from "@/common/webrootProductName";
import { PAYPAL_CARD_TYPE } from "@/define";

const componentName = "BuyReceiptComponent";

logEvent("created", componentName);

const buyFlowStore = useBuyFlowStore();
const user = useUserStore();
const isLoading = ref<boolean>(false);
const computerName = ref<string>("");

const creditCardFromPurchase = computed(() => {
  return buyFlowStore.creditCards.find(l => l.id === buyFlowStore.cart?.paymentMethodId);
});

if (buyFlowStore.isRenewOrUpgrade) {
  computerName.value = getComputerName(buyFlowStore.selectedPlan?.computerId);
}

//Check if any subs were bought with auto renew.
//Will need to update once we support multiple items in the cart
const isAutoRenewEnabled = computed(() => {
  return buyFlowStore.cart?.items.some(l => l.autoRenew === true); //return buyFlowStore.cart?.items[0].autoRenew;
});

onBeforeMount(async () => {
  logEvent("mounting", componentName);
  await checkLoggedStatus();
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function getYearFromCartItem(cartItem: ICartItem) {
  const term = buyFlowStore.getTermFromItem(cartItem);
  if (term) {
    const years = term.months / 12;

    return years;
  }
}

function getYearOrYearsText(years: number) {
  return years > 1 ? "Common.years" : "Common.year";
}

function getProductDisplayName(sku: string) {
  return getProductDisplayNameBySku({ sku: sku, isTrial: false });
}

function isWebrootProduct(sku: string) {
  return buyFlowStore.getCatalogItemBySku(sku)?.brand === openTextBrands.Webroot;
}

function getRatePlan(cartItem: ICartItem) {
  const product = buyFlowStore.getCatalogItemBySku(cartItem.sku);
  return product?.ratePlans?.find(l => l.ratePlanId === cartItem.ratePlanId);
}

function getNumberOfDevices(cartItem: ICartItem) {
  return getRatePlan(cartItem)?.includedUnits || 0;
}

function getDeviceDisplayString(cartItem: ICartItem) {
  return getNumberOfDevices(cartItem) > 1 ? "Common.devices" : "Common.device";
}

function getIdentityDisplayString(cartItem: ICartItem) {
  return getNumberOfIdentities(cartItem) > 1 ? "Common.identities" : "Common.identity";
}

function getNumberOfIdentities(cartItem: ICartItem) {
  return getRatePlan(cartItem)?.includedIdentities || 0;
}

const productsThatHideDetails = [webrootCDSku];
function hideProductDetails(cartItem: ICartItem) {
  return productsThatHideDetails.some(l => l === cartItem.sku);
}

function getItemsForReceipt() {
  const items: ICartItem[] = [];

  if (buyFlowStore.cart?.items) {
    for (const item of buyFlowStore.cart.items) {
      items.push(item);
      if (item.bundledItems) {
        items.push(...item.bundledItems);
      }
    }
  }

  return items;
}

// Check if user is authenticated and reload user store if empty
// This will prevent a authenticated user get on a unauthenticated state if page refreshed
async function checkLoggedStatus() {
  try {
    const currentUser = (await unifiedApi.getUser(true))?.data;
    if (currentUser && !user.currentUser) {
      await reloadSubscriberInfo();
    }
  } catch {
    logEvent("User not logged in", componentName);
  }
}

function print() {
  window.print();
}
</script>
