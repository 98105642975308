import { createApp, h } from "vue";

import "./styles/index.css";
import "vue-final-modal/style.css";

import { createPinia } from "pinia";
import router from "@/routes";
import i18n from "@/i18n";
import { createVfm } from "vue-final-modal";
import { vMaska } from "maska/vue";
import App from "./App.vue";

const app = createApp({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render: () => h(App as any),
}).directive("maska", vMaska);

const store = createPinia();
const vfm = createVfm();

app.use(store);
app.use(router);
app.use(vfm);
app.use(i18n);

import { logEvent, logException } from "@/common/logger";

//this occurs when vue detects an unhandled exception within a component
//NOTE: the level of detail is only to the component and the context
//to get more precision, LogEvent() calls MUST have happened to give a clue
//as to what the user was doing prior to the exception.
app.config.errorHandler = (err, vm, info) => {
  logException(err as Error, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: (vm?.$.vnode?.type as any)?.name, //the name of the component - type is officially a App.VNodeTypes
    source: vm?.$el?.baseURI, //the uri shown in the address bar
    info, //Vue specific error information such as lifecycle hooks, events etc.
  });
};

//all the vue warnings end up here. Since vue warnings are actually errors
//that could affect display, record them.
app.config.warnHandler = (msg, instance, trace) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logEvent(msg, (instance?.$.vnode?.type as any)?.name, trace);
  return null;
};

//this handles otherwise unhandled exceptions that happen outside of a Vue component
//generally, we should get an error object (the last parameter)
//if not, then we'll treat this like an event instead
//note that JS code embedded within the HTML will always give lineno = 1
//however, if you click on the link provided in the console, it will show the exact line of js
window.onerror = function (message, source, lineno, colno, error) {
  if (error) {
    logException(error, {
      message,
      source,
      lineno,
      colno,
    });
  } else if (message instanceof Event) {
    logEvent(message.type, source, {
      lineno,
      colno,
    });
  } else
    logEvent(message, source, {
      lineno,
      colno,
    });
  return true;
};

//store environment details (consider using sessionStorage)
import { getOsFamily, isMobile } from "@/common/browserDetect";
localStorage.setItem("isMobile", (!isMobile()).toString());
localStorage.setItem("getOS", getOsFamily());

//language parameter default value entry point
if (!localStorage.getItem("selectedLanguage")) {
  localStorage.setItem("selectedLanguage", "en");
}

import { googleTagInitialize } from "@/common/googleTagEvents";
import { googleTagConfigSettings } from "./define";
googleTagInitialize(googleTagConfigSettings.GOOGLE_TAG_KEY_CARB);
googleTagInitialize(googleTagConfigSettings.GOOGLE_TAG_KEY_WR);

import { initialize } from "@/common";
initialize().then(() => {
  app.mount("#app");
});
