// ************
// view functions
// ************

import { computed, ref } from "vue";
import { logEvent, logException } from "@/common/logger";
import i18n, { t } from "@/i18n";
import { BYTES_IN_GIGABYTE, BYTE_SCALE, FLAGS } from "@/define";
import { formatFileDate } from "@/common/dateFormat";
import { allItems, componentName, isSmb } from "./commonFn";
import { currentDirectory } from "./diskFn";
import { ViewTypes } from "./FileAccessEnums";
import { IRfaItem, IRfaSelectionInfo } from "./interfaces";
import { getComputerName } from "./serverComm";
import { registerRefreshFunction } from "@/components/shared/LanguageSelector/LanguageSelector";

const MIN_PCT_IMAGES_FOR_TILES = 0.75;

export const listClass = "file-view";
export const tileClass = "icon-view";
export const i18nRoot = "FileAccessComponent.";

//text
//calculating the text here rather than directly in the template is more efficient since in the template
//a separate instance of the string is created for every item in the v-for, but here only once instance is created
//that is shared. As well, vue has trouble releasing the strings to the GC and we end up with a *lot* of detached
//items that eventually would cause the browser tab to crash.
export const searchPlaceholderText = computed(() => t(`${componentName}.searchPlaceholder`));
export const searchTitleText = computed(() => `${t(`${componentName}.search`)} ${currentDirectory.value}`);
export const clearSearchResultsText = computed(() => t(`${componentName}.clearSearchResults`));
export const selectAllText = computed(() => t(`${componentName}.selectAll`));
export const selectForDownloadTitleText = computed(() => t(`${componentName}.selectForDownloadTitle`));
export const downloadZipInfoText = computed(() => t(`${componentName}.downloadZipInfo`));
export const viewClass = ref<string>(listClass);

export const isTileView = computed(() => {
  return viewClass.value == tileClass;
});

export const computerName = computed(() => {
  return getComputerName();
});

export function setView(viewType: ViewTypes) {
  switch (viewType) {
    case ViewTypes.list:
      viewClass.value = listClass;
      break;
    case ViewTypes.tile:
      viewClass.value = tileClass;
      break;
  }
  logEvent("switching view", componentName, viewClass.value);
}

//decide whether to go into tile mode based on image count
export function selectView(imageCount: number) {
  if (!isTileView.value) {
    const pctImage = imageCount / allItems.value.length;
    if (pctImage >= MIN_PCT_IMAGES_FOR_TILES) setView(ViewTypes.tile);
  }
}

//get the file extension so we can figure out which icon to show
export function getFileExtension(fileName: string): string {
  const ext = /^.+\.([^.]+)$/.exec(fileName);
  return ext == null ? "" : ext[1];
}

export function getItemSecondaryInfo(item: IRfaItem, isTileView: boolean): string {
  let info = "";

  try {
    //Don't show any info for these types
    if (item.IsBackupRun) {
      return info;
    }

    if (item.IsBackupSet) {
      info = `${item.ModifiedDate && !isTileView ? "-" : ""} ${formatFileDate(item.ModifiedDate)}`;
    }

    if (item.IsDirectory) {
      if (item.FolderItemCount && item.FolderItemCount > 0) {
        info = i18n.global.t(`${i18nRoot}files`, [intlNum.format(item.FolderItemCount)]);
      }
    } else if (item.IsBackupSet) {
      info = `${item.ModifiedDate && !isTileView ? "-" : ""} ${formatFileDate(item.ModifiedDate)}`;
    } else {
      info = `${item.Modified && !isTileView ? "-" : ""} ${formatFileDate(item.Modified)}`;
    }
  } catch (err) {
    logException(err as Error, { item, isTileView });
  }

  return info;
}

//translate from file extension to icon
export function getFileExtensionIconName(
  fileName: string,
  isDirectory: boolean,
  isBackupSet: boolean,
  isBackupRun: boolean,
  isInvalid = false
): string {
  let icon = isDirectory ? "folder" : "file";
  icon = isBackupSet ? "backup-set" : icon;
  icon = isBackupRun ? "drive" : icon;

  switch (getFileExtension(fileName).toLowerCase()) {
    case "jpg":
    case "jpeg":
    case "gif":
    case "bmp":
    case "png":
    case "tif":
    case "tiff":
      icon = isInvalid ? "file-broken-image" : "file-image";
      break;
    case "m4a":
    case "m4p":
    case "m4b":
    case "m4r":
    case "aac":
    case "aif":
    case "iff":
    case "m3u":
    case "mid":
    case "mp3":
    case "mpa":
    case "ra":
    case "ram":
    case "wav":
    case "wma":
      icon = "file-audio";
      break;
    case "pdf":
      icon = "file-pdf";
      break;
    case "doc":
    case "docx":
    case "rtf":
      icon = "file-word";
      break;
    case "pkg":
    case "dmg":
    case "numbers":
    case "pages":
    case "key":
      icon = "file-apple";
      break;
    case "xls":
    case "xlsx":
      icon = "file-excel";
      break;
    case "htm":
    case "html":
    case "url":
      icon = "file-web";
      break;
    case "txt":
      icon = "file-text";
      break;
    case "ppt":
    case "pptx":
      icon = "file-powerpoint";
      break;
    case "zip":
      icon = "file-zip";
      break;
    case "pst":
      icon = "file-outlook";
      break;
    case "mp4":
    case "avi":
    case "wmv":
    case "mov":
    case "mpg":
    case "m4v":
    case "3gp":
    case "3gpp":
    case "3g2":
    case "3gpp2":
      icon = "file-video";
      break;
  }
  return icon;
}

// *************
// messages
// *************

export const MAX_DOWNLOAD_COUNT = 5000;

const INFO_MESSAGE_INDEX = 0;
const SIZE_EXCEEDED_INDEX = 1;
const COUNT_EXCEEDED_INDEX = 2;
export const fileSelectionLimitReached = ref<boolean>(false);
const maxDownloadSize = computed(() => {
  return isSmb.value ? 50 * BYTES_IN_GIGABYTE : 10 * BYTES_IN_GIGABYTE;
});

let downloadMessages: string[] = [];

function refreshDownloadMessagesStrings() {
  //Check for isSmb here because isSmb may not be available when this first runs
  const maxDownloadSizeGB = isSmb.value ? 50 : 10;
  downloadMessages = [
    t(`${i18nRoot}info`, { maxDownloadSize: maxDownloadSizeGB }),
    t(`${i18nRoot}sizeExceeded`, { maxDownloadSize: maxDownloadSizeGB }),
    t(`${i18nRoot}countExceeded`),
    t(`${i18nRoot}bothExceeded`, { maxDownloadSize: maxDownloadSizeGB }),
  ];
}

registerRefreshFunction(refreshDownloadMessagesStrings, true);

//get a message warning the user about too large a download if needed
export function getDownloadMessage(info: IRfaSelectionInfo | undefined) {
  //Move Initialization  to here so isSmb variable is available
  refreshDownloadMessagesStrings();
  let msg = "";

  if (info) {
    let msgIndex = INFO_MESSAGE_INDEX;
    if (info.EstimatedFileSize > maxDownloadSize.value) msgIndex += SIZE_EXCEEDED_INDEX;
    if (info.NumFiles > MAX_DOWNLOAD_COUNT) msgIndex += COUNT_EXCEEDED_INDEX;
    msg = downloadMessages[msgIndex];
    fileSelectionLimitReached.value = msgIndex > 0 ? true : false;
  }
  return msg;
}

const DELETED_DAY = 0;
const LAST_WARNING_DAY = 1;
const MIN_MILD_WARNING_DAY = 15;
const MIN_FIRST_WARNING_DAY_CONSUMER = 30;
const MIN_FIRST_WARNING_DAY_SMB = 60;

//get a message warning the user about the state of the file, if needed
export function calcAlert(item: IRfaItem) {
  item.AlertMessage = "";
  item.AlertLevel = "";
  if (!item.IsRecoverMode && item.IsMissing) {
    if (item.NumberOfDays == DELETED_DAY) {
      item.AlertMessage = `${t(`${i18nRoot}fileWasDeleted`)} ${t(`${i18nRoot}fileWillBeDeleted`)}`;
      item.AlertLevel = "warning";
    } else if (item.NumberOfDays == LAST_WARNING_DAY) {
      item.AlertMessage = `${t(`${i18nRoot}fileWasDeleted`)} ${t(`${i18nRoot}fileAvailableSingular`)}`;
      item.AlertLevel = "warning";
    } else if (item.NumberOfDays <= MIN_MILD_WARNING_DAY) {
      item.AlertMessage = `${t(`${i18nRoot}fileWasDeleted`)} ${t(`${i18nRoot}fileAvailablePlural`, {
        days: item.NumberOfDays.toString(),
      })}`;
      item.AlertLevel = "warning";
    } else if (
      (item.IsSMB && item.NumberOfDays <= MIN_FIRST_WARNING_DAY_SMB) ||
      (!item.IsSMB && item.NumberOfDays <= MIN_FIRST_WARNING_DAY_CONSUMER)
    ) {
      item.AlertMessage = `${t(`${i18nRoot}fileWasDeleted`)} ${t(`${i18nRoot}fileAvailablePlural`, {
        days: item.NumberOfDays.toString(),
      })}`;
      item.AlertLevel = "warning";
    }
  } else if (
    FLAGS.ENABLE_RESTRICTED_FILES_DOWNLOAD === "false" &&
    !item.IsDownloadable &&
    !item.IsDirectory &&
    !item.IsBackupRun &&
    !item.IsBackupSet
  ) {
    item.AlertMessage = t(`${i18nRoot}restrictedFile`);
  }
}

// *************
// size translations
// *************

const MAX_UNIT_NAMES = 4;
const MAX_FRACTION_DIGITS = 2;
let byteUnitNames = [
  t(`${i18nRoot}bytes`),
  t(`${i18nRoot}kilobytes`),
  t(`${i18nRoot}megabytes`),
  t(`${i18nRoot}gigabytes`),
  t(`${i18nRoot}terabytes`),
];

function refreshByteUnitNamesStrings() {
  byteUnitNames = [
    t(`${i18nRoot}bytes`),
    t(`${i18nRoot}kilobytes`),
    t(`${i18nRoot}megabytes`),
    t(`${i18nRoot}gigabytes`),
    t(`${i18nRoot}terabytes`),
  ];
}

registerRefreshFunction(refreshByteUnitNamesStrings, true);

const intlNum = Intl.NumberFormat("en-US", { maximumFractionDigits: MAX_FRACTION_DIGITS, minimumFractionDigits: 0 });

export function convertSize(amount: number, showZero = false): string {
  if (amount == 0) {
    if (!showZero) return "";
    return `0  ${byteUnitNames[0]}`;
  }

  let unitIndex = 0;
  while (amount >= BYTE_SCALE && unitIndex < MAX_UNIT_NAMES) {
    amount /= BYTE_SCALE;
    unitIndex++;
  }

  return `${intlNum.format(amount)} ${byteUnitNames[unitIndex]}`;
}
