<template>
  <ul>
    <li v-for="(item, i) in props.invoice.subscriptionPaymentItems" :key="i">
      <ul class="mb-half">
        <li>{{ getProductName(item.sku) }}</li>
        <li v-if="!hideYearsForProducts.some(l => l === item.sku)">
          {{ tn("quantity") }} {{ item.quantity }} ({{ getYears(item) }}
          {{ t(`Common.year${getYears(item) === 1 ? "" : "s"}`) }})
        </li>
        <li v-else>{{ tn("quantity") }} {{ item.quantity }}</li>
      </ul>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { t } from "@/i18n";
import {
  IUserPaymentHistoryPaymentInfo,
  IUserPaymentHistoryPaymentInfoItems,
} from "@/common/api/unifiedPortal/interfaces";
import { MONTHS_IN_YEAR } from "@/define";
import { webrootCDSku, webrootLicenseCategories } from "@/common/webrootProductName";

const props = defineProps({
  invoice: {
    type: Object as PropType<IUserPaymentHistoryPaymentInfo>,
    required: true,
  },
  localizedStringComponentName: {
    type: String,
    default: "PaymentHistoryComponent",
  },
});

const hideYearsForProducts = [webrootCDSku];

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${props.localizedStringComponentName}.${v}`, params);
}

function getProductName(sku: string) {
  return t(`Brand.${sku === webrootLicenseCategories.Webroot ? webrootLicenseCategories.WSAV : sku}`);
}

function getYears(item: IUserPaymentHistoryPaymentInfoItems) {
  let years = 1;
  years = item.subscriptionMonths / MONTHS_IN_YEAR;

  return Math.trunc(years);
}
</script>
