<template>
  <section>
    <h1>{{ t("Common.nextSteps") }}</h1>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p v-html="tn('nextStepsWebrootAllStepsMyAccount')"></p>
    <p>
      {{ tn("nextStepsWebrootEstablishedP2") }}
    </p>
    <div class="mb">
      <button id="btn2GoToDownloadsFromFullAccount" type="button" class="btn-primary" @click="goToDownloads()">
        {{ t("Common.goToDownloads") }}
      </button>
    </div>
  </section>
</template>
<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { continueToMyAccount } from "../BuyHelper";

const componentName = "WebrootNextStepsEstablished";
const baseComponentName = "NextSteps";
logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

function goToDownloads() {
  continueToMyAccount();
}
</script>
