<template>
  <ul v-if="unsupported" class="form">
    <li class="danger-text">
      {{ t(`${parentComponentName}.${productCode}Unsupported`) }}
    </li>
    <li>
      {{ t(`${parentComponentName}.${productCode}UnsupportedReference`) }}
      <Keycodes :keycodes="keycodes" />
    </li>
  </ul>
  <ol v-else class="form">
    <li>
      <p>{{ t(`${parentComponentName}.${productCode}StepOne`) }}</p>
      <SelectorComponent :product-code="productCode" />
      <div v-if="hasAnyBestBuySubs" class="mt-quarter">
        <button class="inline-btn-link" @click="goToArmInfoPage">
          {{ t(`${parentComponentName}.windowsArmInfoLink`) }}
        </button>
      </div>
    </li>
    <li>
      <p>{{ t(`${parentComponentName}.${productCode}StepTwo`) }}</p>
      <Keycodes :keycodes="keycodes" />
    </li>
  </ol>
  <p>{{ t(`${parentComponentName}.timeDelayWarning`) }}</p>
</template>

<script setup lang="ts">
import { PropType, ref, computed, onMounted } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscriberDownloadsInfo } from "@/common/api/unifiedPortal/interfaces";
import Keycodes from "@/components/Downloads/Keycodes.vue";
import SelectorComponent from "@/components/shared/dialogs/DownloadProduct/Selector.vue";
import { getUrl } from "@/common/getUrl";
import { hasAnyBestBuySubscriptions } from "@/common/bestBuy";

const productCode = "WSAP";
const componentName = `${productCode}ProductComponent`;
const parentComponentName = "DownloadProductComponent";

const props = defineProps({
  keycodes: {
    type: Object as PropType<ISubscriberDownloadsInfo>,
    default: null,
  },
  isUnsupportedOs: {
    type: Boolean,
    default: null,
  },
});

logEvent("created", componentName, props);
const unsupported = computed(() => props.isUnsupportedOs);
const hasAnyBestBuySubs = ref(false);

onMounted(async () => {
  hasAnyBestBuySubs.value = await hasAnyBestBuySubscriptions();
});

function goToArmInfoPage() {
  window.open(`${getUrl("VITE_APP_URL")}windowsArmInfo`, "_blank");
}
</script>
