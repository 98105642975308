<template>
  <div class="card">
    <article class="opposite-ends align-items-center">
      <div class="info">
        <ul class="mt-quarter">
          <li>
            <strong>
              <span>{{ t(productName) }}</span>
              <span v-if="device.isTrial">{{ t("Common.backupTrial") }}</span>
            </strong>
          </li>
          <li>
            <span v-if="device.isEvergreenMonthly">{{ tn("activeUntilCancelled") }}</span>
            <span v-else>{{ tn("expires") }} {{ expirationDate() }}</span>
          </li>
        </ul>
      </div>
      <div class="install">
        <button id="btnInstallSafeClient" class="btn-primary" :disabled="isMobileClient" @click="installSafeClient">
          {{ tn("installNow") }}
        </button>
      </div>
    </article>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IDevice } from "@/common/api/unifiedPortal/interfaces";
import { useNotificationsStore } from "@/stores/notifications";
import { isMobile } from "@/common/browserDetect";
import { formatDateString, formatDate } from "@/common/dateFormat";
import { getProductDisplayNameBySku, IProductDisplayRequest } from "@/common/productsBySku";

const name = "CardUninstalledSubComponent";

const props = defineProps({
  device: {
    type: Object as PropType<IDevice>,
    required: true,
  },
});

const emits = defineEmits(["ac-selected"]);

const notificationsStore = useNotificationsStore();
const isMobileClient: boolean = isMobile();

logEvent("created", name, props.device);
logEvent("Activation Code", name, props.device.activationCode);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${name}.${v}`, params);
}

function expirationDate() {
  return props.device.expirationDate ? formatDateString(props.device.expirationDate) : formatDate(new Date()); //temp, will need a API change to return the correct value here.
}

const productName = computed(() => {
  const request: IProductDisplayRequest = {
    sku: props.device.sku,
    webrootKeycode: props.device.webrootKeycode,
    isTrial: props.device.isTrial,
  };
  return getProductDisplayNameBySku(request);
});

function installSafeClient() {
  notificationsStore.clearNotifications();
  logEvent("selected", name, props.device);
  emits("ac-selected", props.device, "Install");
}
</script>
