<template>
  <TileWrapperComponent :tile-data="td" :is-loading="false" notification-context="">
    <template #title>{{ t(`Brand.${td.title}`) }}</template>
    <template #content>
      <div class="border-bottom mt">
        <h4 v-if="isMonthly">
          {{ t("SecurityComponent.expires") }}
          {{ t("SecurityComponent.activeUntilCancelled") }}
        </h4>
        <h4 v-else-if="isExpired">{{ t("SecurityComponent.expired") }}</h4>
        <h4 v-else-if="isExpiring">
          {{
            t("SecurityComponent.expiresIn", {
              daysToExpire: daysToExpire,
              daysNoun: daysNoun,
              expirationDate: expirationDate(),
            })
          }}
        </h4>
        <h4 v-else-if="isNotActive">{{ t("SecurityComponent.isNotActiveYet") }}</h4>
        <h4 v-else>{{ t("SecurityComponent.expires") }} {{ expirationDate() }}</h4>
        <div v-if="showBestBuyContactLink" class="mt-half">
          <button :id="`contactBbyBtn_${htmlIdentifier}`" class="btn-primary" @click="contactBbyLink()">
            {{ t("SecurityComponent.bbyBtn") }}
          </button>
        </div>
        <div v-else-if="showConvertBBYToWebRootBuyLink" class="mt-half">
          <button
            :id="`ConvertBBYToWebrootBuyBtn_${htmlIdentifier}`"
            class="btn-primary"
            @click="buyWebrootSubscription()"
          >
            {{ t("SecurityComponent.buyBtn") }}
          </button>
        </div>
        <div v-else-if="showBuyWebrootLinkForTrial" class="mt-half">
          <button :id="`buyWebrootTrialBtn_${htmlIdentifier}`" class="btn-primary" @click="buyWebrootSubscription()">
            {{ t("SecurityComponent.buyBtn") }}
          </button>
        </div>
        <div v-else-if="showRenewWebrootLink" class="mt-half">
          <button :id="`renewWebrootBtn_${htmlIdentifier}`" class="btn-primary" @click="renewWebrootSubscription()">
            {{ t("SecurityComponent.renewBtn") }}
          </button>
        </div>
        <div v-else-if="showSafeWebrootRenewLink" class="mt-half">
          <button :id="`renewSafeWebrootBtn_${htmlIdentifier}`" class="btn-primary" @click="renewSafeWebroot()">
            {{ t("SecurityComponent.renewBtn") }}
          </button>
        </div>
        <div v-else-if="showRenewSafeLink" class="mt-half">
          <button :id="`renewSafeBtn_${htmlIdentifier}`" class="btn-primary" @click="renewSafe()">
            {{ t("SecurityComponent.renewBtn") }}
          </button>
        </div>
        <div v-else-if="showBuySafeLink" class="mt-half">
          <button :id="`buySafeBtn_${htmlIdentifier}`" class="btn-primary" @click="buySafe()">
            {{ t("SecurityComponent.buyBtn") }}
          </button>
        </div>
      </div>
      <div v-if="(td.devices?.length ?? 0) > 0">
        <ul class="tile holds-shields">
          <li v-for="item in filteredDevices" :key="item.id" :class="deviceClass(item)">
            <DeviceComponent :device="item" :license-category-name="td.title" :is-expired="isExpired" />
          </li>
          <li v-if="displayLastPass">
            <LastPassSecurityTabComponent
              :last-pass-info="lastPassData"
              :webroot-sub-info="webrootSubscriptionInformation"
              @open-last-pass-modal="openLastPassModal"
            />
          </li>
        </ul>
        <div>
          <input :id="`showHideAllDevices_${htmlIdentifier}`" v-model="showAllDevices" type="checkbox" />
          {{ t("SecurityComponent.showHiddenDevices") }}
        </div>
      </div>
      <div v-else-if="isChromebookLicense">
        <ul class="border-bottom-list">
          <li>
            <p class="mb italic-text">{{ t("SecurityComponent.WSCBUnavailable") }}</p>
            <p class="mb">{{ t("SecurityComponent.getStartedWSCB") }}</p>
            <p>
              <button
                :id="`btnInstallApps_${htmlIdentifier}`"
                class="btn-outline-primary"
                :disabled="isExpired"
                @click="openWSCBDownload()"
              >
                {{ t("SecurityComponent.getTheApp") }}
              </button>
            </p>
          </li>
        </ul>
      </div>
      <ul v-else class="border-bottom-list">
        <li>
          <h3 class="mb">{{ t("SecurityComponent.noDevicesHeader") }}</h3>
          <p class="mb-half">{{ t("SecurityComponent.noDevicesParagraph") }}</p>
          <p class="mb-half">
            <button :id="`btnViewDetails_${htmlIdentifier}`" class="btn-link left-link" @click="goToHowToLink()">
              {{ t("SecurityComponent.noDevicesViewDetailsLink") }}
            </button>
          </p>
          <p class="mb-half">
            <button
              :id="`btnInstallApps_${htmlIdentifier}`"
              class="btn-outline-primary"
              :disabled="isExpired"
              @click="goToDownloads()"
            >
              <span v-if="isNotActive">{{ t("SecurityComponent.installAndActivateBtn") }}</span>
              <span v-else>{{ t("SecurityComponent.installBtn") }}</span>
            </button>
          </p>
        </li>
        <li v-if="displayLastPass">
          <LastPassSecurityTabComponent
            class="mt"
            :last-pass-info="lastPassData"
            :webroot-sub-info="webrootSubscriptionInformation"
            @open-last-pass-modal="openLastPassModal"
          />
        </li>
      </ul>
    </template>
  </TileWrapperComponent>
</template>

<script setup lang="ts">
import { ref, PropType, computed } from "vue";
import { useUserStore } from "@/stores/user";
import { useNotificationsStore } from "@/stores/notifications";
import { unifiedApi } from "@/common";
import TileWrapperComponent from "@/components/shared/TileWrapper.vue";
import { ITileData, ISkyDevice } from "@/components/Home";
import { StatusType } from "@/globalEnums";
import { logEvent, logException } from "@/common/logger";
import { formatDateString } from "@/common/dateFormat";
import { useRouter } from "vue-router";
import { IWebrootSubscriptions, ILastPassInfo, ISubscription } from "@/common/api/unifiedPortal/interfaces";
import DeviceComponent from "@/components/Security/Device.vue";
import LastPassSecurityTabComponent from "@/components/Security/LastPassSecurityTab.vue";
import { criticalShields } from "@/common/securityDevices";
import { productList } from "@/common/productList";
import {
  showSafeRenewLink,
  showSafeBuyLink,
  showCarbWebrootRenewLink,
  showWebrootRenewLink,
  showWebrootBuyLinkForTrial,
  showContactBestBuyLink,
  renewCarbWebrootSubscription,
  isNotActiveYet,
  showConvertToWebRootBuyLink,
  isExpiredAllstateWithNewSubscription,
} from "@/common/subscriptionHelper";
import { t } from "@/i18n";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { webrootLicenseCategories } from "@/common/webrootProductName";
import { getUrl } from "@/common/getUrl";
import { FLAGS, URLS_JA } from "@/define";
import { pageStates as buyPageStates } from "@/components/Buy/BuyEnums";
import { useBuyFlowStore } from "@/stores/buyFlow";

const componentName = "DeviceTileComponent";

const props = defineProps({
  tileData: {
    type: Object as PropType<ITileData>,
    required: true,
  },
  webrootSubInfo: {
    type: Object as PropType<IWebrootSubscriptions | undefined>,
    required: true,
  },
  sub: {
    type: Object as PropType<ISubscription | undefined>,
    required: true,
  },
  lastPassInfo: {
    type: Object as PropType<ILastPassInfo[]>,
    required: true,
  },
});

const emits = defineEmits(["openLastPassModal", "openChromebookDownload"]);

const buyFlowStore = useBuyFlowStore();
const td = ref<ITileData>(props.tileData);
const userStore = useUserStore();
const subscriptionsStore = useSubscriptionsStore();
const user = computed(() => userStore.currentUser);
const webrootSubscriptionInformation = ref<IWebrootSubscriptions | undefined>(props.webrootSubInfo);
const notificationsStore = useNotificationsStore();
const router = useRouter();
const showAllDevices = ref<boolean>(false);
const subscriptionInformation = ref<ISubscription | undefined>(props.sub);
logEvent("Created", componentName);

const daysToExpire = computed(() => {
  return props.sub?.remainingDays ?? 0;
});

const daysNoun = computed(() => {
  return daysToExpire.value > 1 ? t("SecurityComponent.days") : t("SecurityComponent.day");
});

const htmlIdentifier = computed(() => {
  if (td.value.identifier) {
    return `${td.value.identifier}`;
  }

  return td.value.title;
});

const isMonthly = computed(() => subscriptionInformation.value?.isEvergreenMonthly ?? false);

const filteredDevices = computed(() => {
  if (showAllDevices.value) {
    return td.value.devices;
  }

  return td.value.devices?.filter(l => l.isVisible);
});

const isExpired = computed(() => {
  return (
    td.value.status === StatusType.danger &&
    !isNotActiveYet(subscriptionInformation.value) &&
    !props.sub?.hasOutstandingInvoices
  );
});

const isExpiring = computed(() => {
  return td.value.status === StatusType.warning && !isNotActiveYet(subscriptionInformation.value);
});

const isNotActive = computed(() => {
  return isNotActiveYet(subscriptionInformation.value);
});

const displayLastPass = computed(() => {
  const hasLastPass = productList()
    .find(p => p.code == "LPPM")
    ?.licenseCategories.includes(webrootSubscriptionInformation.value?.licenseCategoryName ?? "");
  return hasLastPass && !isExpired.value && !isNotActive.value;
});

// shows or hides the renew link for WR subs purchased outside of Carbonite
const showRenewWebrootLink = computed(() => {
  return isExpiredAllstateWithNewSubscription(props.sub) ? false : showWebrootRenewLink(props.sub);
});

const showBuyWebrootLinkForTrial = computed(() => {
  return showWebrootBuyLinkForTrial(subscriptionInformation.value);
});

// shows or hides the renew link for WR subs purchased via Carbonite
const showSafeWebrootRenewLink = computed(() => {
  return showCarbWebrootRenewLink(subscriptionInformation.value);
});

const showConvertBBYToWebRootBuyLink = computed(() => {
  return showConvertToWebRootBuyLink(subscriptionInformation.value);
});

const showBuySafeLink = computed(() => {
  return showSafeBuyLink(subscriptionInformation.value) && !subscriptionInformation.value?.isSafeLite;
});

const showRenewSafeLink = computed(() => {
  return showSafeRenewLink(subscriptionInformation.value);
});

//We only ever want to show the contact link for BBY
const showBestBuyContactLink = computed(() => {
  return showContactBestBuyLink(subscriptionInformation.value);
});

function expirationDate() {
  return formatDateString(webrootSubscriptionInformation.value?.expirationDate ?? "");
}

function goToDownloads() {
  logEvent("Click event: Go to Downloads");
  router.push("/downloads");
}

function goToHowToLink() {
  logEvent("Click event: Go to webroot support page");
  window.open(getUrl("WEBROOT_VIEW_INSTRUCTIONS"), "_blank");
}

function deviceClass(device: ISkyDevice): Record<string, string> {
  const deviceClass = {};

  if (device.shields.some(l => criticalShields.includes(l.name) && !l.value)) {
    deviceClass["danger"] = true;
  } else if (device.shields.some(l => !l.value)) {
    deviceClass["warning"] = true;
  } else {
    deviceClass["success"] = true;
  }

  return deviceClass;
}

async function renewWebrootSubscription() {
  notificationsStore.clearNotifications();
  try {
    if (subscriptionInformation.value?.hasOutstandingInvoices) {
      router.push({
        name: "subscription_information",
      });
      return;
    }
    if (webrootSubscriptionInformation.value?.keycode) {
      let url;
      if (userStore.selectedLanguage === "ja") {
        url = URLS_JA["WEBROOT_HOME_RENEWAL"];
      } else {
        url = (await unifiedApi.getWebrootRenewUrl(webrootSubscriptionInformation.value.keycode)).data.redirectUrl;
      }
      window.open(url, "_self");
    }
  } catch (err) {
    notificationsStore.addNotification({ type: "ServerErrors" });
    logException(err as Error);
  }
}

async function buyWebrootSubscription() {
  notificationsStore.clearNotifications();
  if (props.sub?.isTrial) {
    const ratePlanId = buyFlowStore.getRatePlanIdForTrialSubscription(props.sub);
    if (ratePlanId) {
      router.push({ name: "buy", query: { rpi: ratePlanId, lk: props.sub.licenseKey, step: buyPageStates.email } });
      return;
    }
  }

  try {
    if (webrootSubscriptionInformation.value?.keycode) {
      let url;
      if (userStore.selectedLanguage === "ja") {
        url = URLS_JA["WEBROOT_HOME"];
      } else {
        url = (await unifiedApi.getWebrootRenewUrl(webrootSubscriptionInformation.value.keycode)).data.redirectUrl;
      }

      window.open(url, "_self");
    }
  } catch (err) {
    notificationsStore.addNotification({ type: "ServerErrors" });
    logException(err as Error);
  }
}

function contactBbyLink() {
  //Refresh because the user may have changed some thing off site.
  subscriptionsStore.forceRefresh();
  window.open(getUrl("BESTBUY_URL"), "_blank");
}

function renewSafeWebroot() {
  renewCarbWebrootSubscription(subscriptionInformation.value);
}

function renewSafe() {
  if (FLAGS.ENABLE_BUYFLOW === "true") {
    router.push({
      name: "buy",
      query: {
        step: buyPageStates.payment,
        deviceId: subscriptionInformation.value?.computer?.id,
        sku: subscriptionInformation.value?.sku,
      },
    });
    return;
  }

  window.location.href = `${getUrl("CARBONITE_BUY_URL")}/paymentForm?deviceId=${
    subscriptionInformation.value?.computer?.id
  }&sku=${subscriptionInformation.value?.sku}&personId=${user.value?.personId}`;
}

function buySafe() {
  if (FLAGS.ENABLE_BUYFLOW === "true") {
    router.push({
      name: "selectPlan",
      query: {
        deviceId: subscriptionInformation.value?.computer?.id,
        sku: subscriptionInformation.value?.sku,
      },
    });
    return;
  }

  window.location.href = `${getUrl("CARBONITE_BUY_URL")}/selectplan?deviceId=${
    subscriptionInformation.value?.computer?.id
  }&personId=${user.value?.personId}&sku=${subscriptionInformation.value?.sku}`;
}

function openLastPassModal(operation: string) {
  emits("openLastPassModal", operation);
}

const lastPassData = computed(() => props.lastPassInfo.find(c => c.keyCode === props.webrootSubInfo?.keycode));

const isChromebookLicense = computed(
  () => webrootSubscriptionInformation.value?.licenseCategoryName === webrootLicenseCategories.WSCB
);

function openWSCBDownload() {
  emits("openChromebookDownload", props.webrootSubInfo?.keycode);
}
</script>
