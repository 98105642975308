export enum pageStates {
  payment = "payment",
  createAccount = "createAccount",
  orderConfirmation = "orderConfirmation",
  email = "email",
  reviewOrder = "reviewOrder",
}

export enum layoutStates {
  purchase = "purchase",
  editShipping = "editShipping",
  editPayment = "editPayment",
  editPayPalShippingAddress = "editPayPalShippingAddress",
}

export enum emailPageStates {
  enterEmail = "enterEmail",
  alreadyAnAccount = "alreadyAnAccount",
  addedEmail = "addedEmail",
  hideEmail = "hideEmail",
}

export enum terms {
  monthToMonth = "monthToMonth",
  year = "year",
  years = "years",
}

export enum productTypes {
  safeConsumer = "safeConsumer",
  webroot = "webroot",
}

export enum adjustments {
  Tax = "Tax",
  UsedCreditBalance = "UsedCreditBalance",
  RemainingCreditBalance = "RemainingCreditBalance",
  PromoCodeAmt = "PromoCodeAmt",
  PromoCodePct = "PromoCodePct",
  PriorSubscriptionCredit = "PriorSubscriptionCredit",
  OneTimeAmt = "OneTimeAmt",
  BonusMonthsPromo = "BonusMonthsPromo",
  Discounts = "Discounts",
  TrialDaysRemaining = "TrialDaysRemaining",
  LicenseDaysRemaining = "LicenseDaysRemaining",
}

export enum promoCodeStates {
  enter = "Enter",
  applied = "Applied",
}

export enum purchaseErrors {
  CCAddress = "CCAddress",
  CCTransaction = "CCTransaction",
  InvalidUpgradePath = "InvalidUpgradePath",
  SubscriptionExpired = "SubscriptionExpired",
  InvalidRemainingDays = "InvalidRemainingDays",
  CartItemRatePlanIdInvalid = "CartItemRatePlanIdInvalid",
  SmbUserIneligible = "SmbUserIneligible",
  AlreadyHaveOneAllstateSku = "AlreadyHaveOneAllstateSku",
  PurchaseFailureError = "PurchaseFailureError",
  NotEligibleForRenewal = "NotEligibleForRenewal",
  UnderMaintenance = "UnderMaintenance",
  OwnedByBestBuy = "OwnedByBestBuy",
  UpgradeOnRetailRenewalNotAllowed = "UpgradeOnRetailRenewalNotAllowed",
}

export enum purchaseErrorsNotifications {
  CCAddress = "CreditCardAddressError",
  CCTransaction = "CreditCardDeclinedError",
  PurchaseFailureError = "ServerErrors",
}

export enum SafeProductNames {
  PersonalBasic = "PersonalBasic",
  PersonalPlus = "PersonalPlus",
  PersonalPrime = "PersonalPrime",
}

export enum paymentTypes {
  CreditCard = "CreditCard",
  PayPal = "PayPal",
}

export enum openTextBrands {
  Carbonite = "Carbonite",
  Webroot = "Webroot",
}

export enum showKeycodeState {
  loading,
  success,
  failed,
}

export enum webrootTrialUnits {
  WSAV = 1,
  WSAI = 3,
  WSAC = 5,
}
