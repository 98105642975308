<template>
  <div v-if="buyFlowStore.isRenewOrUpgrade && (userStore.currentUser || buyFlowStore.userGuid)">
    <p
      v-if="isWebrootSku && showNotableFeatureForWebroot && FLAGS.ENABLE_BUYFLOW_WEBROOT"
      id="descriptionForWebroot"
      class="text-grey text-sm"
    >
      <i18n-t :keypath="keyPathForDescription" tag="div" class="mb-half" scope="global">
        <template #quantity>
          {{ devicesProtected }}
        </template>
        <template v-if="hasIdentity" #identityCount>
          {{ identityCount }}
        </template>
      </i18n-t>
    </p>
    <p v-else id="renewalOrUpgradeComputer" class="mb-half">{{ tn(`backupForComputerName`) }} {{ computerName }}</p>
  </div>
  <p v-else-if="!isWebrootSku" class="text-grey text-sm">
    <i18n-t keypath="NotableFeature.backupForComputer" tag="div" class="mb-half" scope="global">
      <template #quantity>
        {{ devicesProtected }}
      </template>
    </i18n-t>
  </p>
  <p
    v-else-if="isWebrootSku && showNotableFeatureForWebroot && FLAGS.ENABLE_BUYFLOW_WEBROOT"
    id="descriptionForWebroot"
    class="text-grey text-sm"
  >
    <i18n-t :keypath="keyPathForDescription" tag="div" class="mb-half" scope="global">
      <template #quantity>
        {{ devicesProtected }}
      </template>
      <template v-if="hasIdentity" #identityCount>
        {{ identityCount }}
      </template>
    </i18n-t>
  </p>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { FLAGS, WEBROOT_PC_OPTIMIZER } from "@/define";
import { computed, PropType, ref, watch } from "vue";
import { getComputerName } from "@/components/Buy/BuyHelper";
import { ICartItem } from "@/common/api/unifiedPortal/interfaces";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { useUserStore } from "@/stores/user";
import {
  webrootSkusWithNotableFeature,
  webrootMultiDeviceSkus,
  webrootSkusWithIdentity,
} from "@/common/webrootProductName";

const componentName = "NotableFeature";
const userStore = useUserStore();
function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

const props = defineProps({
  cartItem: {
    type: Object as PropType<ICartItem>,
    required: true,
  },
});

const buyFlowStore = useBuyFlowStore();

const computerName = computed(() => {
  return cartItem.value.computerId > 0 ? getComputerName(cartItem.value.computerId) : "";
});

const cartItem = computed(() => {
  return props.cartItem;
});

const isWebrootSku = computed(() => {
  return buyFlowStore.isWebrootSku(cartItem.value.sku);
});

const selectedRatePlanId = computed(() => {
  return cartItem.value.ratePlanId;
});

const product = computed(() => {
  return buyFlowStore.getCatalogItemBySku(cartItem.value.sku);
});

const ratePlan = computed(() => {
  return product.value?.ratePlans?.find(l => l.ratePlanId === selectedRatePlanId.value);
});

const devicesProtected = computed(() => {
  return ratePlan.value?.includedUnits || 1;
});

const hasIdentity = computed(() => webrootSkusWithIdentity.some(l => l == cartItem.value.sku));

const identityCount = computed(() => ratePlan.value?.includedIdentities || 0);

const showNotableFeatureForWebroot = ref<boolean>(webrootSkusWithNotableFeature.some(l => l == cartItem.value.sku));
const keyPathForDescription = ref<string>("");

function getKeyPathForWebrootSkuDescription(quantity: number) {
  const isMultiDeviceWebrootSku = webrootMultiDeviceSkus.some(l => l == cartItem.value.sku);
  const isIdentitySku = webrootSkusWithIdentity.some(l => l == cartItem.value.sku);
  let response = "";
  if (isIdentitySku) {
    if (isMultiDeviceWebrootSku) {
      response = identityCount.value > 1 ? "DevicesWithIdentities" : "DevicesWithIdentity";
    } else {
      response = identityCount.value > 1 ? "Identities" : "Identity";
    }
  } else {
    if (isMultiDeviceWebrootSku) {
      if (cartItem.value.sku === WEBROOT_PC_OPTIMIZER) {
        response = "PcOptimizer";
      } else {
        response = quantity > 1 ? "Devices_other" : "Devices_one";
      }
    } else {
      response = quantity > 1 ? "Computer_other" : "Computer_one";
    }
  }
  keyPathForDescription.value = `NotableFeature.protectionFor${response}`;
}

watch(
  () => props.cartItem,
  () => {
    getKeyPathForWebrootSkuDescription(devicesProtected.value);
  },
  { immediate: true }
);
</script>
