<template>
  <ModalWrapperComponent dialog-name="MoveMySubscriptionComponent" :show-dialog="showDialog" @close-modal="closeModal">
    <template #header>
      {{ tn("headerH1") }}
    </template>
    <template v-if="!showConfirmDelete" #content>
      <ul>
        <li>
          <h3>{{ tn("line1") }}</h3>
        </li>
        <li>
          <p>
            {{ t("MacToWindowsMoveSubscriptionComponent.line2", { nickname, oldComputerOs, newComputerOs }) }}
          </p>
          <button id="btnDownloadFiles" type="button" class="btn-outline-primary" @click="downloadFiles()">
            {{ tn("downloadButton") }}
          </button>
        </li>
        <li>
          <h3>{{ tn("line3") }}</h3>
        </li>
        <li>
          <p>
            {{ tn("line4a") }}&nbsp;
            <a id="linkAccountCarboniteTargetBlank" :href="getUrl('VITE_APP_URL')" target="_blank">
              {{ tn("line4b") }}
            </a>
            &nbsp;{{ tn("line4c") }}
          </p>
        </li>
      </ul>
    </template>
    <template v-else #content>
      <ul>
        <li>
          <i18n-t keypath="MacToWindowsMoveSubscriptionComponent.deleteLine1" tag="p" scope="global">
            <template #nickname>
              <strong>{{ nickname }}</strong>
            </template>
            <template #oldComputerOs>
              {{ oldComputerOs }}
            </template>
            <template #newComputerOs>
              {{ newComputerOs }}
            </template>
          </i18n-t>
        </li>
        <li>
          {{ tn("deleteLine2") }}
        </li>
        <li>
          <input id="confirm-delete" type="checkbox" @click="enableDisableSaveButton()" />
          <label for="confirm-delete">
            <i18n-t keypath="MacToWindowsMoveSubscriptionComponent.deleteLine3" scope="global">
              <template #iUnderstand>
                <strong>{{ tn("deleteIUnderstandStatement") }}</strong>
              </template>
            </i18n-t>
          </label>
        </li>
      </ul>
    </template>

    <template #footer>
      <div v-if="!showConfirmDelete">
        <button id="btnCancelChangesDifferentOs" type="button" class="btn-link mr-half" @click="closeModal()">
          {{ tn("footerButtonCancel") }}
        </button>
        <button
          id="btnSaveChangesDifferentOs"
          type="submit"
          class="btn-primary"
          @click="showSubscriptionConfirmDelete()"
        >
          {{ tn("footerButtonContinue") }}
        </button>
      </div>
      <div v-else>
        <button id="btnCancelChanges" type="button" class="btn-link" @click="closeModal()">
          {{ tn("footerButtonCancel") }}
        </button>
        <button
          id="btnSaveChanges"
          type="submit"
          :disabled="!enableSaveButton"
          class="btn-primary"
          @click="releaseSubscription()"
        >
          {{ tn("footerButtonDelete") }}
        </button>
      </div>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType, ref, computed } from "vue";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import router from "@/routes";
import { FLAGS } from "@/define";
import { AxiosError } from "axios";
import { useNotificationsStore, NotificationType } from "@/stores/notifications";
import { unifiedApi } from "@/common";
import { getOsFamily } from "@/common/browserDetect";
import { IDevice } from "@/common/api/unifiedPortal/interfaces";
import { IExceptionErrorMessage } from "@/common/api/interfaces";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { getFileAccessRoute } from "@/components/FileAccess/navigationFn";
import { getUrl } from "@/common/getUrl";

const componentName = "MacToWindowsMoveSubscriptionComponent";

const props = defineProps({
  device: {
    type: Object as PropType<IDevice>,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["cancel"]);

logEvent("created", componentName, props.device);

const notificationsStore = useNotificationsStore();
const showConfirmDelete = ref<boolean>(false);
const enableSaveButton = ref<boolean>(false);
const nickname = ref<string>(props.device.description);
const oldComputerOs = ref<string>(props.device.platform.toLowerCase());
const newComputerOs = computed(() => {
  return getOsFamily().toLowerCase();
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function showSubscriptionConfirmDelete() {
  logEvent("Continue", componentName, props.device);
  showConfirmDelete.value = true;
}

async function releaseSubscription() {
  logEvent("Release subscription", componentName, props.device);
  //release the older subscription
  let response = "";
  let axiosError: AxiosError | null = null;
  try {
    response = (await unifiedApi.releaseSubscription(props.device?.deviceId)).data;
  } catch (err) {
    logException(err as Error);
    axiosError = err as AxiosError;
  }

  notificationsStore.clearNotifications();
  if (response === "Success") {
    //if release subscription is successful , we reload the page and save the success message in the local storage to show the success message after reload
    //localStorage.setItem("success-message", `MoveMySubscription${response}`);
    location.reload();
  } else {
    var errorMessage = (axiosError?.response?.data as IExceptionErrorMessage)?.ErrorMessage;
    //show error message
    const errType = (
      errorMessage ? `MoveMySubscription${errorMessage}` : `MoveMySubscriptionError`
    ) as NotificationType;
    logEvent(errType, componentName, response);
    notificationsStore.addNotification({ type: errType });
    close();
  }
}

function downloadFiles() {
  if (FLAGS.SHOW_RFA_COMPONENT === "true") {
    logEvent("new viewFiles", componentName, props.device);
    router.push(getFileAccessRoute(props.device.deviceId));
  } else {
    logEvent("old viewFiles", componentName, props.device);
    window.location.href = `${getUrl("REMOTE_FILE_ACCESS_URL")}/${props.device.deviceId}`;
  }
}

function enableDisableSaveButton() {
  logEvent("Toggle enable save", componentName, !enableSaveButton.value);
  enableSaveButton.value = !enableSaveButton.value;
}

function closeModal() {
  logEvent("Close move sub", componentName);
  emits("cancel");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
