<template>
  <ModalWrapperComponent dialog-name="DownloadRestrictedFilesComponent" :show-dialog="showDialog" @close-modal="close">
    <template #header>
      <div v-if="is2faEnabled || props.showInitialState">
        {{ tn("header1") }}
      </div>
      <div v-else>
        {{ tn("header2") }}
      </div>
    </template>
    <template #content>
      <p class="mb">
        {{ tn("restrictedFilesLineOne") }}
        <br />
        <span v-if="is2faEnabled || props.showInitialState">
          {{ tn("restrictedFilesLineTwo") }}
        </span>
        <span v-else>
          <br />
          {{ tn("downloadFromClientOrCourier") }}
        </span>
      </p>
      <p v-if="is2faEnabled && !props.showInitialState" class="mb">
        {{ codeSentToDeviceText }}
        <br />
        {{ tn("restrictedFilesLineFour") }}
      </p>
      <div v-if="is2faEnabled && !props.showInitialState" class="form">
        <label for="activationCode">{{ tn("securityCode") }}</label>
        <input id="activationCode" v-model="activationCode" type="text" />
      </div>
      <div v-if="is2faEnabled && !props.showInitialState" class="mb">
        <input id="trust-device-checkbox" v-model="trustDevice" type="checkbox" value="selected" />
        <label for="trust-device-checkbox">{{ tn("trustDevice") }}</label>
        <div class="icon-sm has-tooltip arrow-left" onclick="">
          <button type="button" class="btn-link">
            <svg-icon-component icon="circle-information" class="icon-sm" />
          </button>
          <div class="tooltip-area">
            {{ tn("infoTooltip") }}
          </div>
        </div>
      </div>
      <p v-if="is2faEnabled && !props.showInitialState">
        {{ tn("restrictedFilesLineFive") }}
        <template v-if="!isResendDisabled">
          <button type="button" class="inline-btn-link" @click="sendVerificationCodeAgain">
            {{ tn("sendAgain") }}
          </button>
          |
          <button type="button" class="inline-btn-link" @click="callUserForVerificationCode">
            {{ tn("callMe") }}
          </button>
        </template>
        <span v-else>
          {{ resendTimerText }}
        </span>
      </p>
      <p v-if="is2faEnabled && !props.showInitialState">
        {{ tn("restrictedFilesLineSix") }}
        <button type="button" class="inline-btn-link" @click="sendVerificationCodeOnEmail">
          {{ tn("emailCode", { email: "araj@opentext.com" }) }}
        </button>
      </p>
    </template>
    <template #footer>
      <div class="text-right">
        <button v-if="is2faEnabled" id="btnCancelChanges" type="button" class="btn-link mr" @click="close()">
          Cancel
        </button>
        <button
          v-if="props.showInitialState"
          id="btnDownloadWithoutRestrictedFiles"
          type="button"
          class="btn-outline-primary mr"
          @click="emits('exclude-restricted-files')"
        >
          {{ tn("downloadWithoutRestrictedFiles") }}
        </button>
        <button
          v-if="is2faEnabled && !props.showInitialState"
          id="submitCode"
          type="submit"
          class="btn-primary"
          :disabled="activationCode.length <= 0"
          @click="submitCode()"
        >
          {{ tn("submitCode") }}
        </button>
        <button
          v-if="props.showInitialState"
          id="btnProceed"
          type="button"
          class="btn-primary"
          @click="emits('download-all-files')"
        >
          {{ tn("proceed") }}
        </button>
        <button v-if="!is2faEnabled" if="closeBtn" class="btn-primary" @click="close()">Close</button>
      </div>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { unifiedApi } from "@/common";
import {
  IUserSecurityPhone,
  IUserSecurityPhoneValidationAndTrustClientResponse,
} from "@/common/api/unifiedPortal/interfaces";
import { handleApiError } from "@/common/handleApiError";
import { logEvent } from "@/common/logger";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { t } from "@/i18n";
import { NotificationType, useNotificationsStore } from "@/stores/notifications";
import { AxiosError } from "axios";
import { computed, onBeforeMount, PropType, ref } from "vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const componentName = "DownloadRestrictedFilesComponent";
const props = defineProps({
  showDialog: {
    type: Boolean,
    default: true,
  },
  securityPhone: {
    type: Object as PropType<IUserSecurityPhone>,
    default: null,
  },
  showInitialState: {
    type: Boolean,
    default: true,
  },
});
const trustDevice = ref<boolean>(false);
const activationCode = ref<string>("");
const emits = defineEmits(["close-modal", "submit-code-success", "download-all-files", "exclude-restricted-files"]);
const notificationsStore = useNotificationsStore();
const is2faEnabled = ref<boolean>(true);
const resendDelay = ref<number>(30);
const isResendDisabled = ref<boolean>(false);
const resendCounter = ref<number>(0);
const resendAttempt = ref<number>(0);
const resendTimerText = ref<string>("");

function startResendCounter() {
  resendAttempt.value++;
  if (resendAttempt.value == 2) {
    resendDelay.value = 60;
  }
  resendCounter.value = resendDelay.value;
  resendTimerText.value = tn("resendTimerText", { seconds: resendCounter.value });
  const timer = setInterval(() => {
    if (resendCounter.value > 0) {
      resendCounter.value--;
      resendTimerText.value = tn("resendTimerText", { seconds: resendCounter.value });
    } else {
      clearInterval(timer);
      isResendDisabled.value = false;
    }
  }, 1000);
}

const maskedPhoneNumber = computed(() => {
  if (props.securityPhone?.phoneNumber) {
    const len = props.securityPhone.phoneNumber.length;
    const visiblePart = props.securityPhone.phoneNumber.slice(-3);
    const maskedPart = "*".repeat(len - 3);
    return maskedPart + visiblePart;
  }
  return "";
});
const codeSentToDeviceText = ref<string>(tn("restrictedFilesLineThree", { device: maskedPhoneNumber.value }));

onBeforeMount(async () => {
  logEvent("created", "DownloadRestrictedFilesComponent");
  notificationsStore.clearNotifications();
  try {
    if (!props.showInitialState) {
      if (props.securityPhone?.phoneNumber) {
        is2faEnabled.value = true;
      } else {
        is2faEnabled.value = false;
      }
    }
  } catch (error) {
    handleApiError(error as AxiosError, true);
  }
});

async function submitCode() {
  notificationsStore.clearNotifications();
  const securityPhoneValidation = (
    await unifiedApi.sendVerificationCodeAndTrustClient(activationCode.value, trustDevice.value, "VD")
  ).data;
  if (securityPhoneValidation.validateCodeResponse.isValid) {
    if (trustDevice.value) {
      localStorage.setItem(
        "DownloadRestrictedFilesToken" + props.securityPhone.personId,
        securityPhoneValidation.deviceTrustToken
      );
    } else {
      sessionStorage.setItem(
        "DownloadRestrictedFilesToken" + props.securityPhone.personId,
        securityPhoneValidation.deviceTrustToken
      );
    }
    emits("submit-code-success", securityPhoneValidation.deviceTrustToken);
  } else {
    showCodeVerificationError(securityPhoneValidation);
  }
}

function showCodeVerificationError(securityPhoneValidation: IUserSecurityPhoneValidationAndTrustClientResponse) {
  let notificationMessage: NotificationType = "TwoStepVerificationFailed";
  switch (securityPhoneValidation.validateCodeResponse.securityPhoneOperationCode) {
    case "VerificationWindowExpired":
      notificationMessage = "SetTwoStepVerificationVerificationWindowExpired";
      break;
    case "TooManyVerificationAttempts":
      notificationMessage = "SetTwoStepVerificationTooManyVerificationAttempts";
      break;
    case "HighRiskScore":
      notificationMessage = "SetTwoStepVerificationHighRiskScore";
      break;
  }
  notificationsStore.addNotification({ type: notificationMessage });
}

async function sendVerificationCodeAgain() {
  notificationsStore.clearNotifications();
  const response = (
    await unifiedApi.requestVerificationCode(props.securityPhone.personId, props.securityPhone.verificationMethod)
  ).data;
  if (response.securityPhoneOperationCode == "Success") {
    notificationsStore.addNotification({ type: "TwoStepVerificationCodeSent" });
    startResendCounter();
    isResendDisabled.value = true;
  }
}

async function sendVerificationCodeOnEmail() {
  notificationsStore.clearNotifications();
  const response = (await unifiedApi.requestVerificationCode(props.securityPhone.personId, "Email")).data;
  if (response.securityPhoneOperationCode == "Success") {
    notificationsStore.addNotification({ type: "SecurityCodeSentToEmail" });
  }
}

async function callUserForVerificationCode() {
  (await unifiedApi.requestVerificationCode(props.securityPhone.personId, "Call")).data;
}

function close() {
  emits("close-modal");
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}
</script>
