<template>
  <ul class="form">
    <li>
      <label for="buyEmail">{{ t("Common.email") }}</label>
      <InputError :show="emailError !== null">
        <input
          id="buyEmail"
          v-model.trim="email"
          type="text"
          autocomplete="off"
          @keyup="
            dirtyFlagEmail = true;
            emailPageStore.isSmbEmail = false;
          "
          @focusout="verifyEmail()"
          @focusin="startEmailTimer()"
        />
        <template #error>
          <div>
            <span :key="emailError?.name">{{ emailError?.message || t(emailError?.name ?? "") }}</span>
          </div>
        </template>
      </InputError>
    </li>
    <li v-if="showV2Recaptcha">
      <div class="captcha mb">
        <vue-recaptcha
          :key="captchaV2ComponentKey"
          :sitekey="captchaV2SiteKey"
          :load-recaptcha-script="true"
          :language="recaptchaLang"
          @verify="captchaV2Callback"
          @expired="onCaptchaV2Expired"
        ></vue-recaptcha>
      </div>
    </li>
    <li>
      <button
        id="btn-continue-to-payment"
        type="button"
        class="btn-primary"
        :disabled="emailError !== null || !email || isVerifying"
        @click="emitResults()"
      >
        <spinner :is-spinning="isVerifying" />
        {{ t("BuyEmailComponent.btnContinue") }}
      </button>
    </li>
  </ul>
</template>

<script setup lang="ts">
//Components
import InputError from "@/components/shared/InputError.vue";
import Spinner from "@/components/shared/Spinner.vue";
import { VueRecaptcha } from "vue-recaptcha";
//Others
import {
  HTTP_STATUS_BAD_REQUEST,
  HTTP_STATUS_NOT_ACCEPTABLE,
  HTTP_STATUS_NOT_FOUND,
  MAX_EMAIL_LENGTH,
  MSEC_IN_MINUTE,
  MIN_EMAIL_LENGTH,
} from "@/define";
import { unifiedApi } from "@/common";
import { emailCharactersRegex } from "@/globalRegex";
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from "vue";
import { isValidEmail } from "@/common/validateEmail";
import { IValidationResult, reportStringErrors } from "@/common/validator";
import { t } from "@/i18n";
import { logEvent, logException } from "@/common/logger";
import { useNotificationsStore } from "@/stores/notifications";
import { useUserStore } from "@/stores/user";
import { useConfigStore } from "@/stores/config";
import { AxiosError } from "axios";
import { ReCaptchaInstance } from "recaptcha-v3";
import { getReCaptcha, executeReCaptcha, updateReCaptchaLanguage } from "@/common/ReCaptchaV3";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { clearShoppingCartFromDb } from "@/components/Buy/BuyHelper";
import { useEmailPageStore } from "@/stores/emailPage";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import { getBrandFromCart } from "@/components/Buy/BuyHelper";
import { purchaseErrors } from "@/components/Buy/BuyEnums";
import { useRouter } from "vue-router";

const componentName = "BuyEnterEmailComponent";

const emits = defineEmits(["next", "consumer"]);

enum emailValidationErrors {
  EmailInUseNoLoginLink = "ValidationError_EmailInUseNoLoginLink",
  EmailInUse = "ValidationError_EmailInUse",
}

logEvent("created", componentName);
const userStore = useUserStore();
const configStore = useConfigStore();
const notificationsStore = useNotificationsStore();
const email = ref<string>("");
const dirtyFlagEmail = ref<boolean>(false);
const recaptchaInstance = ref<ReCaptchaInstance>();
const buyFlowStore = useBuyFlowStore();
const showV2Recaptcha = ref<boolean>(false);
const captchaV2ComponentKey = ref<number>(0);
const captchaV2SiteKey = ref<string>(configStore.config?.captcha?.siteKey ?? "");
const recaptchaLang = ref<string>(localStorage.getItem("selectedLanguage") || "en");
const captchaV2ResponseToken = ref<string>("");
const emailPageStore = useEmailPageStore();
const isVerifying = ref<boolean>(false);
const router = useRouter();
let emailSaveTimer;

const props = defineProps({
  hideEmail: {
    type: Boolean,
    default: false,
    required: false,
  },
});

onBeforeMount(async () => {
  isVerifying.value = false;
  email.value = props.hideEmail ? "" : (userStore.email ?? "");
  recaptchaInstance.value = await getReCaptcha(userStore.selectedLanguage);
});

onBeforeUnmount(() => {
  //Hide the badge after the component is destroyed so it doesn't show on every page
  recaptchaInstance.value?.hideBadge();
});

watch(
  () => userStore.$state.selectedLanguage,
  async languageChanged => {
    updateReCaptchaLanguage(languageChanged);
  }
);

const emailInvalidCharacterError = computed(() => {
  return reportStringErrors(email.value, t("Common.email"), MIN_EMAIL_LENGTH, MAX_EMAIL_LENGTH, emailCharactersRegex);
});

const emailError = computed(() => {
  if (dirtyFlagEmail.value === false) return null;
  const errs = isValidEmail(email.value).filter(e => !e.passed);
  if (errs.length > 0) return errs[0];
  if (emailInvalidCharacterError.value.length > 0) {
    return { name: "invalidCharacter", message: emailInvalidCharacterError.value } as IValidationResult;
  }
  if (emailPageStore.isSmbEmail) {
    return { name: "invalidCharacter", message: t("BuyEmailComponent.differentEmail") } as IValidationResult;
  }
  return null;
});

function captchaV2Callback(responseToken: string) {
  logEvent(`Captcha token: ${responseToken}`, `${componentName}/captchaV2Callback`);
  captchaV2ResponseToken.value = responseToken;
  //verifyEmail should have been called already so we need to emit the result
  emitResults();
}

function onCaptchaV2Expired() {
  logEvent("Captcha expired", `${componentName}/onCaptchaV2Expired`);
  captchaV2ResponseToken.value = "";

  // incrementing the key forces the captcha component to reload
  captchaV2ComponentKey.value += 1;
}

async function verifyEmail() {
  //Don't run for a blank email or email with errors
  if (emailError.value || !email.value) {
    return;
  }

  emailPageStore.isCreatingAccount = true;
  emailPageStore.reset();

  notificationsStore.clearNotifications();
  try {
    let v2Token = "";
    let v3Token = "";

    //if we're showing the v2 recaptcha, then send its token (second attempt)
    //otherwise, send the v3 token (first attempt)
    if (showV2Recaptcha.value) {
      v2Token = captchaV2ResponseToken.value;
    } else if (recaptchaInstance.value) {
      v3Token = await executeReCaptcha(recaptchaInstance.value);
    }
    //This will create an Account if the email does not exist
    //It will return 400 if the email is for an SMB Account
    userStore.email = email.value;
    emailPageStore.createAccountResponse = (
      await unifiedApi.checkExistingEmail({
        email: email.value,
        preferredLanguage: userStore.selectedLanguage,
        recaptchaV2Response: v2Token,
        recaptchaV3Response: v3Token,
        brand: getBrandFromCart(),
      })
    ).data;

    if (!emailPageStore.createAccountResponse?.isExistingUser) {
      //Called just to get anti-forgery token for preliminary users
      await unifiedApi.getUser();
    }

    //For existing users we need to set the order code to the cart for email validation
    if (
      emailPageStore.createAccountResponse &&
      emailPageStore.createAccountResponse.isExistingUser &&
      buyFlowStore.cart
    ) {
      buyFlowStore.cart.userEmail = email.value;
      buyFlowStore.cart.orderCode = emailPageStore.createAccountResponse.orderCode;
    }

    if (buyFlowStore.currentEmail && buyFlowStore.currentEmail != email.value) {
      // send empty cart to preview
      await clearShoppingCartFromDb();
    }

    // update current email
    buyFlowStore.currentEmail = email.value;
    emailPageStore.isCreatingAccount = false;
    //Call to put the cart in the DB
    buyFlowStore.updateShoppingCartTotal();
  } catch (error) {
    logException(error as Error, `${componentName}: verifyThenEmit`);

    const errorResponse = error as AxiosError;
    emailPageStore.errorResponseStatus = errorResponse.response?.status;
    emailPageStore.errorResponseMessage = (errorResponse.response?.data as Error).message;
    emailPageStore.isCreatingAccount = false;
  }
  emailPageStore.isCreatingAccount = false;
}

async function emitResults() {
  isVerifying.value = true;

  //Clear timer
  if (emailSaveTimer) {
    clearInterval(emailSaveTimer);
  }

  //Clicking on the button directly after clicking on edit or
  //Clicking on the button directly from the input doesn't trigger the focusOut event
  //If verifyEmail hasn't been called do so now
  if (
    !emailPageStore.isCreatingAccount &&
    !emailPageStore.createAccountResponse &&
    !emailPageStore.errorResponseStatus
  ) {
    await verifyEmail();
  }

  //If the create Account call has not finished we need to wait for it to complete
  //This is for the case where a user clicks off the email input and clicks the btn but the APi call may not be done
  while (emailPageStore.isCreatingAccount) {
    await new Promise(resolve => setTimeout(resolve, 2000));
  }

  //Check if there was an error first
  if (emailPageStore.errorResponseStatus) {
    if (emailPageStore.errorResponseMessage === purchaseErrors.UnderMaintenance) {
      email.value = "";
      buyFlowStore.currentEmail = "";
      userStore.email = "";
      emailPageStore.reset();
      await router.push("/underMaintenance");
      return;
    }

    switch (emailPageStore.errorResponseStatus) {
      case HTTP_STATUS_BAD_REQUEST: //when there's an SMB email
      case HTTP_STATUS_NOT_FOUND: //not found error
        if (emailPageStore.errorResponseMessage == emailValidationErrors.EmailInUseNoLoginLink) {
          emailPageStore.isSmbEmail = true;
          notificationsStore.addNotification({ type: "LoginAccountExistsNoLoginLink" });
        } else {
          //Moved from email.vue so we can keep the spinner on the btn to prevent the page from looking like it is not doing anything
          if (emailPageStore.errorResponseMessage !== emailValidationErrors.EmailInUse) {
            await reloadSubscriberInfo();
          }
          emits("consumer", emailPageStore.errorResponseMessage === emailValidationErrors.EmailInUse);
        }
        break;
      case HTTP_STATUS_NOT_ACCEPTABLE: //recaptcha failed
        if (!showV2Recaptcha.value) {
          //if we failed v3, then show v2
          showV2Recaptcha.value = true;
        } else {
          //if we failed v2, then notify the user -- this is the end of the line
          notificationsStore.addNotification({ type: "RecaptchaValidation_invalid" });
          showV2Recaptcha.value = false;
        }
        break;
    }
  } else {
    //Create Account was successful, check if it is an existing user
    if (emailPageStore.createAccountResponse && emailPageStore.createAccountResponse.isExistingUser) {
      emits("consumer", true);
    } else {
      //Moved from email.vue so we can keep the spinner on the btn to prevent the page from looking like it is not doing anything
      await reloadSubscriberInfo();
      emits("next");
    }
  }

  isVerifying.value = false;
}

function startEmailTimer() {
  if (!emailSaveTimer) {
    emailSaveTimer = setInterval(async () => {
      await verifyEmail();
    }, MSEC_IN_MINUTE);
  }
}

defineExpose({ verifyEmail, emitResults });
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
