import { defineStore } from "pinia";
import {
  INotificationsState,
  INotificationOptions,
  INotificationMetadata,
  NotificationType,
  INotificationParams,
} from "@/stores/notificationsInterfaces";
import { NotificationIcon } from "@/globalEnums";
import { logEvent } from "@/common/logger";

import AccountLockedOutNotification from "@/components/notifications/AccountLockedOutNotification.vue";
import LoginFailedNotification from "@/components/notifications/LoginFailedNotification.vue";
import AddFraudKeyNotification from "@/components/notifications/AddFraudKeyNotification.vue";
import PendingEmailVerificationNotification from "@/components/notifications/PendingEmailVerificationNotification.vue";
import PrimaryEmailVerificationWebrootNotification from "@/components/notifications/PrimaryEmailVerificationWebrootNotification.vue";
import KeycodeExpiredNotification from "@/components/notifications/KeycodeExpiredNotification.vue";
import AddKeycodeNotification from "@/components/notifications/AddKeycodeNotification.vue";
import LastPassProvisionSuccessNotification from "@/components/notifications/LastPassProvisionSuccessNotification.vue";
import RfaEncryptedNotification from "@/components/notifications/RfaEncryptedNotification.vue";
import RfaExpiredNotification from "@/components/notifications/RfaExpiredNotification.vue";
import OptInToAutomaticVideoBackupSuccessNotification from "@/components/notifications/OptInToAutomaticVideoBackupSuccessNotification.vue";
import AutoRenewTurnOnSuccessNotification from "@/components/notifications/AutoRenewTurnOnSuccessNotification.vue";
import AutoRenewTurnOffSuccessNotification from "@/components/notifications/AutoRenewTurnOffSuccessNotification.vue";
import AMZErrorNotification from "@/components/notifications/AMZSErrorNotification.vue";
import EmailAlreadyInUse from "@/components/notifications/EmailAlreadyInUseNotification.vue";
import MovePaymentMethodMRToARNotification from "@/components/notifications/MovePaymentMethodMRToARNotification.vue";
import AllstateExpiredNotification from "@/components/notifications/AllstateExpiredNotification.vue";
import DoubleAllstatePremiumErrorNotification from "@/components/notifications/DoubleAllstatePremiumErrorNotification.vue";
import NonMigratedLegacyWebrootNotification from "@/components/notifications/NonMigratedLegacyWebrootNotification.vue";
import NonZuoraEcommWebrootNotification from "@/components/notifications/NonZuoraEcommWebrootNotification.vue";
import AllstateUpgradeNotification from "@/components/notifications/AllstateUpgradeNotification.vue";
import AllstateNonUsNotification from "@/components/notifications/AllstateNonUsNotification.vue";
import LicenseDaysRemainingNotification from "@/components/notifications/LicenseDaysRemainingNotification.vue";
import { computed, markRaw } from "vue";
import { useUserStore } from "@/stores/user";
import AutoRenewDiscountEnabledNotification from "@/components/notifications/AutoRenewDiscountEnabledNotification.vue";

export * from "@/stores/notificationsInterfaces";

// When adding a new message here, please added it on the notificationPriority constant as well, if the context exists.
export const NotificationLookup: { [key in NotificationType]: INotificationMetadata } = {
  AddCreditCardFailed: {
    contexts: ["AddCreditCardComponent", "SubscriptionInformationComponent", "BackupComponent", "BuyflowComponent"],
    icon: NotificationIcon.DANGER,
  },
  AddCreditCardSuccess: {
    contexts: ["CreditCardsComponent", "SubscriptionInformationComponent", "BackupComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  MovePaymentMethodMRToAR: {
    contexts: ["CreditCardsComponent", "SubscriptionInformationComponent", "BackupComponent"],
    icon: NotificationIcon.SUCCESS,
    component: markRaw(MovePaymentMethodMRToARNotification),
  },
  RemoveCreditCardFailed: {
    contexts: ["CreditCardsComponent"],
    icon: NotificationIcon.DANGER,
  },
  LicenseDaysRemaining: {
    contexts: ["BuyflowComponent"],
    icon: NotificationIcon.INFO,
    component: markRaw(LicenseDaysRemainingNotification),
  },
  LoginFailed: {
    contexts: ["LoginTabLoginComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(LoginFailedNotification),
  },
  LoginAccountExists: {
    contexts: ["LoginTabLoginComponent", "CreateAccountTabLoginComponent", "CreateAccountNtpTabLoginComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(LoginFailedNotification),
  },
  PendingEmailVerification: {
    contexts: ["PersonalInformationComponent"],
    icon: NotificationIcon.WARNING,
    component: markRaw(PendingEmailVerificationNotification),
  },
  PrimaryEmailVerificationWebroot: {
    contexts: [
      "PersonalInformationComponent",
      "SubscriptionInformationComponent",
      "BackupComponent",
      "HomeComponent",
      "SecurityComponent",
      "DownloadsComponent",
      "PaymentHistoryComponent",
      "SupportComponent",
      "AllstateSignUpComponent",
    ],
    icon: NotificationIcon.WARNING,
    component: markRaw(PrimaryEmailVerificationWebrootNotification),
  },
  UpdateEmailLanguagePreferenceSuccess: {
    contexts: ["PersonalInformationComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  EmailVerificationComplete: {
    contexts: ["PersonalInformationComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  LoginAccountExistsNoLoginLink: {
    contexts: ["CreateAccountTabLoginComponent", "BuyflowComponent"],
    icon: NotificationIcon.DANGER,
  },
  AllstateBuyflowLimit: {
    contexts: ["BuyflowComponent"],
    icon: NotificationIcon.DANGER,
  },
  SetSecurityQuestionsSuccess: {
    contexts: ["PersonalInformationComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  SetPasswordSuccess: {
    contexts: ["PersonalInformationComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  SetSecurityQuestionsFailure: {
    contexts: ["PersonalInformationComponent"],
    icon: NotificationIcon.DANGER,
  },
  SetPasswordFailure: {
    contexts: ["PersonalInformationComponent"],
    icon: NotificationIcon.DANGER,
  },
  SetUserFieldsSuccess: {
    contexts: ["PersonalInformationComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  SetUserFieldsFailure: {
    contexts: ["PersonalInformationComponent"],
    icon: NotificationIcon.DANGER,
  },
  //TwoStepVerification Notifications:
  SetTwoStepVerificationFailure: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent"],
    icon: NotificationIcon.DANGER,
  },
  SetTwoStepVerificationSuccess: {
    contexts: ["PersonalInformationComponent", "DownloadsComponent", "BackupComponent", "HomeComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  SetTwoStepVerificationTooManyVerificationAttempts: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent", "DownloadRestrictedFilesComponent"],
    icon: NotificationIcon.DANGER,
  },
  SetTwoStepVerificationLockOut: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent"],
    icon: NotificationIcon.DANGER,
  },
  SetTwoStepVerificationNoPhoneToValidate: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent"],
    icon: NotificationIcon.DANGER,
  },
  SetTwoStepVerificationPhoneAlreadyExists: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent"],
    icon: NotificationIcon.DANGER,
  },
  SetTwoStepVerificationVerificationWindowExpired: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent", "DownloadRestrictedFilesComponent"],
    icon: NotificationIcon.DANGER,
  },
  SetTwoStepVerificationInvalidPhoneType: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent"],
    icon: NotificationIcon.DANGER,
  },
  SetTwoStepVerificationHighRiskScore: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent"],
    icon: NotificationIcon.DANGER,
  },
  SetTwoStepVerificationEmptyPhone: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent"],
    icon: NotificationIcon.DANGER,
  },
  SetTwoStepVerificationInvalidCountry: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent"],
    icon: NotificationIcon.DANGER,
  },
  SetTwoStepVerificationDeletePhone: {
    contexts: ["PersonalInformationComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  SetTwoStepVerificationUnverifiedPhone: {
    contexts: ["PersonalInformationComponent"],
    icon: NotificationIcon.WARNING,
  },
  SetTwoStepVerificationInvalidVerificationMethod: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent"],
    icon: NotificationIcon.DANGER,
  },
  TwoStepVerificationFailed: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent", "DownloadRestrictedFilesComponent"],
    icon: NotificationIcon.DANGER,
  },
  TwoStepVerificationExpired: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent"],
    icon: NotificationIcon.DANGER,
  },
  TwoStepVerificationEmail: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  TwoStepVerificationCodeSent: {
    contexts: ["TwoStepVerificationComponent", "SetTwoStepVerificationComponent", "DownloadRestrictedFilesComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  SecurityCodeSentToEmail: {
    contexts: ["DownloadRestrictedFilesComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  PasswordReset: {
    contexts: ["LoginTabLoginComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  PasswordResetNoLogin: {
    contexts: ["BackupComponent", "DownloadsComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  CannotCreateAccount: {
    contexts: ["CreateAccountTabLoginComponent"],
    icon: NotificationIcon.DANGER,
  },
  AccountLockedOut: {
    contexts: ["LoginTabLoginComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(AccountLockedOutNotification),
  },
  ValidationError_FraudKeycode: {
    contexts: ["AddKeycodeComponent", "CreateAccountTabLoginComponent", "DownloadsComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(AddFraudKeyNotification),
  },
  ValidationError_LicenseStatusIneligible: {
    contexts: ["AddKeycodeComponent", "CreateAccountTabLoginComponent", "DownloadsComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(AddFraudKeyNotification),
  },
  ValidationError_KeycodeEmailMismatch: {
    contexts: ["AddKeycodeComponent", "CreateAccountTabLoginComponent", "DownloadsComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(AddKeycodeNotification),
  },
  ValidationError_KeycodeEmailMismatchDownloadsComponent: {
    contexts: ["DownloadsComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(AddKeycodeNotification),
  },
  ValidationError_KeycodeIneligible: {
    contexts: ["AddKeycodeComponent", "CreateAccountTabLoginComponent", "DownloadsComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(AddKeycodeNotification),
  },
  ValidationError_InvalidKeycode: {
    contexts: ["AddKeycodeComponent", "CreateAccountTabLoginComponent", "DownloadsComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(AddKeycodeNotification),
  },
  ValidationError_LicenseInfoAlreadyLinked: {
    contexts: ["CreateAccountTabLoginComponent", "DownloadsComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(AddKeycodeNotification),
  },
  ValidationError_AMZSLicense: {
    contexts: ["CreateAccountTabLoginComponent", "DownloadsComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(AMZErrorNotification),
  },
  RecaptchaValidation_invalid: {
    contexts: ["CreateAccountTabLoginComponent", "CreateAccountNtpTabLoginComponent"],
    icon: NotificationIcon.DANGER,
  },
  EmailAlreadyInUse: {
    contexts: ["UpdateEmailAddressComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(EmailAlreadyInUse),
  },
  ServerErrors: {
    contexts: [
      "AccountSetup",
      "AddKeycodeComponent",
      "ApplyActivationCodeComponent",
      "AutoRenewOptionComponent",
      "BackupComponent",
      "CancelSubscriptionComponent",
      "ComputerNameComponent",
      "CreateAccountTabLoginComponent",
      "CreditCardsComponent",
      "FileAccessComponent",
      "SecurityComponent",
      "SetUserFieldsComponent",
      "SubscriptionInformationComponent",
      "CreateAccountNtpTabLoginComponent",
      "BuyflowComponent",
    ],
    icon: NotificationIcon.DANGER,
  },
  AddKeycodeSuccess: {
    contexts: ["DownloadsComponent"],
    icon: NotificationIcon.SUCCESS,
    component: markRaw(AddKeycodeNotification),
  },
  KeycodeAlreadyExists: {
    contexts: ["DownloadsComponent"],
    icon: NotificationIcon.WARNING,
    component: markRaw(AddKeycodeNotification),
  },
  EmailVerificationIncomplete: {
    contexts: ["VerifyPrimaryEmailAddressCtnComponent"],
    icon: NotificationIcon.DANGER,
  },
  KeycodeExpired: {
    contexts: ["DownloadsComponent"],
    icon: NotificationIcon.WARNING,
    component: markRaw(KeycodeExpiredNotification),
  },
  RetrieveKeycodesFailed: {
    contexts: ["DownloadsComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(AddFraudKeyNotification),
  },
  PartialRetrieveKeycodesFailed: {
    contexts: ["DownloadsComponent", "BackupComponent"],
    icon: NotificationIcon.DANGER,
  },
  UnsupportedOS: {
    contexts: ["DownloadsSafeComponent"],
    icon: NotificationIcon.DANGER,
  },
  DownloadSafeFailed: {
    contexts: ["BackupComponent"],
    icon: NotificationIcon.DANGER,
  },
  CardComputerBackupOutDated: {
    contexts: ["BackupComponent"],
    icon: NotificationIcon.WARNING,
  },
  CardComputerBackupOutDatedSevere: {
    contexts: ["BackupComponent"],
    icon: NotificationIcon.DANGER,
  },
  CardComputerSubscriptionWillExpire: {
    contexts: ["BackupComponent"],
    icon: NotificationIcon.WARNING,
  },
  CardComputerSubscriptionExpired: {
    contexts: ["BackupComponent"],
    icon: NotificationIcon.DANGER,
  },
  MoveMySubscriptionError: {
    contexts: ["BackupComponent"],
    icon: NotificationIcon.DANGER,
  },
  MoveMySubscriptionSuccess: {
    contexts: ["BackupComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  MoveMySubscriptionComputerOrUser_Invalid: {
    contexts: ["BackupComponent"],
    icon: NotificationIcon.DANGER,
  },
  SubscriptionWillExpireTrial: {
    contexts: ["BackupComponent", "SubscriptionInformationComponent"],
    icon: NotificationIcon.WARNING,
  },
  SubscriptionsAlmostExpired: {
    contexts: ["SubscriptionInformationComponent", "SecurityComponent"],
    icon: NotificationIcon.WARNING,
  },
  ResellerSubscriptionsAlmostExpired: {
    contexts: ["SubscriptionInformationComponent", "BackupComponent"],
    icon: NotificationIcon.WARNING,
  },
  ResellerTrialSubscriptionsAlmostExpired: {
    contexts: ["SubscriptionInformationComponent", "BackupComponent"],
    icon: NotificationIcon.WARNING,
  },
  SubscriptionsExpired: {
    contexts: ["SubscriptionInformationComponent", "BackupComponent", "SecurityComponent"],
    icon: NotificationIcon.DANGER,
  },
  ResellerSubscriptionsExpired: {
    contexts: ["SubscriptionInformationComponent", "BackupComponent"],
    icon: NotificationIcon.DANGER,
  },
  LastPassProvisioned: {
    contexts: ["LastPassDialogComponent"],
    icon: NotificationIcon.SUCCESS,
    component: markRaw(LastPassProvisionSuccessNotification),
  },
  LastPassError: {
    contexts: ["LastPassDialogComponent"],
    icon: NotificationIcon.DANGER,
  },
  AutoRenewTurnOnSuccess: {
    contexts: ["SubscriptionInformationComponent", "BackupComponent"],
    icon: NotificationIcon.SUCCESS,
    component: markRaw(AutoRenewTurnOnSuccessNotification),
  },
  AutoRenewTurnOffSuccess: {
    contexts: ["SubscriptionInformationComponent"],
    icon: NotificationIcon.SUCCESS,
    component: markRaw(AutoRenewTurnOffSuccessNotification),
  },
  AutoRenewTurnOnOrOffFailure: {
    contexts: ["SubscriptionInformationComponent", "BackupComponent"],
    icon: NotificationIcon.DANGER,
  },
  AutoRenewDiscountEnabled: {
    contexts: ["SubscriptionInformationComponent"],
    icon: NotificationIcon.SUCCESS,
    component: markRaw(AutoRenewDiscountEnabledNotification),
  },
  ResellerTrialSubscriptionsExpired: {
    contexts: ["SubscriptionInformationComponent", "BackupComponent"],
    icon: NotificationIcon.DANGER,
  },
  CancelSubscriptionSuccess: {
    contexts: ["SubscriptionInformationComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  CancelSubscriptionFailure: {
    contexts: ["SubscriptionInformationComponent"],
    icon: NotificationIcon.DANGER,
  },
  RenewSuccessMessage: {
    contexts: ["SubscriptionInformationComponent", "BackupComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  RenewFailureMessage: {
    contexts: ["SubscriptionInformationComponent", "BackupComponent", "InvoiceDetailsComponent"],
    icon: NotificationIcon.DANGER,
  },
  InvalidActivationCode: {
    contexts: ["ApplyActivationCodeComponent", "BackupComponent"],
    icon: NotificationIcon.DANGER,
  },
  BusinessActivationCode: {
    contexts: ["ApplyActivationCodeComponent"],
    icon: NotificationIcon.DANGER,
  },
  InactiveActivationCode: {
    contexts: ["ApplyActivationCodeComponent"],
    icon: NotificationIcon.DANGER,
  },
  ExpiredActivationCode: {
    contexts: ["ApplyActivationCodeComponent"],
    icon: NotificationIcon.DANGER,
  },
  ActivationCodeAlreadyApplied: {
    contexts: ["ApplyActivationCodeComponent"],
    icon: NotificationIcon.DANGER,
  },
  ActivationCodeAppliedSuccess: {
    contexts: ["SubscriptionInformationComponent", "BackupComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  ActivationCodeAppliedFailed: {
    contexts: ["ApplyActivationCodeComponent", "BackupComponent"],
    icon: NotificationIcon.DANGER,
  },
  DataAtRiskOutstandingInvoice: {
    contexts: ["BackupComponent"],
    icon: NotificationIcon.DANGER,
  },
  OptInToAutomaticVideoBackupSuccess: {
    contexts: ["BackupComponent"],
    icon: NotificationIcon.SUCCESS,
    component: markRaw(OptInToAutomaticVideoBackupSuccessNotification),
  },
  AlreadyUsedOrderHeaderToken: {
    contexts: ["LoginTabLoginComponent", "CreateAccountTabLoginComponent"],
    icon: NotificationIcon.SUCCESS,
    component: markRaw(LoginFailedNotification),
  },
  VatError_Default: {
    contexts: ["AddVatNumberComponent"],
    icon: NotificationIcon.DANGER,
  },
  VatError_INVALID_INPUT: {
    contexts: ["AddVatNumberComponent"],
    icon: NotificationIcon.DANGER,
  },
  VatError_FORMAT_INVALID: {
    contexts: ["AddVatNumberComponent"],
    icon: NotificationIcon.DANGER,
  },
  VatError_SERVICE_UNAVAILABLE: {
    contexts: ["AddVatNumberComponent"],
    icon: NotificationIcon.DANGER,
  },
  VatError_MS_UNAVAILABLE: {
    contexts: ["AddVatNumberComponent"],
    icon: NotificationIcon.DANGER,
  },
  VatError_TIMEOUT: {
    contexts: ["AddVatNumberComponent"],
    icon: NotificationIcon.DANGER,
  },
  VatError_SERVER_BUSY: {
    contexts: ["AddVatNumberComponent"],
    icon: NotificationIcon.DANGER,
  },
  VatError_BUY_GENERIC_ERROR: {
    contexts: ["AddVatNumberComponent"],
    icon: NotificationIcon.DANGER,
  },
  EncryptionKeySuccess: {
    contexts: ["BackupComponent", "ComputerDropDownComponent", "AddEncryptionKeyComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  EncryptionKeyWrongPassword: {
    contexts: ["AddEncryptionKeyComponent"],
    icon: NotificationIcon.DANGER,
  },
  EncryptionKeyFailure: {
    contexts: ["AddEncryptionKeyComponent"],
    icon: NotificationIcon.DANGER,
  },
  EncryptionKeyInvalidFile: {
    contexts: ["AddEncryptionKeyComponent"],
    icon: NotificationIcon.DANGER,
  },
  SetEmailAddressFailure: {
    contexts: ["PersonalInformationComponent"],
    icon: NotificationIcon.DANGER,
  },
  EmailAddressFormSubmissionError: {
    contexts: ["UpdateEmailAddressComponent"],
    icon: NotificationIcon.DANGER,
  },
  RFA_ComputerHasPrivateKey: {
    contexts: ["FileAccessComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(RfaEncryptedNotification),
  },
  RFA_ExpiredSubscription: {
    contexts: ["FileAccessComponent"],
    icon: NotificationIcon.DANGER,
    component: markRaw(RfaExpiredNotification),
  },
  ValidationError_DuplicateCustomerInEcommerce: {
    contexts: ["CreateAccountTabLoginComponent", "AddKeycodeComponent"],
    icon: NotificationIcon.DANGER,
  },
  AllstateSignUpComplete: {
    contexts: ["AllstateSignUpComponent"],
    icon: NotificationIcon.SUCCESS,
  },
  AllstateSignUpError: {
    contexts: ["AllstateSignUpComponent"],
    icon: NotificationIcon.DANGER,
  },
  AllstateConflictError: {
    contexts: ["AllstateSignUpComponent", "SubscriptionInformationComponent"],
    icon: NotificationIcon.DANGER,
  },
  BrowserNotSupportedError: {
    contexts: ["App"],
    icon: NotificationIcon.DANGER,
  },
  RateLimitCreditCardError: {
    contexts: [
      "CreditCardsComponent",
      "SubscriptionInformationComponent",
      "BuyflowComponent",
      "CourierRecoveryComponent",
    ],
    icon: NotificationIcon.DANGER,
  },
  GenericCreditCardError: {
    contexts: [
      "CreditCardsComponent",
      "SubscriptionInformationComponent",
      "BuyflowComponent",
      "CourierRecoveryComponent",
    ],
    icon: NotificationIcon.DANGER,
  },
  AllstateExpiredError: {
    contexts: ["AllstateSignUpComponent"],
    icon: NotificationIcon.DANGER,
    ignoreBaseLayout: true,
    component: markRaw(AllstateExpiredNotification),
  },
  AllstateExpiredErrorReEnroll: {
    contexts: ["AllstateSignUpComponent"],
    icon: NotificationIcon.DANGER,
    ignoreBaseLayout: true,
    component: markRaw(AllstateExpiredNotification),
  },
  AllstateExpiringWarning: {
    contexts: ["AllstateSignUpComponent"],
    icon: NotificationIcon.WARNING,
    ignoreBaseLayout: true,
    component: markRaw(AllstateExpiredNotification),
  },
  AllstateExpiringWarningReEnroll: {
    contexts: ["AllstateSignUpComponent"],
    icon: NotificationIcon.WARNING,
    ignoreBaseLayout: true,
    component: markRaw(AllstateExpiredNotification),
  },
  AllstateUpgradeInfo: {
    contexts: [
      "HomeComponent",
      "AllstateAlreadySignedUpComponent",
      "SecurityComponent",
      "SubscriptionInformationComponent",
      "DownloadsComponent",
    ],
    icon: NotificationIcon.INFO_BG,
    ignoreBaseLayout: true,
    component: markRaw(AllstateUpgradeNotification),
  },
  unable_to_use_AllstateKeycode: {
    contexts: ["AddKeycodeComponent"],
    icon: NotificationIcon.DANGER,
  },
  DoubleAllstatePremiumError: {
    contexts: ["AllstateSignUpComponent"],
    icon: NotificationIcon.WARNING,
    component: markRaw(DoubleAllstatePremiumErrorNotification),
  },
  BuyflowAutoRenewMonthlyError: {
    contexts: ["BuyflowComponent", "ReviewOrderComponent"],
    icon: NotificationIcon.DANGER,
  },
  CreditCardDeclinedError: {
    contexts: ["BuyflowComponent"],
    icon: NotificationIcon.DANGER,
  },
  CreditCardAddressError: {
    contexts: ["BuyflowComponent", "SubscriptionInformationComponent"],
    icon: NotificationIcon.DANGER,
  },
  PayPalCreditCardAddressError: {
    contexts: ["BuyflowComponent", "SubscriptionInformationComponent"],
    icon: NotificationIcon.DANGER,
  },
  CreditCardInsufficientFunds: {
    contexts: ["BuyflowComponent", "SubscriptionInformationComponent", "InvoiceDetailsComponent"],
    icon: NotificationIcon.DANGER,
  },
  CreditCardInvalidExpirationDate: {
    contexts: ["BuyflowComponent"],
    icon: NotificationIcon.DANGER,
  },
  PurchaseFailureError: {
    contexts: ["BuyflowComponent"],
    icon: NotificationIcon.DANGER,
  },
  BuyCreateAccountSMBUserError: {
    contexts: ["BuyflowComponent"],
    icon: NotificationIcon.DANGER,
  },
  InvalidShippingAddress: {
    contexts: ["BuyflowComponent"],
    icon: NotificationIcon.DANGER,
  },
  InactiveKeycodeAllstateError: {
    contexts: ["AllstateSignUpComponent"],
    icon: NotificationIcon.DANGER,
  },
  NewAccountPasswordError: {
    contexts: ["FinalizeAccount"],
    icon: NotificationIcon.DANGER,
  },
  PurchaseDoneContinueToMyAccountPage: {
    contexts: ["BuyflowComponent"],
    icon: NotificationIcon.WARNING,
  },
  PreliminaryUserTimeOut: {
    contexts: ["BuyflowComponent"],
    icon: NotificationIcon.INFO_BG,
  },
  PreliminaryUserOrderConfirmationTimeOut: {
    contexts: ["BuyflowComponent"],
    icon: NotificationIcon.INFO_BG,
  },
  NonMigratedLegacyWebrootError: {
    contexts: ["SubscriptionInformationComponent"],
    icon: NotificationIcon.DANGER,
    ignoreBaseLayout: true,
    component: markRaw(NonMigratedLegacyWebrootNotification),
  },
  NonZuoraEcommWebrootError: {
    contexts: ["SubscriptionInformationComponent"],
    icon: NotificationIcon.DANGER,
    ignoreBaseLayout: true,
    component: markRaw(NonZuoraEcommWebrootNotification),
  },
  AllstateNonUS: {
    contexts: ["SubscriptionInformationComponent"],
    icon: NotificationIcon.DANGER,
    ignoreBaseLayout: true,
    component: markRaw(AllstateNonUsNotification),
  },
  UnderMaintenance: {
    contexts: ["BackupComponent", "SecurityComponent"],
    icon: NotificationIcon.INFO,
  },
  UpgradeOnRetailRenewalNotAllowed: {
    contexts: ["BuyflowComponent"],
    icon: NotificationIcon.DANGER,
  },
  PlanetOpenTextMismatchNewUser: {
    contexts: ["BuyflowComponent"],
    icon: NotificationIcon.INFO_BG,
  },
  PlanetOpenTextMismatchExistingUser: {
    contexts: ["BuyflowComponent"],
    icon: NotificationIcon.INFO_BG,
  },
  //END
};

export const NOTIFICATION_SEQUENCE_LAST = 9999;

// Configure notifications priority -- The order on notificationsInOrder is what we will get on screen
export const notificationPriority = [
  {
    context: "BackupComponent",
    notificationsInOrder: [
      "AddCreditCardFailed",
      "AddCreditCardSuccess",
      "MovePaymentMethodMRToAR",
      "SetTwoStepVerificationSuccess",
      "PartialRetrieveKeycodesFailed",
      "DownloadSafeFailed",
      "MoveMySubscriptionError",
      "MoveMySubscriptionSuccess",
      "MoveMySubscriptionComputerOrUser_Invalid",
      "AutoRenewTurnOnSuccess",
      "AutoRenewTurnOnOrOffFailure",
      "RenewSuccessMessage",
      "RenewFailureMessage",
      "InvalidActivationCode",
      "ActivationCodeAppliedSuccess",
      "ActivationCodeAppliedFailed",
      "OptInToAutomaticVideoBackupSuccess",
      "EncryptionKeySuccess",
      "EncryptionKeyFailure",

      "CardComputerSubscriptionExpired",
      "SubscriptionsExpired",
      "ResellerSubscriptionsExpired",
      "ResellerTrialSubscriptionsExpired",
      "DataAtRiskOutstandingInvoice",

      "CardComputerSubscriptionWillExpire",
      "ResellerSubscriptionsAlmostExpired",
      "SubscriptionWillExpireTrial",
      "ResellerTrialSubscriptionsAlmostExpired",

      "CardComputerBackupOutDatedSevere",
      "CardComputerBackupOutDated",
    ],
  },
];

export const useNotificationsStore = defineStore("notifications", {
  state: (): INotificationsState => ({
    notifications: [],
    notificationCounter: 0,
  }),
  getters: {
    getNotifications(state) {
      return (context?: string | string[]) => {
        if (!context) {
          // return all notifications... or global ones?
          return [];
        }

        if (typeof context === "string") context = [context];

        // return notifications requested by the provided contexts
        return state.notifications.filter(n => {
          for (const c1 of context as string[]) {
            for (const c2 of n.contexts) {
              if (c1 === c2) return true;
            }
          }
          return false;
        });
      };
    },
  },
  actions: {
    addNotification(options: INotificationOptions) {
      this.deleteNotificationByType(options.type);
      let metadata = NotificationLookup[options.type];
      //Check if the error returned is one we have an error for.
      //If not display a generic error
      if (!metadata) {
        metadata = NotificationLookup["ServerErrors"];
      }

      const note = {
        id: this.notificationCounter++,
        type: options.type,
        icon: metadata.icon,
        contexts: metadata.contexts,
        datetime: Date.now(),
        dismissible: options.dismissible ?? true,
        dismissed: false,
        params: options.params as INotificationParams,
        component: metadata.component,
        order: NOTIFICATION_SEQUENCE_LAST,
        ignoreBaseLayout: metadata.ignoreBaseLayout,
      };
      logEvent("add notification", "addNotification", note);

      if (options.timeout) {
        // queue timeout to delete notification when time comes
        setTimeout(() => {
          this.deleteNotification(note.id);
        }, options.timeout);
      }

      this.notifications.push(note);
    },
    dismissNotification(id: number) {
      const note = this.notifications.find(n => n.id === id);
      if (note) note.dismissed = true;
      logEvent("complete", "dismissNotification", note);
    },
    deleteNotification(id: number) {
      const idx = this.notifications.findIndex(n => n.id === id);
      let found = false;
      if (idx >= 0) {
        this.notifications.splice(idx, 1);
        found = true;
      }
      logEvent("complete", "deleteNotification", { id, found });
    },
    deleteNotificationByType(type: NotificationType) {
      const idx = this.notifications.findIndex(n => n.type === type);
      let found = false;
      if (idx >= 0) {
        this.notifications.splice(idx, 1);
        found = true;
      }
      logEvent("complete", "deleteNotificationByType", { type, found });
    },
    clearNotifications() {
      const userStore = useUserStore();
      const notificationsStore = useNotificationsStore();
      const user = computed(() => userStore.currentUser);
      // We need to check if BrowserNotSupportError is being displayed
      const isDisplayingBrowserNotSupportedError = this.notifications.some(n => n.type === "BrowserNotSupportedError");

      this.notifications = [];
      this.notificationCounter = 0;

      // Add BrowserNotSupportError back to keep displaying
      if (isDisplayingBrowserNotSupportedError)
        this.addNotification({ type: "BrowserNotSupportedError", dismissible: false });
      // Add PendingWebroot licenses notification if webroot licenses are pending and email is not validated
      if (user.value?.subscriberInfo?.hasPendingWebrootLicenses && !user.value?.subscriberInfo?.hasValidatedEmail) {
        notificationsStore.addNotification({ type: "PrimaryEmailVerificationWebroot" });
      }
      logEvent("complete", "clearNotifications");
    },
  },
});
