<template>
  <div v-if="showSpinner">
    <LoadingSpinner />
  </div>
  <div v-else>
    <div class="content">
      <PrimaryEmailVerificationWrapperComponent
        notification-context="SecurityComponent"
        :email="email"
        :show-dialog="toggleVerifyEmail"
        @close-dialog="closeVerifyDialog()"
      />
      <div v-if="hasErrorsInDevices || errorFetchingDevices">
        <header>
          <h1 class="h1">{{ tn("headerH1") }}</h1>
        </header>
        <div class="mb-double">
          <i18n-t keypath="SecurityComponent.serverError" tag="small" scope="global">
            <template #contactSupportLink>
              <button class="inline-btn-link" @click="contactSupportBtnClick()">
                {{ t("Common.contactSupport") }}
              </button>
            </template>
          </i18n-t>
        </div>
        <div class="mb-double">
          <h1 class="h1 ml-half">{{ tn("headerH2") }}</h1>
          <i18n-t keypath="SecurityComponent.serverErrorDevices" tag="small" scope="global">
            <template #contactSupportLink>
              <button class="inline-btn-link" @click="contactSupportBtnClick()">
                {{ t("Common.contactSupport") }}
              </button>
            </template>
          </i18n-t>
        </div>
      </div>
      <!-- cdlp security -->
      <div v-if="FLAGS.ENABLE_CDLP === 'true' && hasLicenses" class="mb-triple">
        <header v-if="!hideSecuritySummary" class="mb-double">
          <h1 class="h1 ml-half">{{ t("Common.security") }}</h1>
        </header>
        <article v-if="!hideSecuritySummary" class="tile-wrapper mb-double">
          <TileDetails>
            <template #content>
              <ul class="tile">
                <li v-for="(item, i) in summaryItems" :key="i" :class="calcItemClass(item)">
                  {{ tn(`${item.caption}`) }}
                  <b>{{ convertAmount(item) }}</b>
                  <em>{{ convertUnitType(item) }}</em>
                </li>
              </ul>
            </template>
          </TileDetails>
        </article>
        <div v-if="FLAGS.ENABLE_CDLP === 'true'">
          <h3 class="text-lg ml-half">{{ tn("webrootBackupRestore") }}</h3>
          <article v-for="(sub, l) in subData" :key="l" class="tile-wrapper no-grid">
            <DeviceTileComponent
              v-if="getCdlpSubInfo(sub.keycode)"
              :tile-data="sub"
              :webroot-sub-info="getCdlpSubInfo(sub.keycode)"
              :sub="getSubscriptionInfo(sub.keycode)"
              :last-pass-info="lastPassInfo"
              @open-last-pass-modal="openLastPassModal"
              @open-chromebook-download="toggleDownloadProductModal"
            />
          </article>
        </div>
      </div>
      <!-- regular webroot security -->
      <div v-if="hasLicenses" class="mb-triple">
        <header v-if="!hideSecuritySummary">
          <h1 v-if="FLAGS.ENABLE_CDLP === 'false'" class="h1 ml-half">{{ tn("headerH1") }}</h1>
        </header>
        <h3 v-if="FLAGS.ENABLE_CDLP === 'true'" class="text-lg ml-half">{{ t("Brand.webrootAntivirus") }}</h3>
        <article v-if="FLAGS.ENABLE_CDLP === 'false' && !hideSecuritySummary" id="cardForSecurity" class="tile-wrapper">
          <TileDetails>
            <template #content>
              <ul class="tile">
                <li v-for="(item, i) in summaryItems" :key="i" :class="calcItemClass(item)">
                  {{ tn(`${item.caption}`) }}
                  <b>{{ convertAmount(item) }}</b>
                  <em>{{ convertUnitType(item) }}</em>
                </li>
              </ul>
            </template>
          </TileDetails>
        </article>
        <div>
          <h1 v-if="FLAGS.ENABLE_CDLP === 'false'" class="h1 ml-half">{{ tn("headerH2") }}</h1>
          <article v-for="(sub, l) in subData" :key="l" class="tile-wrapper no-grid">
            <DeviceTileComponent
              v-if="getWebrootSubInfo(sub.keycode)"
              :tile-data="sub"
              :webroot-sub-info="getWebrootSubInfo(sub.keycode)"
              :sub="getSubscriptionInfo(sub.keycode)"
              :last-pass-info="lastPassInfo"
              @open-last-pass-modal="openLastPassModal"
              @open-chromebook-download="toggleDownloadProductModal"
            />
          </article>
        </div>
        <div v-if="displayChromebookAndMobileNotes" id="divWscbNote">
          {{ tn("WSCBnote") }}
        </div>
      </div>

      <div v-else-if="needsToValidateEmail">
        <h1>{{ tn("noLicenseHeaderH1") }}</h1>
        <div class="mb"><svg-icon-component icon="webroot-logo" class="logo" /></div>
        <h4 class="mb">{{ tn("addWebroot") }}</h4>
        <p class="mb">{{ tn("verifyEmailMessage") }}</p>
        <div class="mb-double">
          <button id="verifyEmailBtn" class="btn-primary" type="button" @click="toggleVerifyEmailDialog()">
            {{ tn("btnVerifyEmail") }}
          </button>
        </div>
      </div>

      <div v-else>
        <h1 class="h1">{{ tn("noLicenseHeaderH1") }}</h1>
        <h3 class="mb">{{ tn("noLicenseHeaderH2") }}</h3>
        <div class="mb"><svg-icon-component icon="webroot-logo" class="logo" /></div>
        <h4 class="mb">{{ tn("addWebroot") }}</h4>
        <p class="mb">{{ tn("noLicenseP1") }}</p>
        <div class="mb-double">
          <button id="learnMoreBtn" class="btn-outline-primary" type="button" @click="learnMoreBtnClick()">
            {{ tn("learnMoreBtn") }}
          </button>
        </div>
        <div class="mb">
          <i18n-t keypath="SecurityComponent.noLicenseP2" tag="small" scope="global">
            <template #downloadsLink>
              <button class="inline-btn-link" @click="downloadsBtnClick()">{{ tn("downloadsLink") }}</button>
            </template>
          </i18n-t>
        </div>
      </div>
    </div>
    <LastPassDialogComponent
      :show-dialog="showLastPassModal"
      :operation="modalOperation"
      @close-modal="toggleLastPassModal"
      @save-modal="toggleLastPassModal"
    />

    <DownloadProductComponent
      :show-dialog="showDownloadProductModal"
      :product-name="`WSCB`"
      :keycodes="subscriberInfo"
      :install-type="`Install`"
      @close-modal="toggleDownloadProductModal"
    />
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, computed, onMounted } from "vue";
import TileDetails from "@/components/shared/TileDetails.vue";
import DeviceTileComponent from "./DeviceTile.vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { logEvent } from "@/common/logger";
import { useRouter } from "vue-router";
import {
  ITileData,
  ITileItemData,
  calcItemClass,
  convertAmount,
  convertUnitType,
  ISkyDevice,
  IShield,
} from "@/components/Home";
import { UnitType, StatusType, IconType } from "@/globalEnums";
import {
  ISkyWebrootDevice,
  ISubscriberDownloadsInfo,
  ISkyShields,
  IWebrootSubscriptions,
  ILastPassInfo,
  ISubscription,
} from "@/common/api/unifiedPortal/interfaces";
import { getInstalledDevices } from "@/common/securityDevices";
import { unifiedApi } from "@/common";
import { useSecurityStore } from "@/stores/security";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { useNotificationsStore } from "@/stores/notifications";
import PrimaryEmailVerificationWrapperComponent from "@/components/shared/PrimaryEmailVerificationWrapper.vue";
import LastPassDialogComponent from "@/components/shared/dialogs/LastPass/LastPassDialog.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import {
  processSubscriptionDataForDisplay,
  isNotActiveYet,
  showWarning,
  showDanger,
} from "@/common/subscriptionHelper";
import { t } from "@/i18n";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { FLAGS, MSEC_IN_DAY } from "@/define";
import { useUserStore } from "@/stores/user";
import {
  SUBSCRIPTION_BESTBUY_RENEWAL_CONVERSION_DAY,
  SUBSCRIPTION_MIN_DATA_LOSS_DAY,
  EXCLUDED_FROM_SECURITY_AND_HOME_TAB_SECURITY,
} from "@/define";
import { KeyCodeSeller } from "@/globalEnums";
import { hasOnlyBestBuyS2 } from "@/common/bestBuy";
import { addOnLCNs, webrootCDLPcodes } from "@/common/webrootProductName";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import { webrootLicenseCategories } from "@/common/webrootProductName";
import DownloadProductComponent from "@/components/shared/dialogs/DownloadProduct/DownloadProduct.vue";
import { productList } from "@/common/productList";
import { getUrl } from "@/common/getUrl";
import { showAllstateBanner } from "@/components/Allstate/AllstateHelper";
import { useBuyFlowStore } from "@/stores/buyFlow";

const componentName = "SecurityComponent";

logEvent("Created", componentName);
const userStore = useUserStore();
const email = ref<string>("");
const notificationsStore = useNotificationsStore();
const subscriptionsStore = useSubscriptionsStore();
const router = useRouter();
const summaryItems = ref<ITileItemData[]>([]);
const subData = ref<ITileData[]>([]);
const subscriberInfo = ref<ISubscriberDownloadsInfo>();
const showSpinner = ref<boolean>(true);
const securityStore = useSecurityStore();
const showLastPassModal = ref<boolean>(false);
const modalOperation = ref<string>("");
const lastPassInfo = ref<ILastPassInfo[]>([]);
const hasErrorsInDevices = ref<boolean>(false);
const errorFetchingDevices = ref<boolean>(false);
const subscriptionInformation = ref<ISubscription[]>([]);
const user = computed(() => userStore.currentUser);
const isBestBuyS2 = ref<boolean>(false);
const toggleVerifyEmail = ref<boolean>(false);
const showDownloadProductModal = ref<boolean>(false);
const buyFlowStore = useBuyFlowStore();

const hasLicenses = computed(() => {
  return subData.value.length > 0;
});

const hideSecuritySummary = computed(() => subData.value.every(c => c.title === webrootLicenseCategories.WSCB));

const displayChromebookAndMobileNotes = computed(() => {
  const wrmsLicenseCategories = productList().find(p => p.code === "WRMS")?.licenseCategories ?? "";
  const wscbLicenseCategories = productList().find(p => p.code === "WSCB")?.licenseCategories ?? "";
  return subData.value.some(c => wrmsLicenseCategories.includes(c.title) || wscbLicenseCategories.includes(c.title));
});

const needsToValidateEmail = computed(() => {
  return !!user.value?.subscriberInfo?.hasPendingWebrootLicenses && !user.value?.subscriberInfo?.hasValidatedEmail;
});
onMounted(async () => {
  if (buyFlowStore.plans.length === 0) {
    await buyFlowStore.populatePlans();
  }
  logEvent("mounted", componentName, user.value);
  email.value = user.value?.email ?? "";
  // Allstate upgrade banner displays if More than 90 days from sub expiring and api returns showUpgradedBanner and flag is set to show allstate family
  showAllstateBanner();
  if (needsToValidateEmail.value) {
    notificationsStore.addNotification({ type: "PrimaryEmailVerificationWebroot" });
  } else {
    notificationsStore.deleteNotificationByType("PrimaryEmailVerificationWebroot");
  }
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function toggleVerifyEmailDialog() {
  toggleVerifyEmail.value = !toggleVerifyEmail.value;
}
async function closeVerifyDialog() {
  toggleVerifyEmail.value = false;
  await refreshSecurity();
}

async function refreshSecurity() {
  logEvent("refreshSecurity", componentName);
  showSpinner.value = true;
  await subscriptionsStore.forceRefreshAndPopulateSubscriptions();
  await reloadSubscriberInfo();
  await useSecurityStore().forceRefresh();
  const subs = subscriptionsStore.subscriptions;
  subscriptionInformation.value = processSubscriptionDataForDisplay(subs);
  await getSecurityData();
  await getLastPassInfo();
  isBestBuyS2.value = await hasOnlyBestBuyS2();
  showSpinner.value = false;
  logEvent("refreshSecurity Completed", componentName);
}

async function getSecurityData() {
  // get user's devices
  try {
    const skyDevices = await securityStore.getSecurity();
    hasErrorsInDevices.value = skyDevices?.webrootDeviceResult?.some(l => !l.success) || false;
    const totalInstalledDevices = getInstalledDevices(skyDevices.webrootDeviceResult ?? []);
    if (totalInstalledDevices.length > 0) {
      summaryItems.value = [
        {
          caption: "protecting",
          value: totalInstalledDevices.length, // using scale of 1000s
          unitType: UnitType.computers,
          iconType: IconType.devices,
        },
        {
          caption: "threatProtection",
          value: skyDevices.totalEnabledShields,
          maxValue: skyDevices.totalShields,
          unitType: UnitType.shields,
          iconType: IconType.threat,
        },
      ];
    } else {
      summaryItems.value = [
        {
          caption: "protecting",
          value: 0, // using scale of 1000s
          unitType: UnitType.computers,
          iconType: IconType.devices,
        },
        {
          caption: "threatProtection",
          value: 0,
          maxValue: 0,
          unitType: UnitType.shields,
          iconType: IconType.threat,
        },
      ];
    }
    const response = (await unifiedApi.getSubscriberDownloadsInfo()).data;
    if (response) {
      subscriberInfo.value = response;
    }
    if (response && subscriberInfo.value) {
      // Remove items that aren't Active yet or Add-On
      let webrootSubs = subscriberInfo.value.webrootSubscriptions;
      const notActiveYetSubscriptions = webrootSubs.filter(item => isNotActiveYet(getSubscriptionInfo(item.keycode)));
      const activeSubscriptions = webrootSubs.filter(
        item =>
          !notActiveYetSubscriptions.includes(item) &&
          //remove Add-Ons
          !addOnLCNs.includes(item.licenseCategoryName)
      );

      // Order items with expiration date
      const orderedWebrootSubs = OrderSubscriptionsByExpirationAsc(activeSubscriptions);
      // ZUOR-39109: Remove BBY S2 subs that are past the BBY S2 conversion date
      let filteredOrderedSubs = orderedWebrootSubs.filter(item => filterOutExpiredBestBuyKeycodes(item));
      // ZUOR-39155: Remove Safe keycodes for ActivationCodes that are purged
      filteredOrderedSubs = filteredOrderedSubs.filter(item => filterOutExpiredSafeKeycodes(item));
      // ZUOR-48885: Remove WIFI keycodes
      filteredOrderedSubs = filteredOrderedSubs.filter(item => filterOutWIFIKeycodes(item));
      //  ZUOR-48952 ZUOR-49027 remove WW ADP WSASB
      filteredOrderedSubs = filteredOrderedSubs.filter(item => filterOutSunsetKeycodes(item));

      // Add Not Active yet items to the beginning of the Array
      webrootSubs = [...filteredOrderedSubs, ...notActiveYetSubscriptions];

      webrootSubs.forEach(sub => {
        const subInfo = getSubscriptionInfo(sub.keycode);

        subData.value.push({
          title: sub.licenseCategoryName,
          type: "",
          status: getStatusType(subInfo),
          state: "",
          devices: formatDevices(
            skyDevices.webrootDeviceResult !== null
              ? totalInstalledDevices.length > 0
                ? totalInstalledDevices.filter(result => result.licenseKey == sub.keycode) || []
                : []
              : []
          ),
          keycode: sub.keycode,
          identifier: sub.keycode,
        });
      });
      processNotifications();
    } else {
      logEvent(`getSubscriberDownloadsInfo result NULL`, `${componentName}/getSecurityData`);
    }
  } catch (error) {
    handleApiError(error as AxiosError, true, "ServerErrors");
    errorFetchingDevices.value = true;
  }
}

function filterOutExpiredBestBuyKeycodes(sub: IWebrootSubscriptions) {
  const subInfo = getSubscriptionInfo(sub.keycode);

  if (subInfo?.keyCodeSellerType === KeyCodeSeller.BestBuy) {
    return subInfo?.remainingDays > SUBSCRIPTION_BESTBUY_RENEWAL_CONVERSION_DAY;
  }

  return true;
}

function filterOutExpiredSafeKeycodes(sub: IWebrootSubscriptions) {
  const subInfo = getSubscriptionInfo(sub.keycode);

  if (subInfo?.keyCodeSellerType === KeyCodeSeller.Safe) {
    return subInfo?.remainingDays > SUBSCRIPTION_MIN_DATA_LOSS_DAY;
  }

  return true;
}

function filterOutWIFIKeycodes(sub: IWebrootSubscriptions) {
  return sub.licenseCategoryName !== webrootLicenseCategories.WIFI;
}
function filterOutSunsetKeycodes(sub: IWebrootSubscriptions) {
  return !EXCLUDED_FROM_SECURITY_AND_HOME_TAB_SECURITY.includes(sub.licenseCategoryName);
}

function processNotifications() {
  if (user.value?.subscriberInfo?.isUnderMaintenance) {
    notificationsStore.addNotification({ type: "UnderMaintenance", dismissible: false });
    return;
  }

  if (subData.value.some(l => l.status === StatusType.danger)) {
    notificationsStore.addNotification({ type: "SubscriptionsExpired", dismissible: false });
    return;
  }

  if (subData.value.some(l => l.status === StatusType.warning)) {
    notificationsStore.addNotification({ type: "SubscriptionsAlmostExpired", dismissible: false });
    return;
  }
}

function getWebrootSubInfo(keycode: string | undefined): IWebrootSubscriptions | undefined {
  const nonCdlpSubscriberInfo = subscriberInfo.value?.webrootSubscriptions.filter(
    d => !webrootCDLPcodes.some(l => l === d.licenseCategoryName)
  );
  return nonCdlpSubscriberInfo?.find(l => l.keycode === keycode);
}
function getCdlpSubInfo(keycode: string | undefined): IWebrootSubscriptions | undefined {
  const cdlpSubscriberInfo = subscriberInfo.value?.webrootSubscriptions.filter(d =>
    webrootCDLPcodes.some(l => l === d.licenseCategoryName)
  );
  return cdlpSubscriberInfo?.find(l => l.keycode === keycode);
}

function getSubscriptionInfo(keycode: string | undefined): ISubscription | undefined {
  // Added SKU to avoid getting wrong subscription in case of SafeLite
  return subscriptionInformation.value?.find(
    l =>
      l.keyCode?.includes(keycode ?? "") &&
      (l.sku?.toLowerCase() !== "personalbasic" || (l.sku?.toLowerCase() === "personalbasic" && l.isTrial))
  );
}

function getExpirationDays(expirationDate: string) {
  return (new Date(expirationDate).getTime() - new Date().getTime()) / MSEC_IN_DAY;
}

function OrderSubscriptionsByExpirationAsc(subs: IWebrootSubscriptions[]) {
  return subs.sort((a, b) => {
    const expirationDaysA = getExpirationDays(a.expirationDate);
    const expirationDaysB = getExpirationDays(b.expirationDate);

    if (expirationDaysA < expirationDaysB) {
      return -1;
    }

    if (expirationDaysA > expirationDaysB) {
      return 1;
    }

    return 0;
  });
}

function getStatusType(sub: ISubscription | undefined) {
  let statusType = StatusType.normal;

  if (sub && showDanger(sub)) {
    statusType = StatusType.danger;
  } else if (sub && showWarning(sub)) {
    statusType = StatusType.warning;
  }

  return statusType;
}

function formatDevices(devices: ISkyWebrootDevice[]) {
  const formattedDevices: ISkyDevice[] = [];

  devices.forEach(device => {
    formattedDevices.push({
      name: device.hostName,
      nickname: device.deviceNickname,
      keycode: device.licenseKey,
      instanceId: device.instanceId,
      id: device.deviceId,
      isVisible: device.isVisible !== null ? device.isVisible : true,
      operatingSystem: device.operatingSystem,
      osBuild: device.osBuild || "",
      osPlatform: device.osPlatform,
      type: device.deviceType,
      version: device.version || "",
      shields: getShieldsInfo(device.shields),
    });
  });

  return formattedDevices;
}

function getShieldsInfo(deviceShields: ISkyShields) {
  const shields: IShield[] = [];

  Object.entries(deviceShields).map(entry => {
    const key = entry[0];
    const value = entry[1];
    if (value !== null && value !== undefined) {
      shields.push({ name: key, value: value });
    }
  });

  return orderShieldsAlphabetically(shields);
}

function orderShieldsAlphabetically(shields: IShield[]) {
  return shields.sort(function (a, b) {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1; //nameA comes first
    }
    if (nameA > nameB) {
      return 1; // nameB comes first
    }
    return 0; // names must be equal
  });
}

function learnMoreBtnClick() {
  logEvent("Btn Click: Go To www.webroot.com/us/en/home", componentName);
  window.open(getUrl("WEBROOT_HOME"), "_blank");
}

function downloadsBtnClick() {
  logEvent("Btn Click: Go To downloads page", componentName);
  router.push("/downloads");
}

onBeforeMount(async () => {
  await subscriptionsStore.populateSubscriptions();
  const subs = subscriptionsStore.subscriptions;
  subscriptionInformation.value = processSubscriptionDataForDisplay(subs);
  await getSecurityData();
  await getLastPassInfo();
  isBestBuyS2.value = await hasOnlyBestBuyS2();
  showSpinner.value = false;
});

// LastPass
async function getLastPassInfo() {
  try {
    lastPassInfo.value = (await unifiedApi.getLastPassInfo()).data;
  } catch (error) {
    handleApiError(error as AxiosError, true, "LastPassError");
  }
}

async function toggleLastPassModal(type: string) {
  type = type || "modal";
  logEvent("toggleLastPassModal", componentName, `${showLastPassModal.value ? "Hiding" : "Showing"} via ${type}`);
  showLastPassModal.value = !showLastPassModal.value;
  if (!showLastPassModal.value) {
    showSpinner.value = true;
    await getLastPassInfo();
    subData.value = [];
    securityStore.forceRefresh();
    await getSecurityData();
    showSpinner.value = false;
  }
}

function openLastPassModal(operation: string) {
  modalOperation.value = operation;
  toggleLastPassModal(operation);
}

function contactSupportBtnClick() {
  window.open(getUrl("WEBROOT_SUPPORT_HOME"), "_blank");
}

function toggleDownloadProductModal() {
  logEvent("toggleDownloadProductModal", componentName, `${showDownloadProductModal.value ? "Hiding" : "Showing"}`);
  showDownloadProductModal.value = !showDownloadProductModal.value;
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
.logo {
  max-width: 300px;
}
</style>
