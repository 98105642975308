<template>
  <article class="mb-double">
    <svg-icon-component class="mb" icon="webroot-logo" />
    <ul class="disc mb">
      <li>{{ tn("webrootSecureAnyWhere") }}</li>
      <li>{{ tn("webThreatShield") }}</li>
      <li>{{ tn("mobileSecurity") }}</li>
      <li>{{ tn("chromebookSecurity") }}</li>
      <li>{{ tn("wifiSecurity") }}</li>
    </ul>
    <div class="support-wrapper">
      <div class="search-container mb">
        <p id="pWebrootDescription">{{ tn("findHelpWebrootText") }}</p>
        <form @submit.prevent>
          <div class="input-btn-set-md width-half-lg">
            <div class="input-wrapper">
              <input
                id="searchWebroot"
                v-model="wrSearchText"
                type="text"
                :placeholder="tn('searchKnowledge')"
                @keyup.enter="searchWebroot"
              />
            </div>
            <div>
              <button id="btnSearchWebroot" type="button" class="btn-primary" @click="searchWebroot">
                {{ tn("search") }}
              </button>
            </div>
          </div>
        </form>
        <div class="popular-searches mt-quarter">
          {{ tn("popularSearches") }}:
          <span v-for="(item, index) in wrSearches" :key="item.searchText">
            <a :id="`aWebrootPopularSearch${index}`" :href="getUrl(item.searchUrl)" target="_blank">
              {{ tn(item.searchText) }}
            </a>
            <em v-if="index != wrSearches.length - 1" class="only-show-desktop-inline">,&nbsp;</em>
          </span>
        </div>
      </div>
      <div>
        <h2 id="pWebrootCustomerSupport" class="mb-half">{{ tn("webrootCustomerSupport") }}</h2>
        <div>
          <button id="goWebroot" class="inline-btn-link" @click="goWebroot">{{ tn("webrootSupportText") }}</button>
        </div>
      </div>
    </div>
  </article>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { useUserStore } from "@/stores/user";
import { getLanguageUrlParm } from "@/common/helper";
import { getUrl } from "@/common/getUrl";

const componentName = "WebrootSupportSectionComponent";

logEvent("created", componentName);

const wrSearches = computed(() => {
  return [
    {
      searchText: "installPC",
      searchUrl: "VITE_APP_WEBROOT_HOW_TO_INSTALL",
    },
    {
      searchText: "locateYourKeycode",
      searchUrl: "VITE_APP_WEBROOT_INSTALL_SUPPORT",
    },
    {
      searchText: "uninstallPC",
      searchUrl: "VITE_APP_WEBROOT_HOW_TO_UNINSTALL",
    },
  ];
});

const wrSearchText = ref<string>("");
const userStore = useUserStore();
const languageUrlParm = computed(() => getLanguageUrlParm(userStore.selectedLanguage || "en"));

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function searchWebroot() {
  logEvent("Search Webroot", componentName, { searchText: wrSearchText.value });
  const lang = localStorage.getItem("selectedLanguage") ?? "en";
  let url = getUrl("WEBROOT_SUPPORT_SEARCH");

  if (lang === "en") {
    url = `${url}&userText=${wrSearchText.value}`;
  }

  window.open(url);
}

function goWebroot() {
  logEvent("Go to Webroot Knowledge base", componentName);
  window.open(`https://www.webroot.com/${languageUrlParm.value}/support/support-home`);
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.popular-searches span {
  display: block;
  margin: var(--space) 0 var(--space) var(--space);
}

@media (min-width: 600px) {
  .popular-searches span {
    display: inline-block;
    margin: 0;
  }

  .popular-searches {
    font-size: var(--small-text);
  }
}
</style>
