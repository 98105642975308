<template>
  <div>
    <ModalWrapper
      dialog-name="candyRackInfoModal"
      :show-dialog="showModal"
      :show-footer="false"
      css-class="modal-sm"
      @close-modal="close"
      @pre-open="beforeOpen"
    >
      <template #header>
        <span v-if="props.sku">{{ t(`Brand.${props.sku}`) }}</span>
      </template>
      <template #content>
        <div v-if="props.sku">
          <div v-if="currentConfig?.contentType === 'P'" class="mb">
            <p v-for="(c, index) in currentConfig?.content" :id="`Paragraph_${index}`" :key="c">{{ tn(c) }}</p>
          </div>
          <ul v-if="currentConfig?.contentType === 'UL'" class="disc tight mb">
            <li v-for="(c, index) in currentConfig?.content" :id="`ListItem_${index}`" :key="c">{{ tn(c) }}</li>
          </ul>
          <p v-if="currentConfig?.displayStartingAt" class="success-text mb">
            <strong>{{ tn("StartingAt", { price: props.price }) }}</strong>
          </p>
          <div>
            <button
              v-if="currentConfig?.displayAddToCartButton"
              id="btnAddToCart"
              type="button"
              class="btn-primary"
              @click="addToCart()"
            >
              {{ t("Common.addToCart") }}
            </button>
          </div>
        </div>
      </template>
    </ModalWrapper>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import ModalWrapper from "@/components/shared/dialogs/ModalWrapper.vue";
import { webrootCDSku } from "@/common/webrootProductName";

const componentName = "SpecialOffersInfo";

const props = defineProps({
  showDialog: {
    type: Boolean,
    default: false,
  },
  sku: {
    type: String,
    default: "",
  },
  price: {
    type: String,
    default: "",
  },
});
const emits = defineEmits(["close-modal", "add-to-cart"]);
const showModal = computed<boolean>(() => props.showDialog);

// This configuration const is specific for this component, so keeping it here
const displayConfig = [
  {
    sku: webrootCDSku,
    contentType: "P",
    content: ["SoftwareCDP1"],
    displayAddToCartButton: false,
    displayStartingAt: false,
  },
  {
    sku: "WebrootSecureVPN",
    contentType: "P",
    content: ["WebrootWiFiSecurityP1", "WebrootWiFiSecurityP2"],
    displayAddToCartButton: true,
    displayStartingAt: true,
  },
  {
    sku: "AllstateIdentityProtectionBasic",
    contentType: "UL",
    content: [
      "AllstateIdentityProtectionBasicLI1",
      "AllstateIdentityProtectionBasicLI2",
      "AllstateIdentityProtectionBasicLI3",
    ],
    displayAddToCartButton: true,
    displayStartingAt: true,
  },
  {
    sku: "AllstateIdentityProtectionPremium",
    contentType: "UL",
    content: [
      "AllstateIdentityProtectionPremiumL1",
      "AllstateIdentityProtectionPremiumL2",
      "AllstateIdentityProtectionPremiumL3",
    ],
    displayAddToCartButton: true,
    displayStartingAt: true,
  },
  {
    sku: "AllstateIdentityProtectionPremiumFamily",
    contentType: "UL",
    content: [
      "AllstateIdentityProtectionPremiumFamilyL1",
      "AllstateIdentityProtectionPremiumFamilyL2",
      "AllstateIdentityProtectionPremiumFamilyL3",
    ],
    displayAddToCartButton: true,
    displayStartingAt: true,
  },
  {
    sku: "WebrootPcOptimizer",
    contentType: "P",
    content: ["WebrootPcOptimizerDescriptionP1", "WebrootPcOptimizerDescriptionP2"],
    displayAddToCartButton: true,
    displayStartingAt: true,
  },
];

const currentConfig = computed(() => displayConfig.find(c => c.sku === props.sku));

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function beforeOpen() {
  logEvent("created", componentName);
}

async function addToCart() {
  emits("add-to-cart", props.sku);
}

function close() {
  emits("close-modal");
}
</script>
