<template>
  <div>
    <ul
      v-if="
        specialOffers &&
        specialOffers.length > 0 &&
        buyFlowStore.pageState === buyFlowPageStates.email &&
        FLAGS.ENABLE_BUYFLOW_WEBROOT
      "
      :key="specialOffers?.length"
      class="mb-double"
    >
      <li>
        <h1>{{ tn("specialOffersH1") }}</h1>
      </li>
      <li
        v-for="(specialOffer, index) in specialOffers"
        :id="`specialOfferProduct-${index}`"
        :key="index"
        class="constrain-50 mb"
      >
        <div class="flex-start mb-0">
          <h2>{{ t(`Brand.${specialOffer.sku}`) }}</h2>
          <button :id="`btnInfo-${index}`" type="button" class="btn-link" @click="showCandyRackInfo(specialOffer.sku)">
            <svg-icon-component icon="circle-information" class="icon" />
          </button>
        </div>
        <p
          v-for="d in webrootProducts.find(l => l.sku === specialOffer.sku)?.candyRackDescription"
          :key="d"
          class="mb-quarter"
        >
          {{ t(d) }}
        </p>
        <h3 class="success-text mb-half">
          {{ tn("startingAt") }}
          {{ formatMoney(startingRatePlan(specialOffer).newSubscriptionPrice) }}
        </h3>
        <div>
          <button
            :id="`btnAddToCart-${index}`"
            type="button"
            class="btn-outline-primary"
            @click="addProduct(specialOffer.sku)"
          >
            {{ t("Common.addToCart") }}
          </button>
        </div>
      </li>
    </ul>
    <CandyRackInfo
      :show-dialog="displayCandyRackInfo || false"
      :sku="currentSku"
      :price="currentStartingPrice"
      @add-to-cart="AddToCartFromCandyRackInfo"
      @close-modal="closeShowCandyRackInfo()"
    />
  </div>
</template>
<script setup lang="ts">
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { ICartItem, IProductCatalog } from "@/common/api/unifiedPortal/interfaces";
import { formatMoney, sortArrayOfObjects } from "@/components/Buy/commonFn";
import { webrootProducts } from "@/common/webrootProductName";
import { computed, onBeforeMount, ref, watch } from "vue";
import { pageStates as buyFlowPageStates } from "@/components/Buy/BuyEnums";
import CandyRackInfo from "@/components/Buy/Dialogs/SpecialOffersInfo.vue";
import { FLAGS } from "@/define";
import { getSpecialOffersToDisplay } from "./BuyHelper";
import { googleTagCartEvents } from "@/common/googleTagEvents";
import { useUserStore } from "@/stores/user";

const componentName = "SpecialOffers";
const buyFlowStore = useBuyFlowStore();
const currentSku = ref<string>("");
const currentStartingPrice = ref<string>("");
const displayCandyRackInfo = ref<boolean>(false);
const specialOffers = ref<IProductCatalog[] | undefined>();
const userStore = useUserStore();

const mainItem = computed(() => {
  return buyFlowStore.cart?.items[0];
});

onBeforeMount(() => {
  specialOffers.value = getSpecialOffersToDisplay(mainItem.value);
});

watch(mainItem, async () => {
  specialOffers.value = getSpecialOffersToDisplay(mainItem.value);
});

const language = computed(() => userStore.selectedLanguage);
watch(language, async () => {
  specialOffers.value = getSpecialOffersToDisplay(mainItem.value);
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function startingRatePlan(specialOfferItem: IProductCatalog) {
  return specialOfferItem?.ratePlans
    .filter(r => r.months == 12)
    .sort((a, b) => {
      // Handle cases where includedUnits might be undefined
      const unitsA = a.includedUnits !== undefined ? a.includedUnits : Number.MAX_SAFE_INTEGER;
      const unitsB = b.includedUnits !== undefined ? b.includedUnits : Number.MAX_SAFE_INTEGER;
      return unitsA - unitsB;
    })[0];
}

async function addProduct(sku) {
  const product = buyFlowStore?.plans.find(l => l.sku === sku);
  let ratePlan;
  if (product) {
    ratePlan = startingRatePlan(product);
  }
  //Clear the undo feature
  buyFlowStore.clearUndo();

  const mainItemRatePlans = buyFlowStore.getRatePlansForSku(mainItem.value?.sku || "");
  const mainItemRatePlan = mainItemRatePlans.find(l => l.ratePlanId === mainItem.value?.ratePlanId);
  if (mainItemRatePlans.length > 0 && mainItemRatePlan) {
    //Sort the rate plans so the month are in order
    let itemRatePlans = sortArrayOfObjects(buyFlowStore.getRatePlansForSku(sku), "months", "ascending");
    //Sort the rate plans so the included units are in asc order
    itemRatePlans = sortArrayOfObjects(itemRatePlans, "includedUnits", "ascending");
    //Try to find a rate plan that matches the main items term
    const matchedItemTerm = itemRatePlans.find(l => l.months === mainItemRatePlan.months);
    if (matchedItemTerm) {
      ratePlan = matchedItemTerm;
    } else {
      //If we can't match the term of the main product check for a shorter term
      const shorterItemTerm = itemRatePlans.find(l => l.months < mainItemRatePlan.months);
      if (shorterItemTerm) {
        ratePlan = shorterItemTerm;
      } else {
        //If we can find one shorter that means they are all longer than the main product so pick the first one
        ratePlan = itemRatePlans[0];
      }
    }
  }

  let quantity = mainItem.value?.quantity || 1;
  //Set the quantity
  if (product && quantity > 1 && buyFlowStore.getMaxQuantityBySku(product.sku)) {
    quantity =
      quantity > buyFlowStore.getMaxQuantityBySku(product.sku)
        ? buyFlowStore.getMaxQuantityBySku(product.sku)
        : quantity;
  }

  const item = {
    sku: product?.sku,
    ratePlanId: ratePlan?.ratePlanId,
    quantity: quantity,
    autoRenew: true,
    total: ratePlan?.newSubscriptionPrice,
  } as ICartItem;
  buyFlowStore.cart?.items.push(item);
  await buyFlowStore.updateShoppingCartTotal(false, false, googleTagCartEvents.add);
  specialOffers.value = getSpecialOffersToDisplay(mainItem.value);
}

function showCandyRackInfo(sku) {
  currentSku.value = sku;
  const specialOfferSku = specialOffers.value?.find(s => s.sku === sku);
  if (specialOfferSku) {
    currentStartingPrice.value = formatMoney(startingRatePlan(specialOfferSku).newSubscriptionPrice);
  }
  displayCandyRackInfo.value = true;
}

function closeShowCandyRackInfo() {
  displayCandyRackInfo.value = false;
  currentSku.value = "";
}

async function AddToCartFromCandyRackInfo(sku) {
  closeShowCandyRackInfo();
  await addProduct(sku);
}
</script>
